import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Button, Container, Grid, MenuItem, TextField } from '@mui/material'
import PreLoader from '../PreLoader'
import FacultySearchTable from './FacultySearchTable'
import { RightSidePanel } from 'wed-components'
import Header from '../header/Header'
import FacultyForm from './FacultyForm'
import FacultyDetails from './FacultyDetails'
import { LookupTablesContext } from "../../context/LookupTablesContext"
import axios from 'axios'
import { useSessionStorageFilters } from '../../hooks/useLocalStorageFilters'
import { toast } from 'react-toastify'
import { Add, FilterAltOff } from '@mui/icons-material'
import { AutocompleteOption } from '../../utilities/generalPurposeTypes'

export type ViewFacultyPermissions = {
  affiliation_name: string,
  faculty_full_name: string,
  faculty_type_name: string,
  fk_id_affiliation: number,
  fk_id_faculty: number,
  fk_id_faculty_type: number,
  id: number
}

export type ViewFaculty = {
  address: string | null,
  address_city: string | null,
  comments: string | null,
  email: string,
  first_name: string,
  fk_id_customer: number | null,
  fk_id_gender: number | null,
  fk_id_nationality: number | null,
  full_name: string,
  id: number,
  is_active: boolean,
  last_name: string,
  permissions: ViewFacultyPermissions[],
  phone: string
}

export type Panel = {
  show: boolean,
  id?: number,
  panel?: 'details' | 'form'
}

type FacultySearchFilters = {
  affiliation: AutocompleteOption | null,
  id: string,
  name: string,
  role: string,
  status: string,
}

export default function FacultySearch() {
  // General
  const [loading, setLoading] = useState(true)
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  // Table
  const [data, setData] = useState<ViewFaculty[]>([])
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  const [filters, setFilters] = useSessionStorageFilters<FacultySearchFilters>({
    affiliation: null,
    id: '',
    name: '',
    role: 'all',
    status: '1'
  }, '_facultyFilters')
  // Memos
  const affiliationOptions = useMemo<AutocompleteOption[]>(() => {
    return lookupTables.affiliation.map((_) => ({ label: `${_.tag} - ${_.option}`, id: _.id }))
  }, [lookupTables])

  const refreshTable = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty?fk_id_affiliation=${!filters.affiliation ? '' : filters.affiliation.id}&fk_id_faculty_type=${filters.role === 'all' ? '' : filters.role}&id=${filters.id}&name=${filters.name}&isActive=${filters.status === 'all' ? '' : Number(filters.status)}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch faculty.')
    }

    setLoading(false)
  }, [filters])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  useEffect(() => {
    // Debounce for the fetch
    const timeout = setTimeout(() => refreshTable(), 500)

    return () => clearTimeout(timeout)
  }, [filters, refreshTable])

  const definePanel = useCallback(() => {
    switch (panel.panel) {
      case 'details':
        return <FacultyDetails
          selectedFaculty={data.find((_faculty) => _faculty.id === panel.id) || null}
        />
      case 'form':
        return <FacultyForm
          closePanel={() => setPanel({ show: false })}
          refreshTable={refreshTable}
        />
      default:
        return <></>
    }
  }, [data, panel, refreshTable])

  return (
    <Container maxWidth="xl">
      <br />
      <Grid container spacing={1}>
        {/* Right Side Panel */}
        <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title={'Register a new faculty'} maxWidth='lg'>
          {
            panel.show ?
              definePanel()
              :
              <></>
          }
        </RightSidePanel>
        <Header title={'Faculty'} name={''} />
        {/* Filters */}
        <Grid item xs={1}>
          <TextField id='search-filter-id' label='ID' disabled={loading} value={filters.id} onChange={(e) => setFilters({ ...filters, id: e.target.value })} />
        </Grid>
        <Grid item xs={4.25}>
          <TextField id='search-filter-name' label='Name' disabled={loading} value={filters.name} onChange={(e) => setFilters({ ...filters, name: e.target.value })} />
        </Grid>
        <Grid item xs={2.5}>
          <Autocomplete
            id='search-filter-affiliation'
            disabled={loading}
            renderInput={props => <TextField {...props} label='Affiliation' />}
            options={affiliationOptions}
            value={filters.affiliation || null}
            onChange={(e, newValue) => setFilters(prev => ({ ...prev, affiliation: newValue }))}
          />
        </Grid>
        <Grid item xs={1.75}>
          <TextField id='search-filter-role' label='Role' disabled={loading} value={filters.role} onChange={(e) => setFilters({ ...filters, role: e.target.value })} select >
            {
              [
                <MenuItem key={-1} value={'all'}>All</MenuItem>,
                ...lookupTables.facultyType.map((_facultyType, index) => (
                  <MenuItem id={`faculty-type-option-${_facultyType.id}`} key={index} value={_facultyType.id}>{_facultyType.option}</MenuItem>
                ))
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={1}>
          <TextField id='search-filter-status' label='Status' disabled={loading} value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })} select >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </TextField>
        </Grid>
        {/* Buttons */}
        <Grid item xs={.75}>
          <Button
            id='button-clear-filters'
            onClick={() => setFilters({
              affiliation: null,
              id: '',
              name: '',
              role: 'all',
              status: '1'
            })}
            sx={{ height: "100%", width: "100%" }}
          >
            <FilterAltOff />
          </Button>
        </Grid>
        <Grid item xs={.75}>
          <Button
            id='button-new-faculty'
            onClick={() => setPanel({ show: true, panel: 'form' })}
            sx={{ height: "100%", width: "100%" }}
          >
            <Add />
          </Button>
        </Grid>
        <Grid item xs={12} maxHeight="80vh">
          {
            loading ?
              <PreLoader />
              :
              <FacultySearchTable
                data={data}
                panel={panel}
                setPanel={setPanel}
              />
          }
        </Grid>
      </Grid>
    </Container>
  )
}