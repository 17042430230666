import React from 'react'
import { Beneficiary } from './RequestVouchers'
import { Table, HeaderRow, Cell, BodyContainer, BodyRow } from 'wed-components'

type RequestVouchersBeneficiariesTableProps = {
  beneficiaries: Beneficiary[],
  handleSelectBeneficiary: (beneficiary: Beneficiary) => void
}

export default function RequestVouchersBeneficiariesTable({ beneficiaries, handleSelectBeneficiary }: RequestVouchersBeneficiariesTableProps) {
  const columns = [
    { header: 'ID', width: 0.5 },
    { header: 'Beneficiary', width: 3 },
  ]

  return <Table>
    <HeaderRow>
      {
        columns.map((column, counter) => {
          return <Cell key={counter} width={column.width}>
            <p>{column.header}</p>
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        !beneficiaries.length ?
        <BodyRow>
          <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} >
            <p>No beneficiaries available</p>
          </Cell>
        </BodyRow>
        :
        beneficiaries.map((beneficiary, counter) => {
          return <BodyRow key={counter} onClick={() => handleSelectBeneficiary(beneficiary)} >
            <Cell width={columns[0].width} >
              <p>{beneficiary.fk_id_beneficiary}</p>
            </Cell>
            <Cell width={columns[1].width} >
              <p>{beneficiary.beneficiary_name}</p>
            </Cell>
          </BodyRow>
        })
      }
    </BodyContainer>
  </Table>
}
