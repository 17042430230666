import { Fragment } from 'react'
import { BodyContainer, BodyRow, Cell, ExpansibleRow, HeaderRow, Table } from 'wed-components'
import { customerWithServices, paymentRequest } from './Payments'
import { renderCurrency, writeTwoDecimalsString } from '../../Utils'

type CustomersTableProps = {
  customerRows: customerWithServices[],
  requests: paymentRequest[],
  selectedCustomer: null | customerWithServices,
  handleSelectCustomer: (newCustomer: customerWithServices) => void,
  handleFilter: (filterType: 'sectionClass' | 'defaultPayment', value: 'all' | number) => void
}

export default function CustomersTable({ customerRows, requests, selectedCustomer, handleSelectCustomer, handleFilter }: CustomersTableProps) {
  const columns = [
    { field: 'id', header: 'ID', width: 0.5 },
    { field: 'name', header: 'Faculty / Class or Default Payment', width: 3.5 },
    { field: 'amount', header: 'Amount', width: 1.25 },
    { field: 'quantity', header: 'Quantity', width: 1 },
  ]

  const parentRowText = { fontWeight: 'bold' }
  const childRowText = { fontSize: '0.9rem', color: '#FFF9' }

  return <Table>
    <HeaderRow>
      {
        columns.map((column, counter) => {
          return <Cell key={counter} width={column.width} >
            <p>{column.header}</p>
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        !customerRows.length ?
          <BodyRow>
            <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} >
              <p>No customers available</p>
            </Cell>
          </BodyRow>
          :
          customerRows.map((customer, counter) => {
            const customerRequests = requests.filter(req => (
              req.fk_id_customer_beneficiary === customer.fk_id_customer_beneficiary
              &&
              ![2, 4].includes(req.fk_id_request_status_type) // Don't consider canceled or rejected requests
            ))
            const customerCurrency = requests.filter(req => (req.fk_id_customer_beneficiary === customer.fk_id_customer_beneficiary))[0].fk_id_currency
            const customerAmount = customerRequests.reduce((acc, curr) => acc += curr.amount, 0)

            return <Fragment key={counter} >
              <BodyRow
                onClick={() => handleSelectCustomer(customer)}
                selected={selectedCustomer === customer}
              >
                <Cell width={columns[0].width} >
                  <p style={parentRowText} >{customer.fk_id_customer_beneficiary}</p>
                </Cell>
                <Cell width={columns[1].width} >
                  <p style={parentRowText} >{customer.faculty_full_name}</p>
                </Cell>
                <Cell width={columns[2].width} >
                  <p style={parentRowText} >
                    {renderCurrency(customerCurrency)} {writeTwoDecimalsString(customerAmount)}
                  </p>
                </Cell>
                <Cell width={columns[3].width} >
                  <p style={parentRowText} >{customerRequests.length}</p>
                </Cell>
              </BodyRow>
              {
                customer.sectionClasses.map((sectionClass, counter) => {
                  const classPayments = requests.filter(req => (
                    req.fk_id_class === sectionClass.fk_id_class
                    &&
                    ![2, 4].includes(req.fk_id_request_status_type) // Don't consider canceled or rejected requests
                  ))
                  const classAmount = classPayments.reduce((acc, curr) => acc += curr.amount, 0)

                  return <ExpansibleRow key={counter} state={selectedCustomer?.fk_id_customer_beneficiary === customer.fk_id_customer_beneficiary} >
                    <Cell width={columns[0].width} ><p></p></Cell>
                    <Cell width={columns[1].width} >
                      <p
                        style={{ ...childRowText, cursor: 'pointer' }}
                        onClick={() => handleFilter('sectionClass', sectionClass.fk_id_class || sectionClass.fk_id_section)}
                      >
                        {sectionClass.section_name}{sectionClass.class_tag ? ` - ${sectionClass.class_tag}` : ''}
                      </p>
                    </Cell>
                    <Cell width={columns[2].width} >
                      <p style={childRowText} >
                        {
                          !classPayments.length ?
                            '-'
                            :
                            <>
                              {renderCurrency(classPayments[0].fk_id_currency)} {writeTwoDecimalsString(classAmount)}
                            </>
                        }
                      </p>
                    </Cell>
                    <Cell width={columns[3].width} >
                      <p style={childRowText} >
                        {classPayments.length}
                      </p>
                    </Cell>
                  </ExpansibleRow>
                })
              }
              {
                customer.defaultPayments.map((defaultPayment, counter) => {
                  const defPayServices = requests.filter(req => (
                    req.fk_id_customer_default_payment_map === defaultPayment.fk_id_customer_default_payment_map
                    &&
                    !req.fk_id_section // Exclude sectionClass requests
                    &&
                    ![2, 4].includes(req.fk_id_request_status_type) // Don't consider canceled or rejected requests
                  ))
                  const defPayServicesAmount = defPayServices.reduce((acc, curr) => acc += curr.amount, 0)

                  return <ExpansibleRow key={counter} state={selectedCustomer?.fk_id_customer_beneficiary === customer.fk_id_customer_beneficiary} >
                    <Cell width={columns[0].width} ><p></p></Cell>
                    <Cell width={columns[1].width} >
                      <p
                        style={{ ...childRowText, cursor: 'pointer' }}
                        onClick={() => handleFilter('defaultPayment', defaultPayment.fk_id_default_payment)}
                      >
                        {defaultPayment.default_payment_name}
                      </p>
                    </Cell>
                    <Cell width={columns[2].width} >
                      <p style={childRowText} >
                        {
                          !defPayServices.length ?
                            '-'
                            :
                            <>
                              {renderCurrency(defPayServices[0].fk_id_currency)} {writeTwoDecimalsString(defPayServicesAmount)}
                            </>
                        }
                      </p>
                    </Cell>
                    <Cell width={columns[3].width} >
                      <p style={childRowText} >
                        {defPayServices.length}
                      </p>
                    </Cell>
                  </ExpansibleRow>
                })
              }
            </Fragment>
          })
      }
    </BodyContainer>
  </Table>
}
