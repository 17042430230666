import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'


// import MenuIcon from '@mui/icons-material/Menu'

import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import BookIcon from '@mui/icons-material/Book'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import BuildIcon from '@mui/icons-material/Build'
import SchoolIcon from '@mui/icons-material/School'
import RouteIcon from '@mui/icons-material/Route'
import KeyboardIcon from '@mui/icons-material/Keyboard';
import NestLogo from './NestLogo'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import HandshakeIcon from '@mui/icons-material/Handshake'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined'
import PaidIcon from '@mui/icons-material/Paid'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CastForEducationIcon from '@mui/icons-material/CastForEducation'


import styles from './DrawerMenu.module.css'
import { useMsal } from '@azure/msal-react'

type hasPermissionArguments = {
  permittedRoles?: string[],
  excludeAdmin?: boolean
}

export default function DrawerMenu() {
  const [drawerState, setDrawerState] = useState<boolean>(false)
  const { accounts } = useMsal()

  const hasPermission = (roles?: string[], addRoles?: string[], excludeAdmin?: boolean) => {
    let permittedRoles = ['dev', 'admin']
    if (excludeAdmin) permittedRoles.pop()
    if (addRoles) permittedRoles = [...permittedRoles, ...addRoles]
    if (!roles) return false

    return roles.some((role) => permittedRoles.includes(role))
  }
  // useEffect(() => {
  // Fetch request amount

  // Set the notification on title
  // document.title = `(${99}) WED | SIS`
  // }, [])

  return (
    <nav className={drawerState ? styles.expanded : styles.retracted} style={{ zIndex: 2 }}
      onMouseEnter={() => setDrawerState(true)} onMouseLeave={() => setDrawerState(false)}
    >
      {/* <ExpandButton
        onClick={() => setDrawerState(!drawerState)}
        icon={<MenuIcon sx={{ width: '100%', height: '100%' }} />}
      /> */}
      <NavButton
        path='/'
        label='Home'
        icon={<HomeIcon id='drawer-button-home' sx={{ width: '100%', height: '100%' }} />}
      />
      <div className={styles.navSectionTitle}>
        {drawerState ?
          <><HistoryEduIcon sx={{ marginLeft: '.25rem' }} /> <p style={{ marginLeft: '.25rem' }}>Academic</p></>
          :
          <HistoryEduIcon sx={{ marginLeft: '.25rem' }} />
        }
      </div>
      <NavButton
        path='/students'
        label='Students'
        icon={<PersonIcon id='drawer-button-students' sx={{ width: '100%', height: '100%' }} />}
      />
      <NavButton
        path='/faculty'
        label='Faculty'
        icon={<SchoolIcon id='drawer-button-faculty' sx={{ width: '100%', height: '100%' }} />}
      />
      <NavButton
        path='/academic'
        label='Academic'
        icon={<BookIcon id='drawer-button-academic' sx={{ width: '100%', height: '100%' }} />}
      />
      <NavButton
        path='/affiliations'
        label='Affiliations'
        id='affiliation-menu-button'
        icon={<AccountBalanceIcon id='drawer-button-affilliation' sx={{ width: '100%', height: '100%' }} />}
      />
      <NavButton
        path='/reports'
        label='Reports'
        icon={<MenuBookIcon id='drawer-button-reports' sx={{ width: '100%', height: '100%' }} />}
      />
      {
        hasPermission(accounts[0]?.idTokenClaims?.roles, ['finances'], false) ?
          <>
            <div className={styles.navSectionTitle}>
              {drawerState ?
                <><PaidIcon sx={{ marginLeft: '.25rem' }} /> <p style={{ marginLeft: '.25rem' }}>Finances</p></>
                :
                <PaidIcon sx={{ marginLeft: '.25rem' }} />
              }
            </div>
            <NavButton
              path='/billings'
              label='Billings'
              icon={<LocalAtmIcon id='drawer-button-billings' sx={{ width: '100%', height: '100%' }} />}
            />
            <NavButton
              path='/payments'
              label='Payments'
              icon={<PointOfSaleIcon id='drawer-button-payments' sx={{ width: '100%', height: '100%' }} />}
            />
            <NavButton
              path='/vouchers'
              label='Vouchers'
              icon={<ConfirmationNumberIcon id='drawer-button-vouchers' sx={{ width: '100%', height: '100%' }} />}
            />
          </>
          :
          ''
      }
      <div className={styles.navSectionTitle}>
        {drawerState ?
          <><HandshakeIcon sx={{ marginLeft: '.25rem' }} /> <p style={{ marginLeft: '.25rem' }}>External</p></>
          :
          <HandshakeIcon sx={{ marginLeft: '.25rem' }} />
        }
      </div>
      <NavButton
        path='/nest'
        label='Nest'
        icon={<NestLogo id="drawer-button-nest" />}
      // numberOfNotifications={12}
      />
      <NavButton
        path='/canvas'
        label='Canvas'
        icon={<CastForEducationIcon id="drawer-button-nest" />}
      />
      <div className={styles.navSectionTitle}>
        {drawerState ?
          <><BuildIcon sx={{ marginLeft: '.25rem' }} /> <p style={{ marginLeft: '.25rem' }}>Tools</p></>
          :
          <BuildIcon sx={{ marginLeft: '.25rem' }} />
        }
      </div>
      {/* <NavButton
        path='/student-groups-tools'
        label='Student Groups'
        icon={<GroupsIcon id='drawer-button-student-groups' sx={{ width: '100%', height: '100%' }} />}
      /> */}
      {
        hasPermission(accounts[0]?.idTokenClaims?.roles, ['dev'], true) ?
          <>
            <NavButton
              path='/developers'
              label='Developers'
              icon={<KeyboardIcon id='drawer-button-developers' sx={{ width: '100%', height: '100%' }} />}
            />
          </>
          :
          <></>
      }
      <NavButton
        path='/course-progression-tools'
        label='Course Progression'
        icon={<RouteIcon id='drawer-button-cp-overview' sx={{ width: '100%', height: '100%' }} />}
      />
      <NavButton
        path='/enrollment-tools'
        label='Enrollment Tools'
        icon={<AssignmentOutlinedIcon id='drawer-button-enrollment-tools' sx={{ width: '100%', height: '100%' }} />}
      />
      <NavButton
        path='/credit-tools'
        label='Credit Tools'
        icon={<WorkspacePremiumOutlinedIcon id='drawer-button-credit-tools' sx={{ width: '100%', height: '100%' }} />}
      />
    </nav>
  )
}

const NavButton = ({ path, label, icon, numberOfNotifications, id }: any) => {
  const location = useLocation()

  return (
    <Link to={path} id={id}>
      <button className={styles.navButton}>
        <div className={location.pathname === path ? styles.selected : styles.notSelected} />
        <div className={styles.icon}>
          {
            numberOfNotifications ?
              <div className={styles.notification}>{numberOfNotifications}</div>
              :
              null
          }
          {icon}
        </div>
        <div className={styles.label}>
          {label}
        </div>
      </button>
    </Link>
  )
}

// const ExpandButton = ({ onClick, icon }: any) => {
//   return (
//     <button className={styles.expandButton} onClick={onClick} id='drawer-button-expand'>
//       {icon}
//     </button>
//   )
// }