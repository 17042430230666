import { Grid, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Fragment, useContext } from 'react'
import { CashFlowInstallment } from './CashFlow'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { allowMoneyFormat, formatNumberToMoney } from '../../Utils'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { Delete } from '@mui/icons-material'

type Props = {
  cashFlowInstallments: CashFlowInstallment[][]
  currencySign: string
  tabIndex: number
  setCashFlowInstallments: React.Dispatch<React.SetStateAction<CashFlowInstallment[][]>>
}

export default function CashFlowTable({ cashFlowInstallments, currencySign, tabIndex, setCashFlowInstallments }: Props) {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)

  // Convenience
  const handleNavigate = (code: string, elementId: string, row: number) => {
    switch (code) {
      case 'ArrowUp':
        return document.getElementById(`${elementId}${row - 1}`)?.focus()
      case 'ArrowDown':
        return document.getElementById(`${elementId}${row + 1}`)?.focus()
      // Ignore any button other than the arrows
      default:
        return
    }
  }

  return <Grid container spacing={.75}>
    {
      cashFlowInstallments[tabIndex].map((_, index) => <Fragment key={index}>
        <Grid item xs={1}>
          <TextField
            id={`cash-flow-id-${index}`}
            label='ID'
            value={_.id || ''}
            onChange={console.log}
            disabled
          />
        </Grid>
        <Grid item xs={1.75}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              slotProps={{
                textField: {
                  id: `cash-flow-due-date-${index}`
                }
              }}
              format="dd-MMM-yy"
              label="Due date"
              value={_.due_date}
              onChange={(newValue) => setCashFlowInstallments((list) => {
                const _list = [...list]

                _list[tabIndex][index].due_date = newValue

                return _list
              })}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.75}>
          <TextField
            id={`cash-flow-gross-value-${index}`}
            label='Gross value'
            value={_.grossValue}
            onChange={(e) => setCashFlowInstallments((installments) => {
              const _installments = [...installments]

              _installments[tabIndex][index].grossValue = e.target.value
              _installments[tabIndex][index].gross_value = allowMoneyFormat(e.target.value)

              return _installments
            })}
            error={isNaN(_.due_value)}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-gross-value-', index)}
            onBlur={() => {
              setCashFlowInstallments((_) => {
                const __installments = [..._]

                __installments[tabIndex][index].grossValue = formatNumberToMoney(__installments[tabIndex][index].gross_value)

                return __installments
              })
            }}
            onFocus={() => {
              setCashFlowInstallments((_) => {
                const __installments = [..._]

                __installments[tabIndex][index].grossValue = __installments[tabIndex][index].gross_value.toFixed(2)

                return __installments
              })
            }}
            InputProps={
              { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
            }
          />
        </Grid>
        <Grid item xs={1.75}>
          <TextField
            id={`cash-flow-due-value-${index}`}
            label='Due value'
            value={_.dueValue}
            onChange={(e) => setCashFlowInstallments((list) => {
              const _list = [...list]

              _list[tabIndex][index].dueValue = e.target.value
              _list[tabIndex][index].due_value = allowMoneyFormat(e.target.value)

              return _list
            })}
            error={isNaN(_.due_value)}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-due-value-', index)}
            onBlur={() => {
              setCashFlowInstallments((_) => {
                const __installments = [..._]

                __installments[tabIndex][index].dueValue = formatNumberToMoney(__installments[tabIndex][index].due_value)

                return __installments
              })
            }}
            onFocus={() => {
              setCashFlowInstallments((_) => {
                const __installments = [..._]

                __installments[tabIndex][index].dueValue = __installments[tabIndex][index].due_value.toFixed(2)

                return __installments
              })
            }}
            InputProps={
              { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id={`cash-flow-payment-method-${index}`}
            label='Payment method'
            value={_.fk_id_payment_method}
            onChange={(e) => setCashFlowInstallments((list) => {
              const _list = [...list]

              _list[tabIndex][index].fk_id_payment_method = Number(e.target.value)

              return _list
            })}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-payment-method-', index)}
            select
          >
            {
              lookupTables.paymentMethod.map((_, index) => <MenuItem key={index} id={`payment-method-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
            }
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={`cash-flow-comments-${index}`}
            label='Comments'
            value={_.comment || ''}
            onChange={(e) => setCashFlowInstallments((list) => {
              const _list = [...list]

              _list[tabIndex][index].comment = e.target.value

              return _list
            })}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-comments-', index)}
          />
        </Grid>
        <Grid item xs={.75} sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            id={`button-delete-cash-flow-${index}`}
            color='error'
            onClick={() => setCashFlowInstallments((_) => {
              const _list = [..._]

              _list[tabIndex] = _list[tabIndex].filter((_, filterIndex) => filterIndex !== index)

              return _list
            })}
          >
            <Delete />
          </IconButton>
        </Grid>
      </Fragment>
      )
    }
  </Grid>
}
