import { Id, toast } from "react-toastify";

export function toastSuccess(_toast: Id, render: string) {
  return toast.update(
    _toast,
    {
      render,
      type: 'success',
      isLoading: false,
      autoClose: 2500
    }
  )
}

export function toastError(_toast: Id, render: string) {
  return toast.update(
    _toast,
    {
      render,
      type: 'error',
      isLoading: false,
    }
  )
}