import axios from 'axios'
import { useState, useCallback, useEffect } from 'react'
import { useMsal } from "@azure/msal-react"
import { DateTime } from 'luxon'
import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Status, Table } from 'wed-components'
import { toast } from 'react-toastify'

type requests = {
  id: number,
  fk_id_rollout: number | null,
  fk_id_user: number,
  username: string,
  fk_id_affiliation: number,
  affiliation_name: string,
  fk_id_request_type: number,
  request_type_name: string,
  fk_id_request_status_type: number,
  request_status_type_name: string,
  comments: string | null,
  last_update: string,
  updated_by: string,
  ValidFrom: string,
  ValidTo: string,
}[]

export function RequestOverview() {
  // Hooks
  const { accounts } = useMsal()
  // General
  const columns = [1, 2, 2.5, 3, 4]
  const [loading, setLoading] = useState<boolean>(true)
  // Data
  const [data, setData] = useState<requests>([])

  const refreshTable = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: accounts[0].idTokenClaims?.roles?.includes('finances') ? `${process.env.REACT_APP_REQUESTS_URL}/requests?fk_id_request_status_type=1` : `${process.env.REACT_APP_REQUESTS_URL}/requests?fk_id_request_status_type=1&fk_id_request_type=8`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_REQUESTS_TOKEN}`,
        }
      })

      console.log(data)

      setData(data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch requests', { autoClose: 5000 })
    }
  }, [])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const defineTypeColor = (type: number) => {
    switch (type) {
      case 1:
        return 'orange'
      case 2:
        return 'blue'
      case 3:
        return 'magenta'
      case 4:
        return 'yellow'
      case 5:
        return 'darkBlue'
      case 6:
        return 'strongRed'
      case 7:
        return 'strongYellow'
      default:
        return 'grayBlue'
    }
  }

  return (
    <>
      <Table>
        <Overhead>
          <Cell width={columns[3]}>
            <p style={{ fontSize: '22px', fontWeight: 'normal' }}>
              <strong>Under revision requests </strong>
            </p>
            <p style={{ fontSize: '16px', fontWeight: 'normal' }}>
              Fall 2023
            </p>
          </Cell>
        </Overhead>
        <HeaderRow>
          <Cell width={columns[4]}>
            <p>Affiliation</p>
          </Cell>
          <Cell width={columns[3]}>
            <p>Type</p>
          </Cell>
          <Cell width={columns[4]}>
            <p>Requested In</p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            loading ?
              <BodyRow>
                <Cell width={columns.reduce((prev, curr) => prev += curr, 0)} ><p>Loading...</p></Cell>
              </BodyRow>
              :
              data.length ?
                data.map((row, index) => {
                  return (
                    <BodyRow key={index} >
                      <Cell width={columns[4]}>
                        <p>
                          {row.affiliation_name}
                        </p>
                      </Cell>
                      <Cell width={columns[3]}>
                        <Status color={defineTypeColor(row.fk_id_request_type)}>
                          {row.request_type_name}
                        </Status>
                      </Cell>
                      <Cell width={columns[4]}>
                        <p>
                          {DateTime.fromISO(row.ValidFrom, { zone: 'UTC' }).toFormat("dd-MMM - HH'h'mm")}
                        </p>
                      </Cell>
                    </BodyRow>
                  )
                })
                :
                <BodyRow>
                  <Cell flexGrow>
                    <p>
                      No requests to show.
                    </p>
                  </Cell>
                </BodyRow>
          }
        </BodyContainer>
      </Table>
    </>
  )
}