import { useCallback, useState, useContext, useMemo } from 'react'
import { Autocomplete, Button, Grid, MenuItem, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import RequestVouchersRequestsTable from './RequestVouchersRequestsTable'
import axios from 'axios'
import PreLoader from '../../PreLoader'
import { RightSidePanel } from 'wed-components'
import RequestVouchersForm from './RequestVouchersForm'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import RequestVouchersBeneficiariesTable from './RequestVouchersBeneficiariesTable'
import { useSessionStorageFilters } from '../../../hooks/useLocalStorageFilters'
import { AutocompleteOption } from '../../../utilities/generalPurposeTypes'

export type VoucherRequestsType = {
  fk_id_request: number,
  request_comments: string | null,
  fk_id_beneficiary: number,
  beneficiary_name: string,
  fk_id_bundle: number,
  bundle_name: string,
  voucher_amount: number,
  voucher_type_tag: string,
  voucher_type_name: string,
  fk_id_request_status_type: number,
  request_status_type_name: string
}

export type Beneficiary = {
  fk_id_beneficiary: number,
  beneficiary_name: string,
  beneficiary_dependence_id: number,
}

export type VoucherGeneratorsType = {
  fk_id_request: number,
  fk_id_billing: number,
  payer_fk_id_customer_type: number,
  payer_customer_type_name: string,
  payer_name: string,
  fk_id_default_billing: number,
  default_billing_name: string,
  first_installment_due_value: number,
  first_installment_paid_value: null | number
}

export type TableFilters = {
  beneficiary: number | 'all',
  bundle: number | 'all',
  status: number | 'all'
}

const emptyTableFilters: TableFilters = {
  beneficiary: 'all',
  bundle: 'all',
  status: 'all'
}

export default function RequestVouchers() {
  const { lookupTables } = useContext(LookupTablesContext)

  const [loading, setLoading] = useState(false)
  const [requests, setRequests] = useState<VoucherRequestsType[]>([])
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([])

  const [affiliation, setAffiliation] = useSessionStorageFilters<AutocompleteOption | null>(null, '_requestVouchers')
  const [tableFilters, setTableFilters] = useState<TableFilters>(emptyTableFilters)
  const [selectedRequest, setSelectedRequest] = useState<VoucherRequestsType | null>(null)

  const handleSelectRequest = (request: VoucherRequestsType) => {
    if (selectedRequest?.fk_id_request === request.fk_id_request) {
      setShowPanel(false)
      setSelectedRequest(null)
      return
    }
    setSelectedRequest(request)
    setShowPanel(true)
    return
  }

  const [showPanel, setShowPanel] = useState(false)

  const refreshTable = useCallback(async () => {
    if (!affiliation) return
    setLoading(true)
    setShowPanel(false)
    setSelectedRequest(null)
    setTableFilters(emptyTableFilters)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/request-voucher?fk_id_affiliation=${affiliation.id}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })
      console.log(data)
      setRequests(data.requests)
      setBeneficiaries(data.beneficiaries)
    } catch (err) {
      console.log(err)
      setRequests([])
      setBeneficiaries([])
    }

    setLoading(false)
  }, [setRequests, setLoading, setShowPanel, setSelectedRequest, setBeneficiaries, affiliation, setTableFilters])

  const filterAvailableBundles = (bundle: { id: number, option: string }) => {
    if (!requests.length) return false
    return requests.some(request => request.fk_id_bundle === bundle.id)
  }

  // Filtering functions
  const filterByBeneficiary = useCallback((request: VoucherRequestsType) => {
    if (tableFilters.beneficiary === 'all') return true
    return tableFilters.beneficiary === request.fk_id_beneficiary
  }, [tableFilters.beneficiary])

  const filterByBundle = useCallback((request: VoucherRequestsType) => {
    if (tableFilters.bundle === 'all') return true
    return tableFilters.bundle === request.fk_id_bundle
  }, [tableFilters.bundle])

  const filterByStatus = useCallback((request: VoucherRequestsType) => {
    if (tableFilters.status === 'all') return true
    return tableFilters.status === request.fk_id_request_status_type
  }, [tableFilters.status])

  const filteredData = useMemo(() => {
    return requests
      .filter(filterByStatus)
      .filter(filterByBundle)
      .filter(filterByBeneficiary)
  }, [requests, filterByStatus, filterByBundle, filterByBeneficiary])

  const handleSelectBeneficiary = (beneficiary: Beneficiary) => {
    if(tableFilters.beneficiary === beneficiary.fk_id_beneficiary){
      setTableFilters(prev => ({...prev, beneficiary: 'all'}))
    } else {
      setTableFilters(prev => ({...prev, beneficiary: beneficiary.fk_id_beneficiary}))
    }
  }

  return <>
    <RightSidePanel
      title='Voucher Request'
      state={showPanel}
      close={() => { setSelectedRequest(null); setShowPanel(false) }}
      maxWidth='lg'
    >
      {
        showPanel ?
          <RequestVouchersForm selectedRequest={selectedRequest} afterSubmit={() => {
            setShowPanel(false)
            setSelectedRequest(null);
            refreshTable()
          }} />
          :
          <></>
      }
    </RightSidePanel>
    <Grid item xs={2} >
      <Autocomplete
        id='filter-affiliation'
        options={lookupTables.affiliation.map((affiliation) => ({ id: affiliation.id, label: `${affiliation.tag} - ${affiliation.option}` }) )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label='Affiliation' />}
        value={!affiliation ? null : affiliation}
        onChange={(event: any, newValue) => setAffiliation(newValue)}
      />
    </Grid>
    <Grid item xs={2}>
      <Button
        id='btn-fetch'
        startIcon={<Search />}
        onClick={refreshTable}
        disabled={!affiliation || loading}
        fullWidth
        sx={{ height: '100%' }}
      >
        Fetch
      </Button>
    </Grid>
    {/* -------- Spacer -------- */}
    <Grid item xs={1} />
    <Grid item xs={3} >
      <TextField
        id='filter-beneficiary'
        label='Beneficiary'
        disabled={!requests.length}
        value={tableFilters.beneficiary}
        onChange={(e) => setTableFilters(prev => ({ ...prev, beneficiary: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        {[
          <MenuItem key={-1} value='all' >
            All
          </MenuItem>,
          beneficiaries
            .map((element, counter) => {
              return <MenuItem key={counter} value={element.fk_id_beneficiary} >
                {element.beneficiary_name}
              </MenuItem>
            })
        ]}
      </TextField>
    </Grid>
    <Grid item xs={2.5} >
      <TextField
        id='filter-bundle'
        label='Bundle'
        disabled={!requests.length}
        value={tableFilters.bundle}
        onChange={(e) => setTableFilters(prev => ({ ...prev, bundle: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        {[
          <MenuItem key={-1} value='all' >
            All
          </MenuItem>,
          lookupTables.bundle
            .filter(filterAvailableBundles)
            .map((element, counter) => {
              return <MenuItem key={counter} value={element.id} >
                {element.option}
              </MenuItem>
            })
        ]}
      </TextField>
    </Grid>
    <Grid item xs={1.5} >
      <TextField
        id='filter-status'
        label='Status'
        disabled={!requests.length}
        value={tableFilters.status}
        onChange={(e) => setTableFilters(prev => ({ ...prev, status: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        {[
          <MenuItem key={-1} value='all' >
            All
          </MenuItem>,
          lookupTables.requestStatus.map((element, counter) => {
            return <MenuItem key={counter} value={element.id} >
              {element.option}
            </MenuItem>
          })
        ]}
      </TextField>
    </Grid>
    {
      loading ?
        <PreLoader height='calc(100vh - 240px)' />
        :
        <>
          <Grid item xs={4} maxHeight='calc(100vh - 240px)' >
            <RequestVouchersBeneficiariesTable beneficiaries={beneficiaries} handleSelectBeneficiary={handleSelectBeneficiary} />
            <p style={{ color: 'white' }} >
              Number of beneficiaries: {beneficiaries.length}
            </p>
          </Grid>
          <Grid item xs={8} maxHeight='calc(100vh - 240px)' >
            <RequestVouchersRequestsTable requests={filteredData} handleSelectRequest={handleSelectRequest} />
            <p style={{ color: 'white' }} >
              Number of requests: {filteredData.length}
            </p>
          </Grid>
        </>
    }
  </>

}
