import { Table, HeaderRow, Cell, BodyContainer, BodyRow, Status } from 'wed-components'
import { VoucherRequestsType } from './RequestVouchers'
import { StatusColorsTypes } from '../../Utils'
import { IconButton } from '@mui/material'
import { Visibility, Edit } from '@mui/icons-material'

type RequestVouchersRequestsTableProps = {
  requests: VoucherRequestsType[],
  handleSelectRequest: (request: VoucherRequestsType) => void,
}

export default function RequestVouchersRequestsTable({ requests, handleSelectRequest }: RequestVouchersRequestsTableProps) {
  const columns = [
    { key: 'fk_id_request', header: 'ID', width: 0.5 },
    { key: 'beneficiary_name', header: 'Beneficiary', width: 2.5 },
    { key: 'bundle_name', header: 'Bundle', width: 2.5 },
    { key: 'voucher_amount', header: 'Vouchers', width: 1 },
    { key: 'request_status_type_name', header: 'Status', width: 1.5 },
    { key: 'action_button', header: '', width: 0.5 },
  ]

  const setStatusColor = (statusTypeID: number): StatusColorsTypes => {
    switch (statusTypeID) {
      case 1: // Under Revision
        return 'blue'
      case 2: // Canceled
        return 'yellow'
      case 3: // Approved
        return 'green'
      case 4: // Rejected
        return 'red'
      default:
        return 'blue'
    }
  }

  return <Table>
    <HeaderRow>
      {columns.map((column, counter) => {
        return <Cell key={counter} width={column.width} >
          <p>{column.header}</p>
        </Cell>
      })}
    </HeaderRow>
    <BodyContainer>
      {
        !requests.length ?
          <BodyRow>
            <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)}>
              <p>No requests available</p>
            </Cell>
          </BodyRow>
          :
          requests.map((row, counter) => {
            return <BodyRow key={counter}>
              <Cell width={columns[0].width}>
                <p>{row.fk_id_request}</p>
              </Cell>
              <Cell width={columns[1].width}>
                <p>{row.beneficiary_name}</p>
              </Cell>
              <Cell width={columns[2].width}>
                <p>{row.bundle_name}</p>
              </Cell>
              <Cell width={columns[3].width}>
                <p>({row.voucher_amount}) {row.voucher_type_tag}</p>
              </Cell>
              <Cell width={columns[4].width}>
                <Status color={setStatusColor(row.fk_id_request_status_type)} >
                  {row.request_status_type_name}
                </Status>
              </Cell>
              <Cell width={columns[5].width}>
                <IconButton onClick={() => handleSelectRequest(row)} >
                  {
                    row.fk_id_request_status_type === 1 ?
                    <Edit />
                    :
                    <Visibility />
                  }
                </IconButton>
              </Cell>
            </BodyRow>
          })
      }
    </BodyContainer>
  </Table>
}
