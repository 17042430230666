import { Suspense, lazy, useState } from "react"
import { Container, Grid, Tab, Tabs } from "@mui/material"
import PreLoader from "../components/PreLoader"

// import GenerateReportCards from "../components/reports/GenerateReportCards"
// import Header from "../components/header/Header"
// import SelectCertificateType from "../components/reports/SelectCertificate"
// import SelectTranscriptType from "../components/reports/SelectTranscriptType"
// import SelectCourseProgressType from "../components/reports/SelectCourseProgressType"

const GenerateReportCards = lazy(() => import("../components/reports/GenerateReportCards"))
const Header = lazy(() => import("../components/header/Header"))
const SelectCertificateType = lazy(() => import("../components/reports/SelectCertificate"))
const SelectTranscriptType = lazy(() => import("../components/reports/SelectTranscriptType"))
const SelectCourseProgressType = lazy(() => import("../components/reports/SelectCourseProgressType"))

export default function ReportsView() {
  const [tab, setTab] = useState<string>('generateReportCards')

  const renderComponent = () => {
    switch (tab) {
      case 'generateReportCards':
        return <GenerateReportCards />
      case 'generateCourseProgress':
        return <SelectCourseProgressType />
      case 'generateCertificates':
        return <SelectCertificateType />
      case 'generateTranscripts':
        return <SelectTranscriptType />
      default:
        return <GenerateReportCards />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1}>
        <Header title={'Reports'} name={''} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            <Tab id='reports-tab-report-cards' label='Report Cards' value='generateReportCards' />
            <Tab id='reports-tab-course-progress' label='Course Progress' value='generateCourseProgress' />
            <Tab id='reports-tab-certificates' label='Certificates' value='generateCertificates' />
            <Tab id='reports-tab-transcripts' label='Transcripts' value='generateTranscripts' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
      <br />
    </Container>
  </Suspense>
}
