import { Suspense, lazy, useState } from "react"
import { Container, Grid, Tabs, Tab } from "@mui/material"
import PreLoader from "../components/PreLoader"
import { useLocation } from "react-router-dom"

// import CourseProgressionOverview from "../components/tools/courseProgression/CourseProgressionOverview"
// import Templates from "../components/tools/courseProgression/Templates"
// import Header from "../components/header/Header"
// import CompareApply from "../components/tools/courseProgression/CompareApply"

const CourseProgressionOverview = lazy(() => import("../components/tools/courseProgression/CourseProgressionOverview"))
const Templates = lazy(() => import("../components/tools/courseProgression/Templates"))
const Header = lazy(() => import("../components/header/Header"))
const CompareApply = lazy(() => import("../components/tools/courseProgression/CompareApply"))

export default function CourseProgressionToolsView() {
  const { hash } = useLocation()
  const [tab, setTab] = useState<string>(!hash ? 'overview' : hash.replace('#', ''))

  const renderComponent = () => {
    switch (tab) {
      case 'overview':
        return <CourseProgressionOverview />
      case 'templates':
        return <Templates />
      case 'compare-apply':
        return <CompareApply />
      default:
        return <CourseProgressionOverview />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1}>
        <Header title={'Course Progression Tools'} name={''} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            <Tab id='course-progression-tools-tab-overview' label='Overview' value='overview' />
            <Tab id='course-progression-tools-tab-templates' label='Templates' value='templates' />
            <Tab id='course-progression-tools-tab-compare-apply' label='Compare/Apply' value='compare-apply' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </Suspense>
}