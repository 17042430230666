// import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { ViewNotification } from '../../hooks/useNotification'
// import { useMemo } from 'react'
import { Grid } from '@mui/material'

type Props = {
  notification: ViewNotification
}

export default function Details({ notification }: Props) {
  // const tableSettings = [
  //   {
  //     fk_id_automated_service: 3,
  //     columnWidth: []
  //   }
  // ]
  // const columnWidth = useMemo(() => tableSettings.find((_) => _.fk_id_automated_service === notification.fk_id_automated_service)!.columnWidth, [notification])

  return <Grid container>
    <Grid item xs={12} maxHeight={'90vh'}>
      <div style={{ height: '100%', overflow: 'auto', borderRadius: 6, border: '4px solid #222', padding: 10, boxSizing: 'border-box', backgroundColor: '#444' }}>
        <pre>
          <code>
            {
              JSON.stringify(notification.details, null, 2)
            }
          </code>
        </pre>
      </div>
      {/* <Table>
        <HeaderRow>
          <Cell flexGrow>
            <p>
              {notification.automated_service_name}
            </p>
          </Cell>
          <Cell>
            <p style={{ color: 'var(--color-text-subtitle)' }}>
              {notification.time_since_created}
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            notification.details.map((row, index) => <BodyRow key={index}>
              {
                Object.values(row).map((value, index) => <Cell key={index}><p>{value}</p></Cell>)
              }
            </BodyRow>
            )
          }
        </BodyContainer>
      </Table> */}
    </Grid>
  </Grid>

}
