import { IconButton, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications'
import useNotification from '../../hooks/useNotification'
import NotificationsTable from './NotificationsTable'
import styles from './Notifications.module.css'
import { useLocation } from 'react-router-dom'

export default function Notifications() {
  // refs
  const notificationBoxRef = useRef<HTMLDivElement>(null)
  // Hooks
  const { notifications, notificationCount, updateNotification } = useNotification()
  const { pathname } = useLocation()
  // Data
  const [openNotificationBox, setOpenNotificationBox] = useState<boolean>(false)

  const handleOutsideClick = (event: MouseEvent) => {
    if (notificationBoxRef.current && !notificationBoxRef.current.contains(event.target as Node)) {
      setOpenNotificationBox(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])

  return (
    <>
      <Tooltip title='Notifications' >
        <IconButton
          size='medium'
          sx={{
            background: pathname.includes('notifications') ? '#aaa' : 'transparent',
            border: '2px solid #fff',
            '&hover:': {
              color: '#003D55',
              background: '#fff'
            }
          }}
          onClick={() => pathname.includes('notifications') ? () => { } : setOpenNotificationBox(true)}
        >
          <NotificationsIcon />
          {/* Badge */}
          <div className={styles.notificationCounter} style={notificationCount === 0 ? { display: 'none' } : {}}>
            <p>{notificationCount}</p>
          </div>
        </IconButton>
      </Tooltip>

      {/* Notification Box */}
      {
        openNotificationBox && <div
          id='popup-notification'
          ref={notificationBoxRef}
          className={styles.container}
        >
          <NotificationsTable
            notifications={notifications}
            updateNotification={updateNotification}
          />
        </div>
      }
    </>
  )
}
