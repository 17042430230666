import { MissinsAssignmentsOverviewType } from './MissingAssignmentsOverview'
import styles from './MissingAssignmentsOverview.module.scss'

type PercentageBarProps = {
  rowData: MissinsAssignmentsOverviewType
}

export default function PercentageBar({ rowData }: PercentageBarProps) {
  const totalEnrollments = rowData.significantly_behind + rowData.slightly_behind + rowData.on_schedule
  const percentOnSchedule = rowData.on_schedule === 0 ? 0 : (rowData.on_schedule * 100) / totalEnrollments
  const percentSlightlyBehind = rowData.slightly_behind === 0 ? 0 : (rowData.slightly_behind * 100) / totalEnrollments
  const percentSignificantlyBehind = rowData.significantly_behind === 0 ? 0 : (rowData.significantly_behind * 100) / totalEnrollments

  return (
    <div className={styles.percentageBar}>
      <span className={styles.significantlyBehind} style={{ width: `${percentSignificantlyBehind}%` }} ></span>
      <span className={styles.slightlyBehind} style={{ width: `${percentSlightlyBehind}%` }} ></span>
      <span className={styles.onSchedule} style={{ width: `${percentOnSchedule}%` }} ></span>
    </div>
  )
}
