import styles from './BillingsTable.module.scss'

type Props = {
  children: React.ReactNode
  sx?: React.CSSProperties
}

export default function HeaderLeft({ children, sx }: Props) {
  return <div id='fixed-header-left' className={styles.headerRow} style={sx}>
    {children}
  </div>
}
