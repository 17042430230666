import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { Accounts } from './CanvasAccounts'

type CanvasAccountsProps = {
    data: Accounts[]
}

export default function CanvasAccountTable({ data }: CanvasAccountsProps) {
    const columns = [1, 1.5, 2.5, 3.5]
    return (
        <Table>
            <HeaderRow>
                <Cell width={columns[0]}><p>ID</p></Cell>
                <Cell width={columns[3]}><p>Student</p></Cell>
                <Cell width={columns[3]}><p>Email</p></Cell>
                <Cell width={columns[1]}><p>Grade</p></Cell>
                <Cell width={columns[1]}><p>Status</p></Cell>
                <Cell width={columns[1]}><p>Username</p></Cell>
                <Cell width={columns[1]}><p>Password</p></Cell>
            </HeaderRow>
            <BodyContainer>
                {
                    data.length ?
                        data.map((row) => (
                            <BodyRow key={row.id}>
                                <Cell width={columns[0]}><p>{row.id || row.fk_id_student}</p></Cell>
                                <Cell width={columns[3]}><p>{row.student_full_name}</p></Cell>
                                <Cell width={columns[3]}><p>{row.email}</p></Cell>
                                <Cell width={columns[1]}><p>{row.grade_name}</p></Cell>
                                <Cell width={columns[1]}><p>{row.status || row.student_status_type_name}</p></Cell>
                                <Cell width={columns[1]}><p>{row.username}</p></Cell>
                                <Cell width={columns[1]}><p>{row.password ? row.password : ''}</p></Cell>
                            </BodyRow>
                        ))
                        :
                        <BodyRow>
                            <Cell width={columns[2]}>No results available.</Cell>
                        </BodyRow>
                }
            </BodyContainer>
        </Table>
    )
}
