import { createTheme } from '@mui/material/styles'

const _spacing = 10

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600
    }
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
        componentsProps: {
          paper: {
            sx: {
              padding: 0 // All this is to avoid applying the padding to the menu options
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          color: '#003D55',
          width: '100%',
          height: '100%'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      }
    },
    MuiGrid: {
      defaultProps: {
        spacing: 1
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff4'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#444',
          padding: 2 * _spacing
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
        variant: 'filled',
        sx: {
          backgroundColor: '#0001',
        },
        SelectProps: {
          MenuProps: {
            slotProps: {
              paper: {
                sx: {
                  padding: 0 // All this is to avoid applying the padding to the menu options
                }
              }
            }
          }
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        sx: {
          borderBottom: '1px solid white'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        color: 'primary'
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff'
    },
    secondary: {
      main: '#003D55'
    },
    error: {
      main: '#FA7C5F'
    },
    text: {
      primary: '#fff',
      secondary: '#fff'
    },
  },
  spacing: _spacing
})