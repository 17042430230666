import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Grid, Button, TextField, MenuItem, Container, Autocomplete } from "@mui/material"
import StudentsSearchTable from "./StudentsSearchTable"

import { useNavigate } from "react-router-dom"
import { LookupTablesContext } from "../../context/LookupTablesContext"

import axios from "axios"

import PreLoader from "../PreLoader"
import { useSessionStorageFilters } from "../../hooks/useLocalStorageFilters"
import Header from "../header/Header"
import { AutocompleteOption } from "../../utilities/generalPurposeTypes"
import { DateTime } from "luxon"
import { toast } from "react-toastify"
import { Edit, FilterAltOff } from "@mui/icons-material"

export type ViewStudent = {
  admission_date: string,
  affiliation_name: string,
  birthdate: string | null,
  email: string,
  fk_id_affiliation: number,
  fk_id_grade: number,
  fk_id_student_status_type: number,
  grade_name: string,
  id: number,
  program_name: string,
  student_full_name: string,
  student_status_type_name: string,
}

type StudentSearchFilters = {
  affiliation: null | AutocompleteOption
  grade: 'all' | number
  id: string
  name: string
  program: 'all' | number
  status: 'all' | number
  admissionYear: 'all' | number
}

export default function StudentsSearch() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  const navigate = useNavigate()
  // General
  const [loading, setLoading] = useState<boolean>(true)
  // Table
  const [data, setData] = useState<ViewStudent[]>([])
  const [selectedStudentID, setSelectedStudentID] = useState<number | null>(null)
  // Filters
  const [filters, setFilters] = useSessionStorageFilters<StudentSearchFilters>({
    affiliation: null,
    grade: 'all',
    id: '',
    name: '',
    program: 'all',
    status: 'all',
    admissionYear: 'all',
  }, '_studentFilters')
  // useMemos
  const yearOptions = useMemo(() => {
    return Array.from({ length: DateTime.now().year - 2017 + 1 }, (_, counter) => 2017 + counter).map((year, index) => <MenuItem key={index} id={`student-year-admission-${year}`} value={year}>{year}</MenuItem>)
  }, [])

  // Memos
  const affiliationOptions = useMemo<AutocompleteOption[]>(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id }))
  }, [lookupTables])

  // Refresh table
  const refreshTable = useCallback(async () => {
    setLoading(true)

    // Start a timeout for the new search
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/students?affiliation=${!filters.affiliation ? '' : filters.affiliation.id}&grade=${filters.grade === 'all' ? '' : filters.grade}&id=${filters.id}&name=${filters.name}&program=${filters.program === 'all' ? '' : filters.program}&status=${filters.status === 'all' ? '' : filters.status}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch students.')
    }

    setLoading(false)
  }, [filters])

  // Fetching the students
  useEffect(() => {
    // Debounce for the fetch
    const timeout = setTimeout(() => refreshTable(), 500)

    return () => clearTimeout(timeout)
  }, [refreshTable])

  return (
    <Container maxWidth="xl">
      <br />
      <Grid container spacing={1}>
        <Header title={'Student'} name={''} />

        <Grid item xs={0.75}>
          <TextField id='search-filter-id' label='ID' value={filters.id} onChange={(e) => setFilters({ ...filters, id: e.target.value })} />
        </Grid>
        <Grid item xs={2.5}>
          <TextField id='search-filter-name' label='Name' value={filters.name} onChange={(e) => setFilters({ ...filters, name: e.target.value })} />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id='search-filter-affiliation'
            renderInput={props => <TextField {...props} label='Affiliation' />}
            options={affiliationOptions}
            value={filters.affiliation}
            onChange={(e, newValue) => setFilters(prev => ({ ...prev, affiliation: newValue }))}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        </Grid>
        <Grid item xs={1.25}>
          <TextField id='search-filter-grade' label='Grade' value={filters.grade} onChange={(e) => setFilters({ ...filters, grade: e.target.value === 'all' ? 'all' : Number(e.target.value) })} select >
            {
              [
                <MenuItem key={-1} id={`grade-option-all`} value={'all'}>All</MenuItem>,
                ...lookupTables.grade.map((_grade, counter) => (
                  <MenuItem key={counter} id={`grade-option-${_grade.id}`} value={_grade.id}>{_grade.option}</MenuItem>
                ))
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={1.5}>
          <TextField id='search-filter-program' label='Program' value={filters.program} onChange={(e) => setFilters({ ...filters, program: e.target.value === 'all' ? 'all' : Number(e.target.value) })} select>
            {
              [
                <MenuItem key={-1} id={`program-option-all`} value={'all'}>All</MenuItem>,
                ...lookupTables.program.map((_program, counter) => (
                  <MenuItem key={counter} id={`program-option-${_program.id}`} value={_program.id}>{_program.option}</MenuItem>
                ))
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={1.25}>
          <TextField id='search-filter-student-status' label='Status' value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value === 'all' ? 'all' : Number(e.target.value) })} select>
            {
              [
                <MenuItem key={-1} id={`student-status-option-all`} value={'all'}>All</MenuItem>,
                ...lookupTables.studentStatusType.map((_status, counter) => (
                  <MenuItem key={counter} id={`student-status-option-${_status.id}`} value={_status.id}>{_status.option}</MenuItem>
                ))
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={1.25}>
          <TextField id='search-filter-admission-year' label='Adm. Year' value={filters.admissionYear} onChange={(e) => setFilters({ ...filters, admissionYear: e.target.value === 'all' ? 'all' : Number(e.target.value) })} select>
            {
              [
                <MenuItem key={-1} id={`student-year-admission-option-all`} value={'all'}>All</MenuItem>,
                ...yearOptions
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={.75}>
          <Button
            id='button-clear-filters'
            onClick={() => setFilters({
              affiliation: null,
              grade: 'all',
              id: '',
              name: '',
              program: 'all',
              status: 'all',
              admissionYear: 'all'
            })}
            sx={{ height: "100%", width: "100%" }}
          >
            <FilterAltOff />
          </Button>
        </Grid>
        <Grid item xs={.75}>
          <Button
            id='button-edit'
            onClick={() => navigate(`/students/${selectedStudentID}`)}
            sx={{ height: "100%", width: "100%" }}
            disabled={!selectedStudentID}
          >
            <Edit />
          </Button>
        </Grid>
        {/* <Grid item xs={.75}>
          <Button
            id='button-new-student'
            onClick={() => navigate(`/new-student`)}
            sx={{ height: "100%", width: "100%" }}
          >
            <AddIcon />
          </Button>
        </Grid> */}
        {
          loading ?
            <PreLoader />
            :
            <Grid item height={'80vh'} xs={12}>
              <StudentsSearchTable
                data={data}
                selectedStudentID={selectedStudentID}
                setSelectedStudentID={setSelectedStudentID}
              />
            </Grid>
        }
      </Grid>
    </Container>
  )
}
