import { Suspense, lazy, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Container, Grid, Tab, Tabs } from "@mui/material"
import { useParams, useLocation } from "react-router"
import { LookupTablesContext } from "../context/LookupTablesContext"
import axios from "axios"
import { toast } from "react-toastify"
import PreLoader from "../components/PreLoader"

// import Form from "../components/students/Form"
// import Enrollments from "../components/students/Enrollments"
// import Credits from "../components/students/Credits"
// import CourseProgression from "../components/students/CourseProgression"
// import CreditTransfer from "../components/students/CreditTransfer"
// import StudentsFileStorage from "../components/students/FileStorage"
// import Status from "../components/students/Status"
// import StudentProgress from "../components/students/StudentProgress"
// import CanvasUser from "../components/students/CanvasUser"
// import Header from "../components/header/Header"

const Form = lazy(() => import("../components/students/Form"))
const Enrollments = lazy(() => import("../components/students/Enrollments"))
const Credits = lazy(() => import("../components/students/Credits"))
const CourseProgression = lazy(() => import("../components/students/CourseProgression"))
const CreditTransfer = lazy(() => import("../components/students/CreditTransfer"))
const StudentsFileStorage = lazy(() => import("../components/students/FileStorage"))
const Status = lazy(() => import("../components/students/Status"))
const StudentProgress = lazy(() => import("../components/students/StudentProgress"))
const CanvasUser = lazy(() => import("../components/students/CanvasUser"))
const Header = lazy(() => import("../components/header/Header"))

export type StudentInfo = {
  fk_id_affiliation: number,
  first_name: string,
  last_name: string,
  email: string,
  grade_name: string,
  student_status_type_name: string
}

export default function StudentsView() {
  const { id } = useParams()
  const { search } = useLocation()

  const [studentInfo, setStudentInfo] = useState<StudentInfo | null>(null)
  const [tab, setTab] = useState<string>(() => {
    const re = /tab=(\w*)?/i
    const capturingGroups = re.exec(search)
    if (capturingGroups && capturingGroups[1]) return capturingGroups[1]

    return 'form'
  })

  const { lookupTables } = useContext(LookupTablesContext)

  const defineStatusColor = (statusTypeName: string) => {
    if (/admitted/i.test(statusTypeName)) return 'var(--color-green)'
    if (/canceled/i.test(statusTypeName)) return 'var(--color-red)'
    if (/pending/i.test(statusTypeName)) return 'var(--color-yellow)'
    if (/applicant/i.test(statusTypeName)) return 'var(--color-magenta)'
    else return 'var(--color-blue)'
  }

  const studentViewTitle = useMemo(() => {
    if (!studentInfo || lookupTables.loading) return <span>Loading...</span>

    const affiliationInfo = lookupTables.affiliation.find(aff => aff.id === studentInfo.fk_id_affiliation)

    if (!affiliationInfo) return <span>{studentInfo.first_name} {studentInfo.last_name}</span>

    else return <span>{affiliationInfo.tag} - {studentInfo.first_name} {studentInfo.last_name} | {studentInfo.grade_name} <span style={{ backgroundColor: defineStatusColor(studentInfo.student_status_type_name), fontSize: '70%', padding: '0.2em 0.75em', borderRadius: '4px' }} >{studentInfo.student_status_type_name}</span></span>
  }, [studentInfo, lookupTables])

  const fetchStudentInformation = useCallback(async () => {
    // Existing student
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/students/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setStudentInfo(data)
    } catch (err) {
      console.warn(err)
      toast.error('Could not fetch student information.')
    }
  }, [id])

  useEffect(() => {
    fetchStudentInformation()
  }, [fetchStudentInformation])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const renderComponent = () => {
    switch (tab) {
      case 'form':
        return <Form />
      case 'status':
        return <Status />
      case 'studentProgress':
        return <StudentProgress />
      case 'enrollments':
        return <Enrollments studentAffiliationId={studentInfo?.fk_id_affiliation || null} />
      case 'credits':
        return <Credits />
      case 'courseProgression':
        return <CourseProgression />
      case 'creditTransfer':
        return <CreditTransfer />
      case 'fileStorage':
        return <StudentsFileStorage />
      case 'canvasAccount':
        return <CanvasUser studentInfo={studentInfo} />
      default:
        return <Form />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1}>
        <Header title={studentViewTitle} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab id='tab-form' label='Form' value='form' />
            <Tab id='tab-status' label='Status' value='status' />
            <Tab id='tab-student-progress' label='Student Progress' value='studentProgress' />
            <Tab id='tab-course-progression' label='Course Progression' value='courseProgression' />
            <Tab id='tab-enrollments' label='Enrollments' value='enrollments' />
            <Tab id='tab-credits' label='Credits' value='credits' />
            <Tab id='tab-credit-transfer' label='Credit Transfer' value='creditTransfer' />
            <Tab id='tab-file-storage' label='File Storage' value='fileStorage' />
            <Tab id='tab-canvas-account' label='Canvas Account' value='canvasAccount' />
          </Tabs>
        </Grid>
      </Grid>
      <br />
      {renderComponent()}
    </Container>
  </Suspense>
}
