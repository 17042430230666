import { Grid } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { availableVouchersType } from '../vouchers/VouchersConsumeForm'

type AvailableVouchersTablesProps = {
  availableVouchers: availableVouchersType,
  noInfo: boolean
}

export default function AvailableVouchersTables({ availableVouchers, noInfo }: AvailableVouchersTablesProps) {
  const columns = [
    { field: 'voucher_type_name', header: 'Voucher Type', width: 2.4 },
    { field: 'balance', header: 'Available Vouchers', width: 1.6 },
  ]

  const averageRowHeight = 36

  return <>
    <Grid item xs={12}>
      {
        !noInfo ?
          'Select a beneficiary'
          :
          <>
            <p style={{ fontSize: '1rem', marginBottom: '0.5rem' }} >{availableVouchers.customer_name || "Student's vouchers"}</p>
            <Table>
              <HeaderRow>
                {
                  columns.map((column, counter) => {
                    return <Cell key={counter} width={column.width} >
                      <p>{column.header}</p>
                    </Cell>
                  })
                }
              </HeaderRow>
              <BodyContainer>
                {
                  !availableVouchers.beneficiary_balance.length ?
                    <BodyRow rowHeight={averageRowHeight} >
                      <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} ><p>No available vouchers</p></Cell>
                    </BodyRow>
                    :
                    availableVouchers.beneficiary_balance.map((voucherType, counter) => {
                      if (voucherType.balance === 0) return ''
                      return <BodyRow key={counter} rowHeight={averageRowHeight} >
                        <Cell width={columns[0].width} >
                          <p>{voucherType.voucher_type_name}</p>
                        </Cell>
                        <Cell width={columns[1].width} >
                          <p>{voucherType.balance}</p>
                        </Cell>
                      </BodyRow>
                    })
                }
              </BodyContainer>
            </Table>
          </>
      }
    </Grid>

    {/* <Grid item xs={12}>
      {
        !noInfo ?
          ''
          :
          <>
            <p style={{ fontSize: '1rem', marginBlock: '0.5rem' }} >{availableVouchers.affiliation_name || "Affiliation's vouchers"}</p>
            <Table>
              <HeaderRow>
                {
                  columns.map((column, counter) => {
                    return <Cell key={counter} width={column.width} >
                      <p>{column.header}</p>
                    </Cell>
                  })
                }
              </HeaderRow>
              <BodyContainer>
                {
                  !availableVouchers.affiliation_balance.length ?
                    <BodyRow rowHeight={averageRowHeight} >
                      <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} ><p>No available vouchers</p></Cell>
                    </BodyRow>
                    :
                    <>
                      {
                        availableVouchers.affiliation_balance.map((voucherType, counter) => {
                          if (voucherType.balance === 0) return ''
                          return <BodyRow key={counter} rowHeight={averageRowHeight} >
                            <Cell width={columns[0].width} >
                              <p style={{ color: '#FFFFFF66' }} >{voucherType.voucher_type_name}</p>
                            </Cell>
                            <Cell width={columns[1].width} >
                              <p style={{ color: '#FFFFFF66' }} >{voucherType.balance}</p>
                            </Cell>
                          </BodyRow>
                        })
                      }
                    </>
                }
              </BodyContainer>
                <small style={{ marginTop: '0.5rem' }} >* Affiliation vouchers are only available for consumption after a beneficiary has been defined.</small>
            </Table>
          </>
      }
    </Grid> */}
  </>
}
