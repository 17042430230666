import { Container, Grid, Tab, Tabs } from '@mui/material'
import Header from '../components/header/Header'
import { useState } from 'react'
import Feed from '../components/notifications/Feed'

export default function NotificationsView() {
  const [tab, setTab] = useState<string>('feed')

  const renderComponent = () => {
    switch (tab) {
      case 'feed':
        return <Feed />
      default:
        return <Feed />
    }
  }

  return <Container maxWidth='xl'>
    <br />
    <Grid container spacing={1}>
      <Header title='Notifications' name='' />
      <Grid item xs={12}>
        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
          <Tab label='Feed' value='feed' />
        </Tabs>
      </Grid>
    </Grid>
    <br />
    {renderComponent()}
  </Container>
}
