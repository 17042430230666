import { ExpectedDefaultBillings, VoucherGeneratorsType } from './RequestVouchersForm'
import { Table, HeaderRow, Cell, BodyContainer, BodyRow } from 'wed-components'

type RequestVouchersFormTableProps = {
  loadingTable: boolean,
  expectedDefBills: ExpectedDefaultBillings[],
  voucherGenerators: VoucherGeneratorsType[]
}

export default function RequestVouchersFormTable({ loadingTable, expectedDefBills, voucherGenerators }: RequestVouchersFormTableProps) {
  const columns = [
    { header: 'ID', width: 0.5 },
    { header: 'Default Billing', width: 2.5 },
    { header: 'Payer', width: 1 },
    { header: '1st Installment', width: 1.5 },
  ]

  const renderPayer = (payerType: number | undefined) => {
    if (!payerType) return ''
    if (payerType === 1) return 'Affiliation'
    return 'Student'
  }

  const renderFinancialStatus = (dueValue: number | undefined, paidValue: number | undefined | null, payerType: number | undefined) => {
    if (!dueValue) return <p></p>
    if (!paidValue) return <p style={{ color: payerType === 1 ? '#e6dfa2' : '#cf7f7f' }} >Not paid</p>
    if (dueValue !== paidValue) return <p style={{ color: '#cf7f7f' }} >Incomplete payment</p>
    return <p style={{ color: '#a2e6a5' }} >Paid</p>
  }

  return <Table>
    <HeaderRow>
      {
        columns.map((column, counter) => {
          return <Cell key={counter} width={column.width} >
            <p>{column.header}</p>
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        loadingTable ?
          <BodyRow>
            <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)}>
              <p>Loading...</p>
            </Cell>
          </BodyRow>
          :
          expectedDefBills.length === 0 ?
            <BodyRow>
              <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)}>
                <p>No default billings associated to this bundle</p>
              </Cell>
            </BodyRow>
            :
            expectedDefBills.map((defBill, counter) => {
              const respectiveVouGen = voucherGenerators.find(vouGen => vouGen.fk_id_default_billing === defBill.fk_id_default_billing)

              return <BodyRow key={counter} >
                <Cell width={columns[0].width} >
                  <p>{respectiveVouGen?.fk_id_billing}</p>
                </Cell>
                <Cell width={columns[1].width} >
                  <p style={{ color: !respectiveVouGen ? '#FFF5' : '' }} >{defBill.default_billing_name}</p>
                </Cell>
                <Cell width={columns[2].width} >
                  <p>{renderPayer(respectiveVouGen?.payer_fk_id_customer_type)}</p>
                </Cell>
                <Cell width={columns[3].width} >
                  {renderFinancialStatus(respectiveVouGen?.first_installment_due_value, respectiveVouGen?.first_installment_paid_value, respectiveVouGen?.payer_fk_id_customer_type)}
                </Cell>
              </BodyRow>
            })
      }
    </BodyContainer>
  </Table>
}
