import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { Panel } from "../_billings/AssignBundles"
import { Grid } from "@mui/material"
import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Table } from "wed-components"
import { toast } from "react-toastify"

type Props = {
  panel: Panel,
  year: number
}

type Data = {
  cashflow: {
    bundle_name: string,
    default_billing_name: string,
    fk_id_billing: number
  }[],
  courseProgression: {
    base_course_credit_default: number,
    base_course_name: string,
    id: number,
    term_name: string
  }[]
}

export default function AssignBundlesDetails({ panel, year }: Props) {
  const [data, setData] = useState<Data | null>(null)

  const refreshTable = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/assign-bundles?fk_id_student=${panel.fk_id_student}&year=${year}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch the Cashflow and Course Progression.')
    }
  }, [panel, year])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  return <Grid container>
    <Grid item xs={12}>
      <Table>
        <Overhead style={{ height: 60 }}>
          <Cell flexGrow>
            <p style={{ fontSize: 20 }}>
              Billings for {year}
            </p>
          </Cell>
        </Overhead>
        <HeaderRow>
          <Cell width={.6}>
            <p>
              ID
            </p>
          </Cell>
          <Cell flexGrow>
            <p>
              Billing
            </p>
          </Cell>
          <Cell width={2}>
            <p>
              Bundle
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            data?.cashflow.map((_, index) => <BodyRow key={index}>
              <Cell width={.6}>
                <p>
                  {_.fk_id_billing}
                </p>
              </Cell>
              <Cell flexGrow>
                <p>
                  {_.default_billing_name}
                </p>
              </Cell>
              <Cell width={2}>
                <p>
                  {_.bundle_name}
                </p>
              </Cell>
            </BodyRow>
            )
          }
        </BodyContainer>
      </Table>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <Overhead style={{ height: 60 }}>
          <Cell flexGrow>
            <p style={{ fontSize: 20 }}>
              Course Progression for {year}
            </p>
          </Cell>
        </Overhead>
        <HeaderRow>
          <Cell width={.6}>
            <p>
              ID
            </p>
          </Cell>
          <Cell>
            <p>
              Term
            </p>
          </Cell>
          <Cell flexGrow>
            <p>
              Base Course
            </p>
          </Cell>
          <Cell>
            <p>
              Credits
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            data?.courseProgression.map((_, index) => <BodyRow key={index}>
              <Cell width={.6}>
                <p>
                  {_.id}
                </p>
              </Cell>
              <Cell>
                <p>
                  {_.term_name}
                </p>
              </Cell>
              <Cell flexGrow>
                <p>
                  {_.base_course_name}
                </p>
              </Cell>
              <Cell>
                <p>
                  {_.base_course_credit_default}
                </p>
              </Cell>
            </BodyRow>
            )
          }
        </BodyContainer>
        <HeaderRow>
          <Cell flexGrow>
            <p>
              Credits: {data?.courseProgression.reduce((prev, curr) => prev += curr.base_course_credit_default, 0)}
            </p>
          </Cell>
        </HeaderRow>
      </Table>
    </Grid>
  </Grid>
}
