import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, RightSidePanel, Table } from 'wed-components'
import useNotification from '../../hooks/useNotification'
import { Visibility } from '@mui/icons-material'
import { useMemo, useState } from 'react'
import Details from './Details'

type Panel = {
  show: boolean,
  id?: number
}

export default function Feed() {
  // Hooks
  const { notifications, updateNotification } = useNotification()
  // General
  const columnWidth = [2, .6]
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  // useMemos
  const selectedNotification = useMemo(() => notifications.find((_) => _.id === panel.id)!, [notifications, panel])

  return <Grid container>
    <Grid item xs={12} maxHeight={'80vh'}>
      {/* Panel */}
      <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title='Notification details'>
        {
          panel.show ?
            <Details
              notification={selectedNotification}
            />
            :
            <></>
        }
      </RightSidePanel>
      {/* Table */}
      <Table>
        <HeaderRow>
          <Cell width={columnWidth[0]}>
            <p>
              Notifier
            </p>
          </Cell>
          <Cell flexGrow>
            <p>
              Message
            </p>
          </Cell>
          <Cell width={.6}>
            <></>
          </Cell>
          <Cell width={columnWidth[1]}>
            <p>
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            notifications.length ?
              notifications.map((_row, index) => <BodyRow key={index} selected={_row.id === panel.id}>
                <Cell width={columnWidth[0]}>
                  <p style={{ fontSize: 16, color: _row.visualized ? 'var(--color-text-disabled)' : '#fff' }}>
                    {_row.automated_service_name || 'Notifier'}
                  </p>
                  <p style={{ fontSize: 12, color: 'var(--color-text-subtitle)' }}>
                    {_row.time_since_created}
                  </p>
                </Cell>
                <Cell flexGrow>
                  <p style={{ color: _row.visualized ? 'var(--color-text-disabled)' : '#fff' }}>
                    {_row.message}
                  </p>
                </Cell>
                <Cell width={.6}>
                  {
                    _row.details ?
                      <Tooltip title='See details'>
                        <IconButton
                          id={`button-details-${_row.id}`}
                          onClick={() => setPanel({ show: true, id: _row.id })}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      :
                      null
                  }
                </Cell>
                <Cell width={columnWidth[1]}>
                  <Tooltip title='Mark as read' placement='top'>
                    <Checkbox
                      id={`checkbox-mark-as-read-${_row.id}`}
                      checked={_row.visualized}
                      onChange={(e) => updateNotification(_row.id, { visualized: e.target.checked })}
                    />
                  </Tooltip>
                </Cell>
              </BodyRow>
              )
              :
              <BodyRow>
                <Cell flexGrow>No notifications</Cell>
              </BodyRow>
          }
        </BodyContainer>
      </Table>
    </Grid>
  </Grid>
}
