import { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Grid } from '@mui/material'
import { Add, Delete, Download, Edit } from '@mui/icons-material'
// import { useMsal } from '@azure/msal-react'

type Props = {
  _new: {
    label: string,
    onClick: () => void,
    height?: number,
    width?: number
  }
  _edit?: {
    label?: string,
    disabled?: boolean,
    onClick: () => void,
    height?: number,
    width?: number
  }
  _delete: {
    disabled?: boolean,
    onClick: () => void,
    height?: number,
    width?: number
  }
}

export function NewEditButtons({ _new, _edit, _delete }: Props) {
  // Hooks
  // const { accounts } = useMsal()
  // General
  const [showModal, setShowModal] = useState<boolean>(false)

  return <>
    {/* Delete modal */}
    <Dialog
      id='modal-delete'
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <DialogContent>
        Are you sure you want to delete?
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        <Button
          id='modal-button-cancel'
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
        <Button
          id='modal-button-delete'
          onClick={() => {
            _delete.onClick()
            setShowModal(false)
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    {/* Buttons */}
    <Grid item xs={_new.width || 2}>
      <Button
        id='button-new'
        startIcon={<Add />}
        onClick={_new.onClick}
        sx={_new.height ? { height: _new.height } : {}}
      >
        {_new.label}
      </Button>
    </Grid>
    {
      _edit ?
        <Grid item xs={_edit.width || 1}>
          <Button
            id='button-edit'
            startIcon={<Edit />}
            onClick={_edit.onClick}
            disabled={_edit.disabled}
            sx={_edit.height ? { height: _edit.height } : {}}
          >
            Edit
          </Button>
        </Grid>
        :
        null
    }
    <Grid item xs={_delete.width || 1}>
      <Button
        id='button-delete'
        color='error'
        startIcon={<Delete />}
        onClick={() => setShowModal(true)}
        disabled={_delete.disabled}
        sx={_delete.height ? { height: _delete.height } : {}}
      >
        Delete
      </Button>
    </Grid>
  </>
}

type FileButtonsProps = {
  onUploadClick: (arg1: any) => void,
  disableUpload: boolean,
  onDownloadClick: () => void,
  disableDownload: boolean
}

export function FileButtons({ onUploadClick, disableUpload, onDownloadClick, disableDownload }: FileButtonsProps) {
  return (
    <>
      <Grid item xs={2}>
        <Button
          sx={{ width: '100%', height: 56 }}
          startIcon={disableUpload ? <CircularProgress /> : <Add />}
          disabled={disableUpload}
          component="label"
        // onClick={onUploadClick}
        >
          {disableUpload ? 'Uploading...' : 'Upload File'}
          <input
            hidden
            type="file"
            onChange={(e) => {
              if (e.target.files) onUploadClick(e.target.files[0])
            }}
          />
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          sx={{ width: '100%', height: 56 }}
          startIcon={<Download />}
          onClick={onDownloadClick}
          disabled={disableDownload}
        >
          Download
        </Button>
      </Grid>
      {/* <Grid item xs={2}>
        <Button onClick={onDeleteClick}>
          Delete
        </Button>
      </Grid> */}
    </>
  )
}