import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, Grid, MenuItem, TextField, Typography } from "@mui/material"
import FacultyPermissionsFormTable from './FacultyPermissionsFormTable'
import PreLoader from '../PreLoader'
import { Add, DeleteForever } from '@mui/icons-material'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import { useMsal } from '@azure/msal-react'
import { toast } from 'react-toastify'
import { toastError, toastSuccess } from '../assets/customToasts'
import { AutocompleteOption } from '../../utilities/generalPurposeTypes'

type FacultyFormProps = {
  closePanel?: () => void,
  refreshTable?: () => void
}

type Info = {
  address: string | null
  address_city: string | null
  comments: string | null
  email: string,
  first_name: string
  fk_id_gender: number | null
  fk_id_nationality: number | null
  last_name: string
  lms_user_id: string | null
  phone: string | null
}

export type Permissions = {
  fk_id_affiliation: number
  fk_id_faculty_type: number
}

export type InfoPermissions = {
  id?: number
  affiliation: AutocompleteOption | null
  facultyType: AutocompleteOption | null
}

export default function FacultyForm({ closePanel, refreshTable }: FacultyFormProps) {
  // General
  const [addDisabled, setAddDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const { accounts } = useMsal()
  // Modal
  const [openModal, setOpenModal] = useState<boolean>(false)
  // Form
  const [info, setInfo] = useState<Info>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    fk_id_gender: null,
    fk_id_nationality: null,
    address: '',
    address_city: '',
    lms_user_id: '',
    comments: ''
  })
  const [permissions, setPermissions] = useState<Permissions[]>([])
  const [infoPermissions, setInfoPermissions] = useState<InfoPermissions>({
    facultyType: null,
    affiliation: null
  })
  // Hooks
  const { id } = useParams()
  const { lookupTables, refreshLookupTables } = useContext(LookupTablesContext)
  const navigate = useNavigate()
  // useMemos
  const addPermissionButton = useMemo<boolean>(() => {
    if (infoPermissions.affiliation !== null && infoPermissions.facultyType !== null) {
      return false
    }
    return true
  }, [infoPermissions])
  const isFormSubmittable = useMemo<boolean>(() => {
    // New faculty
    if (!id) {
      if (!Boolean(info.first_name) || !Boolean(info.last_name) || !Boolean(info.email) || permissions.length === 0) return false

      return true
    }
    // Editing faculty
    else {
      if (!Boolean(info.first_name) || !Boolean(info.last_name) || !Boolean(info.email)) return false

      return true
    }
  }, [info, id, permissions])
  const affiliationOptions = useMemo(() => lookupTables.affiliation.filter((_) => !permissions.find((_permission) => _permission.fk_id_affiliation === _.id)).map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables, permissions])
  const facultyTypeOptions = useMemo(() => lookupTables.facultyType.map((_) => ({ id: _.id, label: _.option })), [lookupTables])

  const addPermission = () => {
    if (!infoPermissions.affiliation || !infoPermissions.facultyType) return

    setPermissions([...permissions, {
      fk_id_affiliation: infoPermissions.affiliation.id,
      fk_id_faculty_type: infoPermissions.facultyType.id
    }])

    setInfoPermissions({
      facultyType: null,
      affiliation: null
    })
  }

  const deletePermission = (aff: number, facTy: number) => {
    setPermissions(permissions.filter((_) => !(_.fk_id_affiliation === aff && _.fk_id_faculty_type === facTy)))
  }

  const fetchFaculty = useCallback(async () => {
    const _toast = toast('Fetching faculty information', { toastId: 'toast-faculty-form-fetch-faculty', isLoading: false })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setInfo({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        // Optional info might come as "Null", so this avoids the "changing a controlled input to uncontrolled..."
        phone: data.phone_number || '',
        fk_id_gender: data.fk_id_gender || '',
        fk_id_nationality: data.fk_id_nationality || '',
        address: data.address || '',
        address_city: data.address_city || '',
        comments: data.comments || '',
        lms_user_id: data.lms_user_id || ''
      })

      toastSuccess(_toast, 'Faculty fetched.')

      setLoading(false)
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not fetch faculty.')
    }

    setLoading(false)
  }, [id, setLoading])

  useEffect(() => {
    // Existing faculty
    if (id) fetchFaculty()
    // New faculty
    else setLoading(false)
  }, [id, fetchFaculty, setLoading])


  const onAddOrUpdate = async () => {
    setAddDisabled(true)
    const _toast = toast(id ? 'Updating faculty info' : 'Creating faculty', { toastId: 'toast-faculty-form-add-update', isLoading: true })

    try {
      //create faculty 
      const { data } = await axios({
        method: id ? 'PUT' : 'POST',
        url: id ? `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty/${id}` : `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          first_name: info.first_name,
          last_name: info.last_name,
          email_primary: info.email,
          phone: info.phone || null,
          fk_id_gender: info.fk_id_gender || null,
          fk_nationality_id_country: info.fk_id_nationality || null,
          address: info.address || null,
          address_city: info.address_city || null,
          lms_user_id: info.lms_user_id || null,
          comments: info.comments || null
        }
      })

      //create permission   
      if (!id) {
        const __toast = toast('Creating permissions', { toastId: 'toast-faculty-form-add-update-permissions', isLoading: true })

        const permissionsList = permissions.map((_) => ({
          ..._,
          fk_id_faculty: data.insert.id,
          updated_by: accounts[0]?.username || 'Cypress testing'
        }))

        await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty-permissions`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
          },
          data: {
            permissions: permissionsList
          }
        })

        toastSuccess(__toast, 'Permissions created.')
      }

      toastSuccess(_toast, id ? 'Faculty updated.' : 'Faculty created.')

      refreshLookupTables(true)

      if (closePanel && refreshTable) {
        closePanel()
        refreshTable()
      }

      setAddDisabled(false)
    } catch (err) {
      console.log(err)

      toastError(_toast, id ? 'Could not update faculty.' : 'Could not create new faculty.')
    }

    setAddDisabled(false)
  }

  const deleteFaculty = async () => {
    const _toast = toast('Deleting faculty', { toastId: 'toast-faculty-form-delete-faculty', isLoading: true })

    try {
      // SIS
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      toastSuccess(_toast, 'Faculty deleted.')

      // Canvas
      // todo: suspend account on canvas

      setOpenModal(false)

      refreshLookupTables(true)

      navigate('/faculty')
    } catch (err) {
      console.log(err)

      toastError(_toast, 'Could not delete faculty.')
    }
  }

  return (
    <Grid container spacing={1} maxWidth={'md'} marginY={1} marginX={'auto'}>
      {/* Modal */}
      <Dialog
        id='modal'
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogContent>
          You're about to delete faculty member: <strong>{info.first_name + ' ' + info.last_name}</strong>.
          <br />
          Are you sure you want to delete him/her?
        </DialogContent>
        <DialogActions sx={{ padding: "1rem" }}>
          <Button id='modal-button-cancel' onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button id='modal-button-delete' onClick={deleteFaculty}>Delete</Button>
        </DialogActions>
      </Dialog>
      {
        loading ?
          <PreLoader />
          :
          <>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">Basic information</Typography>
            </Grid>
            {/* ---------- FORM ---------- */}
            <Grid item xs={12}>
              <TextField
                id='form-field-first-name'
                label='First name'
                disabled={loading}
                value={info.first_name}
                onChange={(e) => setInfo({ ...info, first_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='form-field-last-name'
                label='Last name'
                disabled={loading}
                value={info.last_name}
                onChange={(e) => setInfo({ ...info, last_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='form-field-email'
                label='E-mail'
                disabled={loading}
                value={info.email}
                onChange={(e) => setInfo({ ...info, email: e.target.value })}
              />
            </Grid>

            {
              !id
              &&
              <>
                <Grid item xs={12} marginTop='1rem'>
                  <Typography variant='h6' >Permissions</Typography>
                </Grid>

                <Grid item xs={5}>
                  <Autocomplete
                    id='form-field-affiliation'
                    renderInput={props => <TextField {...props} label='Affiliation' />}
                    options={affiliationOptions}
                    value={infoPermissions.affiliation || null}
                    onChange={(e, newValue) => setInfoPermissions({ ...infoPermissions, affiliation: newValue })}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Autocomplete
                    id='form-field-faculty-type'
                    renderInput={props => <TextField {...props} label='Role' />}
                    options={facultyTypeOptions}
                    value={infoPermissions.facultyType || null}
                    onChange={(e, newValue) => setInfoPermissions({ ...infoPermissions, facultyType: newValue })}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Button
                    id='button-add'
                    startIcon={<Add />}
                    onClick={addPermission}
                    sx={{ height: 56 }}
                    disabled={addPermissionButton}
                  >
                    Add
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <FacultyPermissionsFormTable
                    permissions={permissions}
                    deletePermission={deletePermission}
                  />
                </Grid>
              </>
            }

            {/* Optional information */}
            <Grid item xs={12} marginTop='1rem' >
              <Typography variant='h6' >Optional information</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='form-field-phone'
                label='Phone'
                disabled={loading}
                value={info.phone}
                onChange={(e) => setInfo({ ...info, phone: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='form-field-gender'
                label='Gender'
                disabled={loading}
                value={info.fk_id_gender || ''}
                onChange={(e) => setInfo({ ...info, fk_id_gender: Number(e.target.value) })}
                select
              >
                {
                  lookupTables.gender.map((_gender, index) =>
                    <MenuItem key={index} id={`gender-option-${_gender.id}`} value={_gender.id}>{_gender.option}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='form-field-nationality'
                label='Nationality'
                disabled={loading}
                value={info.fk_id_nationality || ''}
                onChange={(e) => setInfo({ ...info, fk_id_nationality: Number(e.target.value) })}
                select
              >
                {
                  lookupTables.country.map((_country, index) =>
                    <MenuItem key={index} id={`country-option-${_country.id}`} value={_country.id}>{_country.option}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item xs={12} >
              <TextField
                id='form-field-faculty-address'
                label='Address'
                disabled={loading}
                value={info.address}
                onChange={(e) => setInfo({ ...info, address: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                id='form-field-faculty-address_city'
                label='City'
                disabled={loading}
                value={info.address_city}
                onChange={(e) => setInfo({ ...info, address_city: e.target.value })}
              />
            </Grid>
            {
              id && <Grid item xs={12}>
                <TextField
                  id='form-field-lms-id'
                  label="LMS ID"
                  disabled={loading}
                  value={info.lms_user_id}
                  onChange={(e) => setInfo({ ...info, comments: e.target.value })}
                />
              </Grid>
            }

            <Grid item xs={12}>
              <TextField
                id='form-field-comments'
                label='Comments'
                disabled={loading}
                value={info.comments}
                onChange={(e) => setInfo({ ...info, comments: e.target.value })}
                multiline
                rows={4}
              />
            </Grid>

            {/* Buttons */}
            <Grid item xs={4}>
              {
                id ?
                  <Button
                    id='button-update'
                    startIcon={<Add />}
                    onClick={onAddOrUpdate}
                    sx={{ height: 56 }}
                    disabled={addDisabled || loading || !isFormSubmittable}
                  >
                    Update
                  </Button>
                  :
                  <Button
                    id='button-save'
                    startIcon={<Add />}
                    onClick={onAddOrUpdate}
                    sx={{ height: 56 }}
                    disabled={addDisabled || loading || !isFormSubmittable}
                  >
                    Save
                  </Button>
              }
            </Grid>
            {/* Spacer */}
            <Grid item xs={4} />
            {/* Delete */}
            {
              id ?
                <Grid item xs={4}>
                  <Button
                    id='button-delete'
                    startIcon={<DeleteForever />}
                    onClick={() => setOpenModal(true)}
                    color='error'
                    sx={{ height: 56 }}
                  >
                    Delete
                  </Button>
                </Grid>
                :
                null
            }
          </>
      }
    </Grid>
  )
}