import { Suspense, lazy, useCallback, useEffect, useState } from "react"
import { Container, Grid, Tab, Tabs } from "@mui/material"
import { useLocation, useParams } from "react-router"
import { useMsal } from '@azure/msal-react'
import axios from "axios"
import PreLoader from "../components/PreLoader"

// import Header from "../components/header/Header"
// import FacultyCanvasUser from "../components/faculty/FacultyCanvasUser"
// import FacultyForm from "../components/faculty/FacultyForm"
// import FacultyEnrollments from "../components/faculty/FacultyEnrollments"
// import FacultyPermissions from "../components/faculty/FacultyPermissions"
// import DefaultPayment from "../components/faculty/DefaultPayments"

const Header = lazy(() => import("../components/header/Header"))
const FacultyCanvasUser = lazy(() => import("../components/faculty/FacultyCanvasUser"))
const FacultyForm = lazy(() => import("../components/faculty/FacultyForm"))
const FacultyEnrollments = lazy(() => import("../components/faculty/FacultyEnrollments"))
const FacultyPermissions = lazy(() => import("../components/faculty/FacultyPermissions"))
const DefaultPayment = lazy(() => import("../components/faculty/DefaultPayments"))

export type ViewFaculty = {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  fk_id_gender: number,
  fk_id_nationality: number,
  fk_id_faculty_type: number,
  fk_id_customer: number | null
}

export default function FacultyView() {
  // Hooks
  const { id } = useParams()
  const { hash } = useLocation()
  const { accounts } = useMsal()
  // General
  const [tab, setTab] = useState<string>(hash.replace('#', '') || 'form')
  const [facultyInfo, setFacultyInfo] = useState<ViewFaculty | null>(null)

  const hasPermission = () => {
    if (!accounts.length) {
      console.log('Probably testing')
      return false
    }
    else {
      console.log(accounts[0].idTokenClaims?.roles)
      return true
    }
    // if (!roles) return false
    // return ['dev', 'admin', 'academic'].includes(roles[0])
  }

  const getFacultyInfo = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/faculty/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setFacultyInfo(data)
    } catch (err) {
      console.log(err)
    }
  }, [id])

  useEffect(() => {
    getFacultyInfo()
  }, [getFacultyInfo])

  const renderComponent = () => {
    switch (tab) {
      case 'form':
        return <FacultyForm />
      case 'permissions':
        return <FacultyPermissions />
      case 'enrollments':
        return <FacultyEnrollments />
      case 'canvas':
        return <FacultyCanvasUser facultyInfo={facultyInfo} />
      case 'defaultPayment':
        return <DefaultPayment facultyInfo={facultyInfo} />
      default:
        return <FacultyForm />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth="xl">
      <br />
      <Grid container spacing={1}>
        <Header title={'Tutor'} name={facultyInfo ? facultyInfo.first_name + " " + facultyInfo.last_name : ""} />
        <Grid item xs={12}>
          <Tabs id='faculty-tabs' value={tab} onChange={(e, newTab) => setTab(newTab)}>
            <Tab id='faculty-tab-form' label="Form" value="form" />
            <Tab id='faculty-tab-permissions' label="Permissions" value="permissions" />
            <Tab id='faculty-tab-enrollments' label="Enrollments" value="enrollments" />
            <Tab id='faculty-tab-canvas' label="Canvas Account" value="canvas" />
            {
              hasPermission() || process.env.REACT_APP_TESTING ?
                <Tab id='faculty-tab-default-payment' label="Default Payment" value="defaultPayment" />
                :
                null
            }
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </Suspense>
}