import { createContext, useReducer } from "react"

type StateType = {
  mode: 'hidden' | 'waiting' | 'done' | 'failed',
  message: string,
}

const initialState: StateType = {
  mode: 'hidden',
  message: ''
}

interface FeedbackPropsType {
  feedbackState: StateType
  feedbackDispatch: React.Dispatch<StateType>
}

const FeedbackContext = createContext<FeedbackPropsType>({
  feedbackState: initialState,
  feedbackDispatch: () => undefined
})

// type ActionType = {
//   type: string,
//   payload: StateType
// }

const feedbackReducer = (state: any, payload: any) => {
  return payload
}

const FeedbackContextProvider: React.FC<any> = ({ children }) => {
  const [feedbackState, feedbackDispatch] = useReducer(feedbackReducer, initialState)

  return (
    <FeedbackContext.Provider value={{ feedbackState, feedbackDispatch }}>
      {children}
    </FeedbackContext.Provider>
  )
}

export { FeedbackContextProvider, FeedbackContext }