import { useMsal } from "@azure/msal-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import axios from "axios"
import { DateTime } from "luxon"

export type ViewNotification = {
  automated_service_name: string | null,
  created_at: string,
  details: Record<string, any>[],
  fk_id_automated_service: number | null,
  id: number,
  message: string,
  receiver: string,
  time_since_created: string,
  valid_from: string,
  visualized: boolean
}

export default function useNotification() {
  // Hooks
  const { accounts } = useMsal()
  // Data
  const [notifications, setNotifications] = useState<ViewNotification[]>([])
  // useMemos
  const notificationCount = useMemo<number>(() => notifications.filter((_) => _.visualized === false).length, [notifications])

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/notifications?receiver=${accounts[0]?.username || 'Cypress testing'}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        }
      })

      const now = DateTime.utc().setZone('America/New_York')

      for (const _ of data) {
        const createdAt = DateTime.fromISO(_.created_at)

        const diff = now.diff(createdAt, ['days', 'hours', 'minutes']).toObject()

        if (diff.days! > 0) _.time_since_created = `${Math.floor(diff.days!)}d ago`

        else if (diff.hours! > 0) _.time_since_created = `${Math.floor(diff.hours!)}h ago`

        else if (diff.minutes! > 0) _.time_since_created = `${Math.floor(diff.minutes!)}m ago`

        else _.time_since_created = 'now'
      }

      // console.log(data)

      setNotifications(data)
    } catch (error) {
      console.log(error)
      // toast.error('Could not fetch notifications.')
    }
  }, [accounts])

  const updateNotification = useCallback(async (id: number, visualized: { visualized: boolean }) => {
    try {
      setNotifications((_notifications) => {
        const __notifications = [..._notifications]

        const index = __notifications.findIndex((_) => _.id === id)

        __notifications[index].visualized = visualized.visualized

        return __notifications
      })

      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/notifications/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        },
        data: visualized
      })
    } catch (error) {
      console.log(error)
      toast.error('Could not update notification.')
    }
  }, [])

  useEffect(() => {
    fetchNotifications()

    const interval = setInterval(fetchNotifications, 60000)

    return () => clearInterval(interval)
  }, [fetchNotifications])

  return {
    notifications,
    notificationCount,
    updateNotification
  }
}