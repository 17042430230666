import { useNavigate } from "react-router-dom"
import { IconButton } from "@mui/material"
import { Visibility } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'

import { Panel, ViewFaculty } from "./FacultySearch"
import { Table, HeaderRow, BodyContainer, BodyRow, Cell } from 'wed-components'

type FacultySearchTableProps = {
  data: ViewFaculty[],
  panel: Panel,
  setPanel: React.Dispatch<React.SetStateAction<Panel>>
}

export default function FacultySearchTable({ data, panel, setPanel }: FacultySearchTableProps) {
  const columnWidth = [.6, 3, 2.75, 4.75, 1, 0.5]
  // Hooks
  const navigate = useNavigate()

  return (
    <Table>
      <HeaderRow>
        <Cell width={columnWidth[0]}>
          <p>
            ID
          </p>
        </Cell>
        <Cell flexGrow>
          <p>
            Faculty name
          </p>
        </Cell>
        <Cell width={columnWidth[2]}>
          <p>
            E-mail
          </p>
        </Cell>
        <Cell width={columnWidth[3]}>
          <p>
            Permissions
          </p>
        </Cell>
        <Cell width={columnWidth[4]}>
          <p>
            Status
          </p>
        </Cell>
        <Cell width={columnWidth[5] * 2}>
          <p></p>
        </Cell>
      </HeaderRow>
      <BodyContainer>
        {
          data.length ?
            data.map((_row, index) => {
              const fontColor = _row.is_active ? 'white' : '#FFF6'

              return <BodyRow key={index} selected={panel.id === _row.id}>
                <Cell width={columnWidth[0]}>
                  <p style={{ color: fontColor }} >
                    {_row.id}
                  </p>
                </Cell>
                <Cell flexGrow>
                  <p style={{ color: fontColor }} >
                    {_row.full_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[2]}>
                  <p style={{ color: fontColor }} >
                    {_row.email}
                  </p>
                </Cell>
                <Cell width={columnWidth[3]}>
                  <p style={{ color: fontColor }} >
                    {_row.permissions.map((_) => `${_.affiliation_name} (${_.faculty_type_name})`).join(', ')}
                  </p>
                </Cell>
                <Cell width={columnWidth[4]}>
                  <p style={{ color: fontColor }} >
                    {_row.is_active ? 'Active' : 'Inactive'}
                  </p>
                </Cell>
                <Cell width={columnWidth[5]}>
                  <IconButton id='button-view' onClick={() => setPanel({ show: true, id: _row.id, panel: 'details' })} sx={{ backgroundColor: '#fff4' }}>
                    <Visibility sx={{ color: fontColor }} />
                  </IconButton>
                </Cell>
                <Cell width={columnWidth[5]}>
                  <IconButton id='button-edit' onClick={() => navigate(`/faculty/${_row.id}`)} sx={{ backgroundColor: '#fff4' }}>
                    <EditIcon sx={{ color: fontColor }} />
                  </IconButton>
                </Cell>
              </BodyRow>
            })
            :
            <BodyRow>
              <Cell flexGrow>
                <p>
                  No faculty to show.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  )
}
