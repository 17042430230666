import { Suspense, lazy, useState } from "react"
import { Container, Grid, Tabs, Tab } from "@mui/material"
import PreLoader from "../components/PreLoader"

// import EnrollmentsOverview from "../components/tools/enrollment/EnrollmentsOverview"
// import ManageStudents from "../components/tools/enrollment/ManageStudents"
// import Header from "../components/header/Header"
// import ManageFaculty from "../components/tools/enrollment/ManageFaculty"

const EnrollmentsOverview = lazy(() => import("../components/tools/enrollment/EnrollmentsOverview"))
const ManageStudents = lazy(() => import("../components/tools/enrollment/ManageStudents"))
const Header = lazy(() => import("../components/header/Header"))
const ManageFaculty = lazy(() => import("../components/tools/enrollment/ManageFaculty"))

export default function EnrollmentToolsView() {
  // General
  const [tab, setTab] = useState<string>('manageStudents')

  const renderComponent = () => {
    switch (tab) {
      // case 'overview':
      //   return <EnrollmentsOverview />
      case 'manageStudents':
        return <ManageStudents />
      case 'manageFaculty':
        return <ManageFaculty />
      default:
        return <EnrollmentsOverview />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1}>
        <Header title={'Enrollment Tools'} name={''} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            {/* <Tab label='Overview' value='overview' /> */}
            <Tab id='enrollment-tools-tab-manage-students' label='Manage Students' value='manageStudents' />
            <Tab id='enrollment-tools-tab-manage-faculty' label='Manage Faculty' value='manageFaculty' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
      <br />
    </Container>
  </Suspense>

}