import { Tooltip } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { CSSProperties, Dispatch, SetStateAction } from 'react'

export type SortStateType<T> = {
  column: keyof T,
  direction: 'asc' | 'desc'
}

type SortingHeader02Props<T> = {
  /** Displayed as table header */
  text: string,
  /** Sorting property associated to this column */
  column: keyof T | null,
  /** Sort state */
  sort: SortStateType<T>,
  /** Sort state handler */
  setSort: Dispatch<SetStateAction<{
    column: keyof T,
    direction: 'asc' | 'desc'
  }>>,
  /** Defaults to "Sort by [text.toLowerCase()]" */
  tooltipText?: string,
  /** Display tooltip's arrow. Defaults to true */
  arrow?: boolean,
  /** Tooltip placement. Defaults to "top-start" */
  placement?: "bottom" | "left" | "right" | "top" | "top-start" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end",
}

export default function SortingHeader02<T>({ text, column, sort, setSort, tooltipText = `Sort by ${text.toLowerCase()}`, arrow = true, placement = 'top-start' }: SortingHeader02Props<T>) {

  const renderSortIconStyle = (columnField: keyof T | null): CSSProperties => {
    if (!columnField || columnField !== sort.column) return { opacity: '0' }
    if (sort.direction === 'asc') return {}
    return { rotate: '180deg' }
  }

  const handleSort = (columnField: keyof T | null) => {
    if(!columnField) return
    if ( columnField !== sort.column ) setSort({ column: columnField, direction: 'asc' })
    else if (sort.direction === 'asc') setSort(prev => ({...prev, direction: 'desc'}))
    else setSort(prev => ({...prev, direction: 'asc'}))
  }

  const renderHeaderStyle = (columnField: keyof T | null): CSSProperties => {
    if (!columnField || columnField !== sort.column) return {}
    return { fontWeight: 'bold' }
  }

  return <Tooltip title={tooltipText} arrow={arrow} placement={placement} >
    <p
      style={{ ...renderHeaderStyle(column), display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={() => handleSort(column)}
    >
      {text}
      {<ArrowDropDown sx={{ ...renderSortIconStyle(column), transition: 'all .3s' }} />}
    </p>
  </Tooltip>
}