import { Table, HeaderRow, Cell, BodyRow, BodyContainer, Status } from 'wed-components'
import { paymentRequest, renderQuantity } from './Payments'
import { renderCurrency, writeTwoDecimalsString } from '../../Utils'
import { DateTime } from 'luxon'

type ProvidedServicesTableProps = {
  filteredRequests: paymentRequest[],
  handleSelectRequest: (newRequest: paymentRequest) => void
}

export default function ProvidedServicesTable({ filteredRequests, handleSelectRequest }: ProvidedServicesTableProps) {
  const columns = [
    { field: 'id', header: 'ID', width: 0.75 },
    { field: 'service_provided_type_name', header: 'Type', width: 3 },
    { field: 'quantity', header: 'Quantity', width: 1 },
    { field: 'service_provided_date', header: 'Service Date', width: 1.5 },
    { field: 'amount', header: 'Amount', width: 1 },
    { field: 'request_status_type_name', header: 'Status', width: 1.5 },
    // { field: 'delete', header: '', width: 0.5 },
    // { field: 'visualize', header: '', width: 0.5 },
  ] as const



  const renderAmount = (row: paymentRequest) => {
    return `${renderCurrency(row.fk_id_currency || 0)} ${writeTwoDecimalsString(row.amount)}`
  }

  const defineStatusColor = (student_status_type_name: string) => {
    switch (student_status_type_name) {
      case 'Approved':
        return 'green'
      case 'Rejected':
        return 'red'
      case 'Canceled':
        return 'yellow'
      case 'Under Revision':
        return 'blue'
      default:
        return 'blue'
    }
  }

  const renderRequestFontColor = (fk_id_request_status_type: number) => {
    // "Hide" canceled and rejected requests
    if([2, 4].includes(fk_id_request_status_type)) return '#FFF6'
    return 'inherit'
  }

  return <Table>
    <HeaderRow>
      {
        columns.map((column, counter) => {
          return <Cell key={counter} width={column.width} >
            <p>{column.header}</p>
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        !filteredRequests.length ?
          <BodyRow>
            <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)}>
              <p>Select a customer to view all payment requests.</p>
            </Cell>
          </BodyRow>
          :
          filteredRequests.map((request, counter) => {
            return <BodyRow key={counter} onClick={() => handleSelectRequest(request)} >
              <Cell width={columns[0].width}>
                <p style={{ color: renderRequestFontColor(request.fk_id_request_status_type) }} >
                  {request.fk_id_request}
                </p>
              </Cell>
              <Cell width={columns[1].width} >
                {
                  request.lesson_number ?
                    <p style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: renderRequestFontColor(request.fk_id_request_status_type),
                      cursor: 'pointer'
                    }}>
                      {request.section_name} - {request.class_tag}
                      <br />
                      <strong>Lesson {request.lesson_number}</strong>: {request.lesson_content}
                    </p>
                    :
                    <p style={{
                      color: renderRequestFontColor(request.fk_id_request_status_type), cursor: 'pointer'
                    }}>
                      {request.service_provided_type_name}
                    </p>}
              </Cell>
              <Cell width={columns[2].width}>
                <p
                  style={{ color: renderRequestFontColor(request.fk_id_request_status_type) }}
                >
                  {renderQuantity(request)}
                </p>
              </Cell>
              <Cell width={columns[3].width}>
                <p style={{ color: renderRequestFontColor(request.fk_id_request_status_type) }} >
                  {DateTime.fromISO(request.service_provided_date).toFormat('dd-LLL-yyyy')}
                </p>
              </Cell>
              <Cell width={columns[4].width}>
                <p style={{ color: renderRequestFontColor(request.fk_id_request_status_type) }} >
                  {renderAmount(request)}
                </p>
              </Cell>
              <Cell width={columns[5].width} flexShrink >
                <Status color={defineStatusColor(request.request_status_type_name)}>
                  {request.request_status_type_name}
                </Status>
              </Cell>
            </BodyRow>
          })
      }
    </BodyContainer>
  </Table>
}
