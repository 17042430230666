import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Table } from "wed-components"
import { Student } from "./Submissions"
import { DateTime } from "luxon"
import { IconButton, Tooltip } from "@mui/material"
import { Error, OpenInNew } from "@mui/icons-material"

type Props = {
  student: Student | null
}

export default function SubmissionsForm({ student }: Props) {
  const columnWidth = [.6, 1, 1.5, 1.25, .6]

  return <Table>
    <Overhead style={{ height: 50 }}>
      <Cell flexGrow>
        <p style={{ fontSize: 18 }}>
          Assignment that hasn't been graded yet
        </p>
      </Cell>
    </Overhead>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          ID
        </p>
      </Cell>
      <Cell flexGrow>
        <p>
          Assignment
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          Submitted at
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Days late
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>

        </p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        student?.submissions.map((row, index) => <BodyRow key={index}>
          <Cell width={columnWidth[0]}>
            <p>
              {row.id}
            </p>
          </Cell>
          <Cell flexGrow>
            <p>
              {row.name}
            </p>
          </Cell>
          <Cell width={columnWidth[2]}>
            <p>
              {DateTime.fromISO(row.submitted_at).toFormat('dd-MMM-yyyy')}
            </p>
          </Cell>
          <Cell width={columnWidth[3]} style={{ flexDirection: 'row' }}>
            <p>
              {row.days_late} days
            </p>
            <Error sx={{ color: row.has_seen_attempt ? '#aaa' : (row.days_late > 5 ? 'var(--color-strongRed)' : 'var(--color-yellow)') }} />
          </Cell>
          <Cell width={columnWidth[4]}>
            <Tooltip title='Open assignment in Canvas'>
              <IconButton onClick={() => window.open(`https://wed.instructure.com/courses/sis_course_id:${student.fk_id_section}/gradebook/speed_grader?assignment_id=${row.fk_id_lms_assignment}&student_id=${student.lms_cnv_user_id}`, '_blank')}>
                <OpenInNew />
              </IconButton>
            </Tooltip>
          </Cell>
        </BodyRow>
        )
      }
    </BodyContainer>
  </Table>
}
