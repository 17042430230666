import { Table, HeaderRow, Cell, BodyContainer, BodyRow, ExpansibleRow } from 'wed-components'
import { Affiliation, Month, Total, TotalPerAffiliation, TotalPerCurrency, TotalPerMonth, TotalTypes, ViewAccountBalance } from './AccountBalance'
import { renderCurrency, writeTwoDecimalsString } from '../../Utils'
import { CSSProperties, Dispatch, Fragment, SetStateAction } from 'react'

type AccountBalanceTableProps = {
  year: number,
  term: number,
  months: Month[],
  affiliations: Affiliation[],
  displayedValues: string,
  filteredData: ViewAccountBalance[],
  total: Total,
  expandedRows: { state: number[], setter: Dispatch<SetStateAction<number[]>> }
}



export default function AccountBalanceTable({ year, term, months, affiliations, displayedValues, filteredData, total, expandedRows }: AccountBalanceTableProps) {
  const columns = [
    { id: -3, header: 'ID', width: 0.5 },
    { id: -2, header: 'Affiliation', width: 3 },
    { id: -1, header: 'Year', width: 1.35 },
    { id: 0, header: 'Term', width: 1.35 },
    ...months.map(month => ({ id: month.id, header: `${month.tag} '${year.toString().slice(2)}`, width: 1.4 }))
  ]

  const renderCellStyle = (cellData: TotalTypes) => {
    if (displayedValues === 'total_gross_value') return 'gray'
    if (displayedValues === 'total_due_value') return 'white'
    if (displayedValues === 'total_paid_value') {
      if (!cellData.total_paid_value) return '#e6dfa2'
      if (cellData.total_due_value === cellData.total_paid_value) return '#a2e6a5'
      return '#cf7f7f'
    }
    return 'white'
  }

  const renderValueType = (cellData: TotalTypes, totalCell?: boolean) => {
    if (displayedValues === 'total_gross_value') {
      if (cellData.total_gross_value) return writeTwoDecimalsString(cellData.total_gross_value)
      return totalCell ? '0.00' : '-'
    } else if (displayedValues === 'total_due_value') {
      if (cellData.total_due_value) return writeTwoDecimalsString(cellData.total_due_value)
      return totalCell ? '0.00' : '-'
    } else if (displayedValues === 'total_paid_value') {
      if (cellData.total_paid_value) return writeTwoDecimalsString(cellData.total_paid_value)
      return '0.00'
    } else {
      return totalCell ? '0.00' : '-'
    }
  }

  const renderMonthCellValue = (cellData: Pick<ViewAccountBalance, 'total_gross_value' | 'total_due_value' | 'total_paid_value' | 'fk_id_currency'> | undefined) => {
    if (!cellData) return <p style={{ ...moneyCellStyle }} >-</p>
    return <p style={{ ...moneyCellStyle, color: renderCellStyle(cellData) }} >
      {`${renderCurrency(cellData.fk_id_currency)} ${renderValueType(cellData)}`}
    </p>
  }

  const renderRowTotal = (rowData: Pick<TotalPerAffiliation, 'year' | 'term' | 'fk_id_currency'> | undefined, period: 'term' | 'year') => {
    if (!rowData) return <p>-</p>
    const respectivePeriod = period === 'year' ?
      rowData.year
      :
      term === 1 ?
        rowData.term.spring
        :
        rowData.term.fall

    return <p style={{ ...moneyCellStyle, fontWeight: 'bold', color: renderCellStyle(respectivePeriod) }} >
      {`${renderCurrency(rowData.fk_id_currency)} ${renderValueType(respectivePeriod, true)}`}
    </p>
  }

  const renderColumnTotal = (columnData: TotalPerCurrency | undefined, key: number) => {
    if (!columnData) return <p key={key} >-</p>
    return <p key={key} style={{ ...moneyCellStyle, fontWeight: 'bold', paddingRight: '12px', color: renderCellStyle(columnData) }} >
      {`${renderCurrency(columnData.fk_id_currency)} ${renderValueType(columnData, true)}`}
    </p>
  }

  const moneyCellStyle: CSSProperties = {
    textAlign: 'end'
  }

  const handleExpandRow = (affiliationID: number) => {
    expandedRows.setter(prev => {
      if (prev.includes(affiliationID)) return prev.filter(id => id !== affiliationID)

      return [...prev, affiliationID]
    })
  }

  return <Table>
    <HeaderRow>
      {
        columns.map((column, counter) => {
          const alignEnd = column.id >= -1
          return <Cell key={counter} width={column.width} >
            <p style={{ textAlign: alignEnd ? 'end' : 'start', paddingRight: alignEnd ? '12px' : '0px' }} >
              {column.header}
            </p>
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        !affiliations.length ?
          <BodyRow>
            <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} >
              <p>No affiliations available</p>
            </Cell>
          </BodyRow>
          :
          affiliations.map((affiliationRow, counter) => {
            const affiliationsCells = filteredData.filter(cell => cell.fk_id_affiliation === affiliationRow.fk_id_affiliation)
            const affiliationTotal = total.per_affiliation.find(aff => aff.fk_id_affiliation === affiliationRow.fk_id_affiliation)

            return <Fragment key={counter} >
              <BodyRow onClick={() => handleExpandRow(affiliationRow.fk_id_affiliation)} style={{ cursor: 'pointer' }} >
                <Cell width={columns[0].width}>
                  <p>{affiliationRow.fk_id_affiliation}</p>
                </Cell>

                <Cell width={columns[1].width}>
                  <p>{affiliationRow.affiliation_name}</p>
                </Cell>

                {/* ---- Year Total ----  */}
                <Cell width={columns[2].width}>
                  {renderRowTotal(affiliationTotal, 'year')}
                </Cell>

                {/* ---- Term Total ----  */}
                <Cell width={columns[3].width}>
                  {renderRowTotal(affiliationTotal, 'term')}
                </Cell>

                {/* ---- Month Cells ---- */}
                {
                  [4, 5, 6, 7, 8, 9].map((counter) => {
                    const reducedGradeMonthMap = affiliationsCells
                      .filter(cell => cell.month === columns[counter].id)
                      .reduce((acc, curr) => ({
                        fk_id_currency: curr.fk_id_currency,
                        total_gross_value: acc.total_gross_value + curr.total_gross_value,
                        total_due_value: acc.total_due_value + curr.total_due_value,
                        total_paid_value: (acc.total_paid_value || 0) + (curr.total_paid_value || 0),
                      }), { fk_id_currency: 0, total_gross_value: 0, total_due_value: 0, total_paid_value: 0 })

                    return <Cell width={columns[counter].width} key={counter} >
                      {renderMonthCellValue(reducedGradeMonthMap)}
                    </Cell>
                  })
                }
              </BodyRow>

              {/* ---- Grades Rows ---- */}
              {
                affiliationRow.grades.map((gradeRow, counter) => {
                  const gradeCells = affiliationsCells.filter(cell => cell.fk_id_grade === gradeRow.fk_id_grade)
                  const gradeTotal = affiliationTotal?.grades.find(gr => gr.fk_id_grade === gradeRow.fk_id_grade)

                  return <ExpansibleRow key={counter} state={expandedRows.state.includes(affiliationRow.fk_id_affiliation)} >
                    <Cell width={columns[0].width}>
                      <p />
                    </Cell>

                    <Cell width={columns[1].width}>
                      <p>{gradeRow.grade_name || '(Undefined)'}</p>
                    </Cell>

                    {/* ---- Year Total ----  */}
                    <Cell width={columns[2].width}>
                      {renderRowTotal(gradeTotal, 'year')}
                    </Cell>

                    {/* ---- Term Total ----  */}
                    <Cell width={columns[3].width}>
                      {renderRowTotal(gradeTotal, 'term')}
                    </Cell>

                    {/* ---- Month Cells ---- */}
                    {
                      [4, 5, 6, 7, 8, 9].map((counter) => {
                        const gradeMonthCell = gradeCells.find(cell => cell.month === columns[counter].id)

                        return <Cell width={columns[counter].width} key={counter} >
                          {renderMonthCellValue(gradeMonthCell)}
                        </Cell>
                      })
                    }
                  </ExpansibleRow>
                })
              }
            </Fragment>
          })
      }

      {/* ---- Months' total row */}
      <HeaderRow>
        <Cell width={columns[0].width + columns[1].width} >
          <p style={{ textAlign: 'end', boxSizing: 'border-box', paddingRight: '1rem' }} >
            TOTAL
          </p>
        </Cell>

        {/* ---- Year Total ---- */}
        <Cell width={columns[2].width} >
          {
            total.per_year.map((currencyTotal, counter02) => {
              return renderColumnTotal(currencyTotal, counter02)
            })
          }
        </Cell>

        {/* ---- Term Total ---- */}
        <Cell width={columns[2].width} >
          {
            renderColumnTotal(
              filteredData.reduce((acc: TotalTypes & { fk_id_currency: number }, curr) => {
                const newAcc: TotalTypes & { fk_id_currency: number } = {
                  fk_id_currency: curr.fk_id_currency,
                  total_gross_value: acc.total_gross_value + curr.total_gross_value,
                  total_due_value: acc.total_due_value + curr.total_due_value,
                  total_paid_value: (acc.total_paid_value || 0) + (curr.total_paid_value || 0),
                }
                return newAcc
              }, {
                fk_id_currency: 5,
                total_gross_value: 0,
                total_due_value: 0,
                total_paid_value: 0
              }),
              1
            )
          }
        </Cell>

        {/* ---- Months Totals ---- */}
        {
          [4, 5, 6, 7, 8, 9].map(counter01 => {
            return <Cell width={columns[counter01].width} key={counter01} >
              {
                total.per_month.find(cell => cell.month === columns[counter01].id)?.total.map((currencyTotal, counter02) => {
                  return renderColumnTotal(currencyTotal, counter02)
                })
              }
            </Cell>
          })
        }

      </HeaderRow>
    </BodyContainer>
  </Table>
}
