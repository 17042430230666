import { Suspense, lazy, useState } from "react"
import { useLocation } from 'react-router-dom'
import { Container, Grid, Tabs, Tab } from "@mui/material"
import PreLoader from "../components/PreLoader"
import Submissions from "../components/tools/credit/Submissions"
import CreditsOverview from "../components/tools/credit/CreditsOverview"

// import IssueCredits from "../components/tools/credit/IssueCredits"
// import MissingAssignments from "../components/tools/credit/MissingAssignments"
// import Header from "../components/header/Header"
// import CreditTransfer from "../components/tools/credit/CreditTransfer"

const IssueCredits = lazy(() => import("../components/tools/credit/IssueCredits"))
const MissingAssignments = lazy(() => import("../components/tools/credit/MissingAssignments"))
const Header = lazy(() => import("../components/header/Header"))
const CreditTransfer = lazy(() => import("../components/tools/credit/CreditTransfer"))

export default function CreditToolsView() {
  const { hash } = useLocation()
  const [tab, setTab] = useState<string>(!hash ? 'overview' : hash.replace('#', ''))

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const renderComponent = () => {
    switch (tab) {
      case 'overview':
        return <CreditsOverview />
      case 'issueCredits':
        return <IssueCredits />
      case 'missingAssignments':
        return <MissingAssignments />
      case 'submissions':
        return <Submissions />
      case 'creditTransfer':
        return <CreditTransfer />
      default:
        return <IssueCredits />
    }
  }

  return (
    <Suspense fallback={<PreLoader />}>
      <Container maxWidth='xl'>
        <br />
        <Grid container spacing={1}>
          <Header title={'Credit Tools View'} name={''} />
          <Grid item xs={12}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab id='credit-tools-tab-overview' label='Overview' value='overview' />
              <Tab id='credit-tools-tab-issue-credits' label='Issue Credits' value='issueCredits' />
              <Tab id='credit-tools-tab-missing-assignments' label='Missing Assignments' value='missingAssignments' />
              <Tab id='credit-tools-tab-submissions' label='Submissions' value='submissions' />
              <Tab id='credit-tools-tab-credit-transfer' label='Credit Transfer' value='creditTransfer' />
            </Tabs>
          </Grid>
          {renderComponent()}
        </Grid>
        <br />
      </Container>
    </Suspense>
  )
}