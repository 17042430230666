import React from 'react'
import { Button } from '@mui/base'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { ExpectedVouchersPerStudent } from './BundlesForm'

type BundleExpectedStudentsModalProps = {
  open: boolean,
  closeModal: () => void,
  text: { affiliationAndYear: string, programAndVoucher: string, students: ExpectedVouchersPerStudent[] }
}

export default function BundleExpectedStudentsModal({ open, closeModal, text }: BundleExpectedStudentsModalProps) {
  const columns = [
    { header: 'ID', width: 0.5 },
    { header: 'Student', width: 3 },
    { header: 'Grade', width: 1 },
  ]

  return <Dialog
  id={`modal-bundle-expected-students`}
  open={open}
  onClose={closeModal}
  maxWidth='lg'
  // onKeyUp={(e) => {
  //   e.stopPropagation()
  //   if (e.key === 'Enter') proceedButtonAction()
  // }}
>
  <DialogTitle style={{ paddingBottom: '0px' }} >{text.affiliationAndYear}</DialogTitle>
  <DialogTitle style={{ paddingTop: '0px' }} >{text.programAndVoucher}</DialogTitle>
  <DialogContent sx={{ height: '450px' }} >
    <Table>
      <HeaderRow>
        {
          columns.map((column, counter) => {
            return <Cell key={counter} width={column.width} >
              <p>{column.header}</p>
            </Cell>
          })
        }
      </HeaderRow>
      <BodyContainer>
        {
          text.students.map((student, counter) => {
            return <BodyRow key={counter} rowHeight={36} >
              <Cell width={columns[0].width} >
                <p>{student.fk_id_student}</p>
              </Cell>
              <Cell width={columns[1].width} >
                <p>{student.student_full_name}</p>
              </Cell>
              <Cell width={columns[2].width} >
                <p>{student.grade_name}</p>
              </Cell>
            </BodyRow>
          })
        }
      </BodyContainer>
      <p style={{ marginBottom: '0px' }} >
        <strong>TOTAL:</strong> {text.students.length} student(s)
      </p>
    </Table>
  </DialogContent>
</Dialog>
}
