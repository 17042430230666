import { Autocomplete, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import CanvasAccountTable from './CanvasAccountTable'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import DownloadIcon from '@mui/icons-material/Download'
import { utils, writeFile } from 'xlsx'
import PreLoader from '../PreLoader'
import { AutocompleteOption } from '../../utilities/generalPurposeTypes'
import { Search } from '@mui/icons-material'
import { toastError, toastSuccess } from '../assets/customToasts'

export type Accounts = {
  id: number
  email: string
  grade_name: string
  password: string
  student_full_name: string
  status?: string
  student_status_type_name?: string
  fk_id_student?: number
  username: string
}

type AffiliationAutocompleteOption = AutocompleteOption & { tag: string }

export default function CanvasAccounts() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [isLoading, setIsloading] = useState(false)
  // Data
  const [data, setData] = useState<Accounts[]>([])
  // Filters
  const [affiliation, setAffiliation] = useState<AffiliationAutocompleteOption | null>(null)
  const [student, setStudent] = useState<string>('')
  // useMemos
  const re = useMemo(() => new RegExp(student.toLowerCase(), 'i'), [student])
  const affiliationOptions = useMemo<AffiliationAutocompleteOption[]>(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id, tag: aff.tag }))
  }, [lookupTables])

  const refreshTable = useCallback(async () => {
    if (!affiliation) return
    setIsloading(true)
    const _toast = toast('Fetching accounts', { toastId: 'toast-canvas-accounts', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DIPLOMAT_URL}/get-users-logins?fk_id_affiliation=${affiliation.id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
        }
      })

      console.log(data)

      setData(data)

      toastSuccess(_toast, 'Accounts fetched.')

      setIsloading(false)
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not fetch accounts.')
    }
  }, [affiliation])

  const exportToExcel = () => {
    const _toast = toast('Downloading canvas accounts', { toastId: 'toast-canvas-login-export-to-excel', isLoading: true })

    try {
      const wb = utils.book_new();
      const dataToExcel: Accounts[] = data.map((_) => ({
        'id': _.id,
        'student_full_name': _.student_full_name,
        'grade_name': _.grade_name,
        'email': _.email,
        'status': _.student_status_type_name,
        'username': _.username,
        'password': _.password
      }))

      const ws = utils.json_to_sheet(dataToExcel)
      utils.book_append_sheet(wb, ws)

      const affiliation_tag = lookupTables.affiliation.find((_) => _.id === affiliation?.id)?.tag.toLowerCase()
      writeFile(wb, `${affiliation_tag ? affiliation_tag : ''}-canvas-accounts.xlsx`)

      toastSuccess(_toast, 'Canvas accounts downloaded.')
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not download accounts downloaded.')
    }
  }

  const filterName = (row: Accounts) => {
    switch (student) {
      case '':
        return true
      default:
        return re.test(row.student_full_name.toLowerCase())
    }
  }

  return (
    <>
      <Grid item xs={5}>
        <Paper sx={{ padding: 0, display: 'flex', background: '#fff', color: '#000', height: '100%', alignItems: 'center' }} elevation={4}>
          <LightbulbIcon sx={{ color: 'var(--action)', paddingX: 1 }} />
          <Typography sx={{ color: 'black' }}>The passwords in this table could have been already reset by the user.</Typography>
        </Paper>
      </Grid>
      {/* Spacer */}
      <Grid item xs={.5} />
      {/* Filters */}
      <Grid item xs={2.5}>
        <TextField disabled={!data.length || isLoading} id='filter-student' label='Student' value={student} onChange={(e) => setStudent(e.target.value)} />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          id='filter-affiliation'
          disabled={isLoading}
          renderInput={props => <TextField {...props} label='Affiliation' />}
          options={affiliationOptions}
          value={affiliation || null}
          onChange={(e, newValue) => setAffiliation(newValue)}
        />
      </Grid>
      <Grid item xs={1} >
        <Button
          id='button-search'
          startIcon={<Search />}
          disabled={!affiliation || isLoading}
          onClick={refreshTable}
        >
          Search
        </Button>
      </Grid>
      <Grid item xs={1} >
        <Button
          id='button-export-excel'
          startIcon={<DownloadIcon />}
          sx={{ color: 'white', backgroundColor: 'hsl(148.2, 56.5%, 35%)' }}
          disabled={!data.length || isLoading}
          onClick={exportToExcel}
        >
          XLSX
        </Button>
      </Grid>

      <Grid item xs={12} height={'70vh'}>
        {
          isLoading ?
            <PreLoader height={'100%'} />
            :
            <CanvasAccountTable data={data.filter(filterName)} />
        }
      </Grid>
    </>
  )
}
