import axios from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import { Autocomplete, Button, Container, Grid, IconButton, TextField } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import PreLoader from '../PreLoader'
import { DeleteForever } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { toastError, toastSuccess } from '../assets/customToasts'

export type Courseware = {
  id: number
  fk_id_base_course: number
  base_course_name?: string
  fk_id_affiliation: number
}

type AutocompleteOptions = {
  id: number | null
  label: string
}

export default function AffiliationCoursewareForm() {
  const { id } = useParams()
  const { lookupTables } = useContext(LookupTablesContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [affiliationCourseWares, setAffiliationCoursewares] = useState<Courseware[]>([])
  const [baseCourse, setBaseCourse] = useState<AutocompleteOptions | null>(null)
  const unavailableCoursewares = useMemo(() => affiliationCourseWares.map((_) => _.fk_id_base_course), [affiliationCourseWares])

  const baseCourseAutocompleteOptions = useMemo<AutocompleteOptions[]>(() => {
    return lookupTables.baseCourses
      .filter((_) => !unavailableCoursewares.includes(_.id))
      .map((_) => ({
        id: _.id,
        label: _.option
      }))
  }, [lookupTables.baseCourses, unavailableCoursewares])

  const refreshTable = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliation-courseware?fk_id_affiliation=${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })
      console.log(data)
      setAffiliationCoursewares(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    refreshTable()
  }, [])

  const onSave = async () => {
    const _toast = toast('Saving affiliation courseware...', { toastId: 'toast-affiliation-program-save', isLoading: true })

    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliation-courseware`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          fk_id_affiliation: id,
          fk_id_base_course: baseCourse?.id
        }
      })
      toastSuccess(_toast, 'Courseware saved.')
      refreshTable()
      setBaseCourse(null)
      setLoading(false)
    } catch (error) {
      toastError(_toast, 'Could not save courseware.')
      console.log(error)
    }
  }


  const onDelete = async (id: number) => {
    const _toast = toast('Deleting affiliation courseware...', { toastId: 'toast-affiliation-courseware-delete', isLoading: true })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliation-courseware/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })
      toastSuccess(_toast, 'Courseware deleted.!')
      refreshTable()
      setBaseCourse(null)
    } catch (error) {
      toastError(_toast, 'Could not delete courseware.')
      console.log(error)
    }
  }

  return <Grid container maxWidth={'sm'} marginY={.25} marginX={'auto'} spacing={1}>
    <Grid item xs={8}>
      <Autocomplete
        id='form-field-base-course'
        value={baseCourseAutocompleteOptions.find((_) => _.id === baseCourse)}
        options={baseCourseAutocompleteOptions}
        onChange={(e, newValue) => setBaseCourse(newValue || null)}
        renderInput={(params) => <TextField {...params} label='Base Course' />}
      />
    </Grid>
    <Grid item xs={4}>
      <Button id='button-add' disabled={!baseCourse} onClick={() => onSave()}>ADD</Button>
    </Grid>
    <Grid item xs={12}>
      {loading ?
        <PreLoader />
        :
        <Table>
          <HeaderRow>
            <Cell width={6}><p>Base Course</p></Cell>
          </HeaderRow>
          <BodyContainer>
            {!affiliationCourseWares.length && <BodyRow><Cell width={2}><p>No coursewares registered yet</p></Cell></BodyRow>}
            {affiliationCourseWares.length ?
              affiliationCourseWares.map((row, index) => {
                return (
                  <BodyRow key={index}>
                    <Cell width={6}><p>{row.base_course_name}</p></Cell>
                    <Cell width={1}>
                      <IconButton id={`button-delete-${row.id}`} onClick={() => onDelete(row.id)}>
                        <DeleteForever />
                      </IconButton>
                    </Cell>
                  </BodyRow>
                )
              })
              : null
            }
          </BodyContainer>
        </Table>
      }
    </Grid>
  </Grid>
}
