import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Table } from "wed-components"
import { useCallback, useState, useEffect, useContext } from "react"
import axios from "axios"
import { FeedbackContext } from "../../context/FeedbackContext"
import { DateTime } from 'luxon'

type Data = {
    section_name: string,
    affiliation_name: string,
    end_date: string,
    student_full_name?: string
}

export default function EndingSectionsAndEnrollments() {
    // General
    const [loading, setLoading] = useState<boolean>(true)
    // Data
    const [data, setData] = useState<Data[]>([])
    // Hooks
    const { feedbackDispatch } = useContext(FeedbackContext)
    // Constants
    const columns = [2.5, 2]

    const refreshTable = useCallback(async () => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_DIPLOMAT_URL}/ending-sections-and-enrollments?consult=1`,
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
                }
            })

            console.log(data)

            setData(data)

            setLoading(false)
        } catch (err) {
            console.log(err)
            feedbackDispatch({ mode: 'failed', message: 'Could not fetch sections and enrollments end date.' })
        }
    }, [setData, feedbackDispatch])

    useEffect(() => {
        refreshTable()
    }, [refreshTable])

    return (
        <Table>
            <Overhead>
                <Cell width={3.5}>
                    <p style={{ fontSize: '22px' }}>
                        Ending Sections and Enrollments
                    </p>
                    <p style={{ fontSize: 14, fontWeight: 'lighter' }}>
                        from <b>{DateTime.utc().minus({ days: 15 }).startOf('day').toFormat('dd-MMM-yyyy')}</b> to <b>{DateTime.utc().endOf('day').toFormat('dd-MMM-yyyy')}</b>
                    </p>
                </Cell>
            </Overhead>
            <HeaderRow>
                <Cell width={columns[0]}>
                    <p>
                        Section
                    </p>
                    <p>
                        (End date)
                    </p>
                </Cell>
                <Cell width={columns[1]}>
                    <p>
                        Student name
                    </p>
                </Cell>
            </HeaderRow>
            <BodyContainer>
                {
                    loading ?
                        <BodyRow>
                            <Cell width={columns.reduce((prev, curr) => prev += curr, 0)} >
                                <p>
                                    Loading...
                                </p>
                            </Cell>
                        </BodyRow>
                        :
                        data.length ?
                            data.map((row, index) => {
                                return (
                                    <BodyRow key={index}>
                                        <Cell width={columns[0]}>
                                            <p>
                                                {row.section_name}
                                            </p>
                                            <p>
                                                ({DateTime.fromISO(row.end_date).toFormat('dd-MMM-yyyy')})
                                            </p>
                                        </Cell>
                                        <Cell width={columns[1]}>
                                            <p>
                                                {row.student_full_name || '-'}
                                            </p>
                                        </Cell>
                                    </BodyRow>
                                )
                            })
                            :
                            <BodyRow>
                                <Cell width={columns.reduce((prev, curr) => prev += curr, 0)} >
                                    <p>
                                        No sections/enrollments to show.
                                    </p>
                                </Cell>
                            </BodyRow>
                }
            </BodyContainer>
        </Table>
    )
}