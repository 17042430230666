import { DateTime } from "luxon"
import { ViewStudent } from "./StudentsSearch"
import { Table, HeaderRow, BodyContainer, BodyRow, Cell } from 'wed-components'

type StudentsSearchTableProps = {
  data: ViewStudent[],
  selectedStudentID: number | null,
  setSelectedStudentID: any
}

export default function StudentsSearchTable({ data, selectedStudentID, setSelectedStudentID }: StudentsSearchTableProps) {
  const columnWidth = [.5, 3, 1, 2.75, 2, 2.5, 1.25]

  return (
    <Table>
      <HeaderRow>
        <Cell width={columnWidth[0]}>
          <p>
            ID
          </p>
        </Cell>
        <Cell width={columnWidth[1]}>
          <p>
            Student name
          </p>
        </Cell>
        <Cell width={columnWidth[2]}>
          <p>
            Grade
          </p>
        </Cell>
        <Cell width={columnWidth[3]}>
          <p>
            Program
          </p>
        </Cell>
        <Cell width={columnWidth[4]}>
          <p>
            Affiliation
          </p>
        </Cell>
        <Cell width={columnWidth[5]}>
          <p>
            E-mail
          </p>
        </Cell>
        <Cell width={columnWidth[6]}>
          <p>
            Status
          </p>
        </Cell>
        <Cell width={columnWidth[6]}>
          <p>
            Admission
          </p>
        </Cell>
      </HeaderRow>
      <BodyContainer>
        {
          data.length ?
            data.map((_row, counter) => {
              return <BodyRow selected={selectedStudentID === _row.id} onClick={() => setSelectedStudentID(_row.id)} key={counter}>
                <Cell width={columnWidth[0]}>
                  <p>
                    {_row.id}
                  </p>
                </Cell>
                <Cell width={columnWidth[1]}>
                  <p>
                    {_row.student_full_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[2]}>
                  <p>
                    {_row.grade_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[3]}>
                  <p>
                    {_row.program_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[4]}>
                  <p>
                    {_row.affiliation_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[5]}>
                  <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {_row.email}
                  </p>
                </Cell>
                <Cell width={columnWidth[6]}>
                  <p>
                    {_row.student_status_type_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[6]}>
                  <p>
                    {DateTime.fromISO(_row.admission_date).toFormat('dd-LLL-yy')}
                  </p>
                </Cell>
              </BodyRow>
            })
            :
            <BodyRow>
              <Cell width={3}>
                <p>
                  No students found.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  )
}
