import styles from './BillingsTable.module.scss'

type Props = {
  children: React.ReactNode
  isMonthHeader?: boolean
  isNumber?: boolean
  onClick?: () => void
  width?: number
  sx?: React.CSSProperties
}

export default function HeaderCell({ children, isMonthHeader = false, isNumber = false, onClick, width = 1, sx }: Props) {
  return <div
    className={`${styles.cell} ${styles.headerCell} ${isMonthHeader ? styles.monthHeaderCell : null} ${isNumber ? styles.numberCell : ''}`}
    style={{ width: width * 100, ...sx }}
    onClick={onClick}
  >
    {children}
  </div>
}
