// APP SERVICES CONFIG
export const msalConfig = {
  auth: {
    clientId: process.env.NODE_ENV === 'development' ? "1c3bfe8e-a631-49fa-9e4c-7b93ebff438a" : "778b8480-8563-4155-a43c-a73cc35aa751",
    authority: process.env.NODE_ENV === 'development' ? "https://login.microsoftonline.com/wk12.org" : "https://login.microsoftonline.com/wk12.org", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://sis.wk12.org",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}