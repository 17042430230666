import { Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { useLocation, useNavigate } from 'react-router-dom'
import { KeyboardBackspace, Logout, Settings } from '@mui/icons-material'
import { SetStateAction, useMemo } from "react"
import { IPublicClientApplication } from "@azure/msal-browser"
import Notifications from "../notifications/Notifications"
import { useMsal } from "@azure/msal-react"

type HeaderProps = {
  title: JSX.Element | string
  name?: string
  setSettings?: React.Dispatch<SetStateAction<boolean>>
  handleLogout?: (instance: IPublicClientApplication) => void
}

export default function Header({ title, name, setSettings, handleLogout }: HeaderProps) {
  // Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { instance } = useMsal()
  // useMemos
  const isHomePage = useMemo(() => location.pathname === '/', [location])

  const IconButtonStyle = {
    background: 'transparent',
    border: '2px solid #fff',
    '&hover:': {
      color: '#003D55',
      background: '#fff'
    }
  }
  return <>
    <Grid container spacing={1}>
      <Grid item xs={10.6}>
        <Typography variant="h4" color='primary'>
          {title}{name ? ':' : ''} {name}
        </Typography>
      </Grid>
      {/* Buttons */}
      <Grid item xs={0.45}>
        <Notifications />
      </Grid>
      <Grid item xs={0.45}>
        {
          isHomePage ?
            <Tooltip title='Set dashboard'>
              <IconButton onClick={() => setSettings ? setSettings(true) : ''} sx={IconButtonStyle} size="medium">
                <Settings />
              </IconButton>
            </Tooltip>
            :
            <Tooltip title='Back'>
              <IconButton onClick={() => navigate(-1)} sx={IconButtonStyle} size="medium">
                <KeyboardBackspace />
              </IconButton>
            </Tooltip>
        }
      </Grid>
      <Grid item xs={0.45}>
        <Tooltip title='Logout'>
          <IconButton onClick={() => handleLogout ? handleLogout(instance) : ''} sx={IconButtonStyle} size="medium">
            <Logout />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  </>
}