import axios from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Autocomplete, Button, Dialog, Grid, MenuItem, TextField } from '@mui/material'
import { BundlesTable, CustomersTable } from './AssignBundlesTable'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { quickSort } from '../../Utils'
import { RightSidePanel } from 'wed-components'
import AssignBundlesDetails from './AssignBundlesDetails'
import { AddCircle } from '@mui/icons-material'
import CashFlow from './CashFlow'
import { ViewBundle } from './Bundles'
import { toastError, toastSuccess } from '../../assets/customToasts'
import { DateTime } from 'luxon'

export type ViewCustomer = {
  id: number,
  fk_id_customer_type: number,
  fk_id_customer_dependence: number,
  customer_type_name: string,
  customer_name: string,
  customer_affiliation_name: string,
  customer_affiliation_id: number,
  customer_grade_id: number,
  customer_grade_name: string,
  customer_program_id: number,
  customer_program_name: string,
  fk_id_student_status_type: number,
  student_status_type_name: string,
}

export type SuggestedBundles = {
  fk_id_program: number,
  program_name: string,
  fk_id_voucher_type: number,
  voucher_type_name: string,
  voucher_type_tag: string,
  quantity: number
}

export type AssignedVouchers = {
  beneficiary_dependence_id: number,
  bundle: {
    id: number,
    bundle_name: string,
    voucher_amount: number
  }
  fk_id_bundle: number
}

export type ExpectedVouchers = {
  assigned_voucher_quantity: number,
  expected_voucher_quantity: number,
  fk_id_program: number,
  fk_id_student: number,
  fk_id_voucher_type: number,
  program_name: string,
  voucher_type_name: string,
  voucher_type_tag: string
}

type AutocompleteType = null | { id: number, label: string }

type BundlesFilter = {
  affiliation: AutocompleteType,
  year: number
}

type CustomerFilters = {
  affiliation: AutocompleteType,
  grade: 'all' | number,
  program: 'all' | number,
  expected: 'all' | number,
  status: string,
  hide: 'yes' | 'no'
}

export type SortCustomers = {
  column: keyof ViewCustomer,
  direction: 'asc' | 'desc'
}

export type Panel = {
  show: boolean,
  fk_id_student?: number
}

export type Modal = {
  show: boolean,
  fk_id_bundle?: number,
  customers?: number[]
}

export default function AssignBundles2() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false)
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  const [modal, setModal] = useState<Modal>({
    show: false
  })
  // Data
  const [bundles, setBundles] = useState<ViewBundle[]>([])
  const [selectedBundleID, setSelectedBundleID] = useState<number | null>(null)
  const [customers, setCustomers] = useState<ViewCustomer[]>([])
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([])
  const [expectedAndAssignedVouchers, setExpectedAndAssignedVouchers] = useState<ExpectedVouchers[]>([])
  // Filters
  const [bundlesFilters, setBundlesFilters] = useState<BundlesFilter>({
    affiliation: null,
    year: DateTime.utc().get('year')
  })
  const [customerFilters, setCustomerFilters] = useState<CustomerFilters>({
    affiliation: null,
    grade: 'all',
    program: 'all',
    expected: 'all',
    status: 'active',
    hide: 'no'
  })
  const [sort, setSort] = useState<SortCustomers>({
    column: 'customer_grade_id',
    direction: 'asc'
  })
  // useMemos
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables])
  const gradeOptions = useMemo(() => {
    const availableGrades = customers.reduce((prev: number[], curr) => {
      if (!prev.includes(curr.customer_grade_id)) prev.push(curr.customer_grade_id)

      return prev
    }, [])

    return lookupTables.grade.filter((_) => availableGrades.includes(_.id))
  }, [lookupTables, customers])
  const programOptions = useMemo(() => {
    const availablePrograms = customers.reduce((prev: number[], curr) => {
      if (!prev.includes(curr.customer_program_id)) prev.push(curr.customer_program_id)

      return prev
    }, [])

    return lookupTables.program.filter((_) => availablePrograms.includes(_.id))
  }, [lookupTables, customers])

  const getBundles = useCallback(async () => {
    const _toast = toast('Loading bundles', { toastId: 'toast-assign-bundles-loading-bundles', isLoading: true })
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setBundles(data)

      toastSuccess(_toast, 'Bundles loaded.')
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not fetch bundles.')
    }
  }, [])

  useEffect(() => {
    getBundles()
  }, [getBundles])

  // Fetch customers only if an affiliation was selected from the customers' filters
  const getCustomers = useCallback(async () => {
    const _toast = toast('Loading customers', { toastId: 'toast-assign-bundles-loading-customers', isLoading: true })

    // Render helper text if no affiliation was selected
    if (customerFilters.affiliation === null) return setCustomers([])

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/customers?fk_id_affiliation=${customerFilters.affiliation.id}&fk_id_customer_type=2&${customerFilters.status === `active` ? [1, 6, 8].map((_) => `fk_id_student_status_type[]=${_}`).join('&') : `fk_id_student_status_type[]=${customerFilters.status}`}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setSelectedCustomers([])

      setCustomers(data)

      toastSuccess(_toast, 'Customers loaded.')
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not fetch customers.')
    }
  }, [customerFilters.affiliation, customerFilters.status, setCustomers, setSelectedCustomers, setLoadingCustomers])

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const getExpectedAndAssignedBundles = useCallback(async () => {
    if (!bundlesFilters.affiliation) return setExpectedAndAssignedVouchers([])

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/assigned-and-expected-vouchers?bundleID=${selectedBundleID}&fk_id_affiliation=${bundlesFilters.affiliation.id}&year=${bundlesFilters.year}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setExpectedAndAssignedVouchers(data.expectedAndAssignedVouchers)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch suggested bundles.')
    }
  }, [bundlesFilters, setExpectedAndAssignedVouchers])

  useEffect(() => {
    getExpectedAndAssignedBundles()
  }, [getExpectedAndAssignedBundles])

  // Bundles Filters
  const filterBundleByAffiliation = useCallback((bundle: ViewBundle) => {
    switch (bundlesFilters.affiliation) {
      case null:
        return true
      default:
        return bundle.default_billings[0]?.fk_id_affiliation === bundlesFilters.affiliation.id
    }
  }, [bundlesFilters.affiliation])

  const filterBundleByYear = useCallback((bundle: ViewBundle) => {
    return bundle.reference_year === bundlesFilters.year
  }, [bundlesFilters.year])

  const filteredBundles = useMemo(() => {
    return bundles.filter(filterBundleByAffiliation).filter(filterBundleByYear)
  }, [bundles, filterBundleByAffiliation, filterBundleByYear])

  // Customers Filters
  const filterCustomerByGrade = useCallback((customer: ViewCustomer) => {
    switch (customerFilters.grade) {
      case 'all':
        return true
      default:
        return customerFilters.grade === customer.customer_grade_id
    }
  }, [customerFilters.grade])

  const filterCustomerByProgram = useCallback((customer: ViewCustomer) => {
    switch (customerFilters.program) {
      case 'all':
        return true
      default:
        return customerFilters.program === customer.customer_program_id
    }
  }, [customerFilters.program])

  // const filterCustomerByExpectedVouchers = useCallback((customer: ViewCustomer) => {
  //   if (customerFilters.expected === 'all') return true
  //   const expectedVoucherInfo = expectedVoucherOptions[customerFilters.expected]

  //   const _student = studentsExpectedVouchers.find(st => st.fk_id_student === customer.fk_id_customer_dependence)
  //   const requiredVouchers = _student?.required_vouchers

  //   if (!requiredVouchers) return false

  //   const studentRequiredVoucher = requiredVouchers.find(reqVou => reqVou.fk_id_voucher_type === expectedVoucherInfo.fk_id_voucher_type && reqVou.quantity === expectedVoucherInfo.amount)

  //   if (!studentRequiredVoucher) return false
  //   return true
  // }, [customerFilters.expected, expectedVoucherOptions, studentsExpectedVouchers])

  const filteredCustomers = useMemo(() => {
    return quickSort(
      customers
        .filter(filterCustomerByProgram)
        .filter(filterCustomerByGrade)
      // .filter(filterCustomerByExpectedVouchers)
      ,
      sort.column,
      sort.direction
    )
  }, [customers, filterCustomerByGrade, filterCustomerByProgram,
    // filterCustomerByExpectedVouchers,
    sort])

  // When the Bundles affiliation filter changes
  useEffect(() => {
    setSelectedBundleID(null)
    setCustomerFilters((_customerFilters) => ({ ..._customerFilters, affiliation: bundlesFilters.affiliation }))
  }, [bundlesFilters.affiliation])

  return <>
    {/* Modal */}
    <Dialog
      id='modal-assign-bundle'
      open={modal.show}
      onClose={() => setModal({ show: false })}
      maxWidth='xl'
    >
      <Grid container maxWidth='xl'>
        <CashFlow
          bundleId={selectedBundleID!}
          customerIds={selectedCustomers}
          onClose={() => setModal({ show: false })}
        />
      </Grid>
    </Dialog>
    {/* RightSidePanel */}
    <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title='Assign Bundles details' >
      {
        panel.show ?
          <AssignBundlesDetails
            panel={panel}
            year={bundlesFilters.year as number}
          />
          :
          <></>
      }
    </RightSidePanel>
    {/* Bundles */}
    <Grid item xs={3.45}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Autocomplete
            id='bundles-filter-affiliation'
            options={affiliationOptions}
            renderInput={(params) => <TextField {...params} label='Affiliation' />}
            value={bundlesFilters.affiliation}
            onChange={(event, newValue) => setBundlesFilters({ ...bundlesFilters, affiliation: newValue })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='bundles-filter-year'
            label='Year'
            value={bundlesFilters.year}
            onChange={(e) => setBundlesFilters({ ...bundlesFilters, year: Number(e.target.value) })}
            select
          >
            <MenuItem value='2023'>2023</MenuItem>
            <MenuItem value='2024'>2024</MenuItem>
            <MenuItem value='2025'>2025</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} maxHeight={'76vh'}>
          <BundlesTable
            data={filteredBundles}
            selectedBundleID={selectedBundleID}
            setSelectedBundleID={setSelectedBundleID}
          />
        </Grid>
      </Grid>
    </Grid>
    {/* Spacer */}
    <Grid item xs={.1} />
    {/* Customers */}
    <Grid item xs={8.45}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Autocomplete
            id='customer-filter-affiliation'
            options={affiliationOptions}
            renderInput={(params) => <TextField {...params} label='Affiliation' />}
            value={customerFilters.affiliation}
            onChange={(event, newValue) => setCustomerFilters({ ...customerFilters, affiliation: newValue })}
          />
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            id='bundles-filter-grade'
            label='Grade'
            value={customerFilters.grade}
            onChange={(e) => setCustomerFilters({ ...customerFilters, grade: e.target.value === 'all' ? 'all' : Number(e.target.value) })}
            select
          >
            {
              [
                <MenuItem key={-1} value='all'>All</MenuItem>,
                ...gradeOptions.map((_grade, index) => <MenuItem key={index} id={`grade-option-${_grade.id}`} value={_grade.id}>{_grade.option}</MenuItem>)
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id='bundles-filter-program'
            label='Program'
            value={customerFilters.program}
            onChange={(e) => setCustomerFilters({ ...customerFilters, program: e.target.value === 'all' ? 'all' : Number(e.target.value) })}
            select
          >
            {
              [
                <MenuItem key={-1} value='all'>All</MenuItem>,
                ...programOptions.map((_program, index) => <MenuItem key={index} id={`program-option-${_program.id}`} value={_program.id}>{_program.option}</MenuItem>)
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            id='bundles-filter-status'
            label='Status'
            value={customerFilters.status}
            onChange={(e) => setCustomerFilters({ ...customerFilters, status: e.target.value })}
            select
          >
            {
              [
                <MenuItem key={-2} value={'all'}>All</MenuItem>,
                <MenuItem key={-1} value={'active'}>Active</MenuItem>,
                ...lookupTables.studentStatusType.map((_status, index) => <MenuItem key={index} id={`status-option-${_status.id}`} value={_status.id}>{_status.option}</MenuItem>)
              ]
            }
          </TextField>
        </Grid>
        <Grid item xs={2.75}>
          <TextField
            id='bundles-filter-hide-students'
            label='Hide students with selected bundle?'
            value={customerFilters.hide}
            onChange={(e) => setCustomerFilters({ ...customerFilters, hide: e.target.value as 'yes' | 'no' })}
            select
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2.25}>
          <Button
            id='button-assign-bundle'
            startIcon={<AddCircle />}
            onClick={() => setModal({ show: true, fk_id_bundle: selectedBundleID!, customers: selectedCustomers })}
            disabled={!selectedBundleID || !selectedCustomers.length}
          >
            Assign bundle
          </Button>
        </Grid>
        <Grid item xs={12} maxHeight={'70vh'}>
          <CustomersTable
            data={customers}
            filteredData={filteredCustomers}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            expectedAndAssignedVouchers={expectedAndAssignedVouchers}
            sort={sort}
            setSort={setSort}
            panel={panel}
            setPanel={setPanel}
          />
        </Grid>
      </Grid>
    </Grid>
  </>
}
