import { CSSProperties, Fragment } from 'react'
import { IconButton } from '@mui/material'
import { ReadMore, AdsClick } from '@mui/icons-material'
import { Table, HeaderRow, Cell, BodyContainer, BodyRow, ExpansibleRow } from 'wed-components'
import { SuggestedBundles } from './BundlesForm'
import { ViewDefaultBilling } from './DefaultBillings'

type SuggestedDefaultBillingsTableProps = {
  suggestedBundles: SuggestedBundles[],
  loadingSuggestedBundles: boolean,
  createFromSuggestedBundle: (type: 'enrollment' | 'tuition' | 'courseware', programID: number) => void,
  defaultBillings: ViewDefaultBilling[],
  selectedBundleRows: number[],
  handleSelectBundleRow: (counter: number) => void,
  showBundleExpectedStudents: (suggestedBundle: SuggestedBundles) => void
}

export default function SuggestedDefaultBillingsTable({ suggestedBundles, loadingSuggestedBundles, createFromSuggestedBundle, defaultBillings, selectedBundleRows, handleSelectBundleRow, showBundleExpectedStudents }: SuggestedDefaultBillingsTableProps) {

  const columns = [
    { header: 'Program', width: 2.75 },
    { header: 'Voucher Type', width: 1.65 },
    { header: 'Quantity', width: 0.85 },
    { header: '', width: 0.9 }
  ]

  const defaultStyles: CSSProperties = {
    height: '60%',
    aspectRatio: '1/1',
    borderRadius: '6px',
    backgroundColor: 'white',
    color: 'var(--secondary)',
    display: 'grid',
    placeItems: 'center',
    fontWeight: 'bold',
    fontSize: '0.9rem'
  }

  function checkServiceRow(serviceID: number, programID: number) {
    const defBillsOfCurrentProgramAndService = defaultBillings.filter(defBill => defBill.fk_id_program === programID && defBill.fk_id_service === serviceID)
    if (!defBillsOfCurrentProgramAndService.length) return ''

    return <div style={{ ...defaultStyles, borderRadius: '50%', height: '80%' }} >
      {defBillsOfCurrentProgramAndService.length}
    </div>
  }

  function checkBundleRow(programID: number) {
    const defBillsOfCurrentProgram = defaultBillings.filter(defBill => defBill.fk_id_program === programID)
    if (!defBillsOfCurrentProgram.length) return ''

    return <div style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'flex-start', gap: '4px' }} >
      {defBillsOfCurrentProgram.some(defBill => defBill.fk_id_service === 6) && <div style={defaultStyles} >E</div>}
      {defBillsOfCurrentProgram.some(defBill => defBill.fk_id_service === 1) && <div style={defaultStyles} >T</div>}
      {defBillsOfCurrentProgram.some(defBill => defBill.fk_id_service === 2) && <div style={defaultStyles} >C</div>}
    </div>
  }

  return <>
    <Table>
      <HeaderRow>
        {
          columns.map((column, counter) => {
            return <Cell key={counter} width={column.width} >
              <p>{column.header}</p>
            </Cell>
          })
        }
      </HeaderRow>
      <BodyContainer>
        {
          loadingSuggestedBundles ?
            <BodyRow>
              <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} >
                <p>Loading...</p>
              </Cell>
            </BodyRow>
            :
            !suggestedBundles.length ?
              <BodyRow>
                <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} >
                  <p>No suggested default billings available</p>
                </Cell>
              </BodyRow>
              :
              suggestedBundles.map((bundleRow, counter) => {
                return <Fragment key={counter}>
                  <BodyRow
                    key={counter}
                    onClick={() => handleSelectBundleRow(counter)}
                    onDoubleClick={() => showBundleExpectedStudents(bundleRow)}
                  >
                    <Cell width={columns[0].width} >
                      <p>{bundleRow.program_name}</p>
                    </Cell>
                    <Cell width={columns[1].width} >
                      <p>{bundleRow.voucher_type_name}</p>
                    </Cell>
                    <Cell width={columns[2].width} >
                      <p>{bundleRow.quantity}</p>
                    </Cell>
                    <Cell width={columns[3].width}>
                      {
                        checkBundleRow(bundleRow.fk_id_program)
                      }
                    </Cell>
                  </BodyRow>

                  <ExpansibleRow state={selectedBundleRows.includes(counter)} >
                    <Cell width={0.5}>
                      {
                        checkServiceRow(6, bundleRow.fk_id_program) // Enrollment service ID = 6
                      }
                    </Cell>
                    <Cell width={columns[0].width - 0.5} >
                      <p style={{ fontSize: '0.85rem' }} >Enrollment</p>
                    </Cell>
                    <Cell width={0.5}>
                      <IconButton
                        onClick={() => createFromSuggestedBundle('enrollment', bundleRow.fk_id_program)}
                      >
                        <ReadMore />
                      </IconButton>
                    </Cell>
                  </ExpansibleRow>

                  <ExpansibleRow state={selectedBundleRows.includes(counter)} >
                    <Cell width={0.5}>
                      {
                        checkServiceRow(1, bundleRow.fk_id_program) // Tuition service ID = 1
                      }
                    </Cell>
                    <Cell width={columns[0].width - 0.5} >
                      <p style={{ fontSize: '0.85rem' }} >Tuition</p>
                    </Cell>
                    <Cell width={0.5}>
                      <IconButton
                        onClick={() => createFromSuggestedBundle('tuition', bundleRow.fk_id_program)}
                      >
                        <ReadMore />
                      </IconButton>
                    </Cell>
                  </ExpansibleRow>

                  <ExpansibleRow state={selectedBundleRows.includes(counter)} >
                    <Cell width={0.5}>
                      {
                        checkServiceRow(2, bundleRow.fk_id_program) // Courseware service ID = 2
                      }
                    </Cell>
                    <Cell width={columns[0].width - 0.5} >
                      <p style={{ fontSize: '0.85rem' }} >Courseware</p>
                    </Cell>
                    <Cell width={0.5}>
                      <IconButton
                        onClick={() => createFromSuggestedBundle('courseware', bundleRow.fk_id_program)}
                      >
                        <ReadMore />
                      </IconButton>
                    </Cell>
                  </ExpansibleRow>
                </Fragment>
              })
        }
      </BodyContainer>
      {
        Boolean(suggestedBundles.length)
        &&
        <>
          <p style={{ color: 'white', marginTop: '0.5rem', fontSize: '0.9rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }} ><AdsClick fontSize='small' /> Double click a row to see the list of expected students for the suggested bundle.</p>
        </>
      }
    </Table>
  </>
}
