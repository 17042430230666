import { useState } from "react"
import { Container, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material"
import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Table } from "wed-components"
import { DeleteForever, Edit } from "@mui/icons-material"

export default function Material() {
  const [tab, setTab] = useState<string>('table')

  const renderComponent = () => {
    switch (tab) {
      case 'table':
        return <Table>
          <Overhead>
            <Cell width={3}>
              <p>
                Overhead title
              </p>
            </Cell>
          </Overhead>
          <HeaderRow>
            <Cell width={3}>
              <p>
                Header title
              </p>
            </Cell>
          </HeaderRow>
          <BodyContainer>
            <BodyRow>
              <Cell width={3}>
                <p>
                  Body text
                </p>
              </Cell>
              <Cell width={.5}>
                <IconButton >
                  <Edit />
                </IconButton>
              </Cell>
              <Cell width={.5}>
                <IconButton sx={{ backgroundColor: '#FA7C5FB0' }}>
                  <DeleteForever />
                </IconButton>
              </Cell>
            </BodyRow>
          </BodyContainer>
        </Table>
    }
  }

  return <Container>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Material
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
          <Tab value='table' label='Table' />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {renderComponent()}
      </Grid>
    </Grid>
  </Container>
}
