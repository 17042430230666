import { ViewBundle } from "./Bundles"
import { Dispatch, SetStateAction } from "react"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import SortingHeader02 from "../../assets/SortingHeader02"

type BundlesTableProps = {
  data: ViewBundle[],
  // sort: any,
  panel: any,
  // setSort: (Dispatch<SetStateAction<any>>),
  setPanel: React.Dispatch<React.SetStateAction<any>>,
  setSelectedDefaultBillingID?: Dispatch<SetStateAction<number | null>>,
  setAppliedBillings?: Dispatch<SetStateAction<number[]>>
}

export default function BundlesTable({ data, panel, setPanel }: BundlesTableProps) {
  // Constants
  const columnWidth = [.6, 2, 1.75, 1.75, .8, .8, 1]

  return (
    <Table>
      <HeaderRow>
        <Cell width={columnWidth[0]}>
          <p>
            ID
          </p>
        </Cell>
        <Cell width={columnWidth[1]}>
          <p>
            Name
          </p>
        </Cell>
        <Cell width={columnWidth[2]}>
          <p>
            Affiliation(s)
          </p>
        </Cell>
        <Cell width={columnWidth[3]}>
          <p>
            Voucher Type
          </p>
        </Cell>
        <Cell width={columnWidth[4]}>
          <p>
            Voucher Duration (days)
          </p>
        </Cell>
        <Cell width={columnWidth[5]}>
          <p>
            Voucher Amount
          </p>
        </Cell>
        <Cell flexGrow>
          <p>
            Description
          </p>
        </Cell>
      </HeaderRow>
      <BodyContainer>
        {
          data.length ?
            data.map((_row, index) => <BodyRow key={index} selected={_row.id === panel.id} onClick={() => setPanel({ show: false, id: _row.id })}>
              <Cell width={columnWidth[0]}>
                <p>
                  {_row.id}
                </p>
              </Cell>
              <Cell width={columnWidth[1]}>
                <p>
                  {_row.name}
                </p>
              </Cell>
              <Cell width={columnWidth[2]}>
                {_row.default_billings
                  .reduce((prev: string[], curr) => {
                    if (!prev.includes(curr.affiliation_name)) prev.push(curr.affiliation_name)

                    return prev
                  }, [])
                  .map((_affiliation, _index) => <p key={_index}>{_affiliation}</p>)
                }
              </Cell>
              <Cell width={columnWidth[3]}>
                <p>
                  {_row.voucher_type_name}
                </p>
              </Cell>
              <Cell width={columnWidth[4]}>
                <p>
                  {_row.voucher_duration_days}
                </p>
              </Cell>
              <Cell width={columnWidth[5]}>
                <p>
                  {_row.voucher_amount}
                </p>
              </Cell>
              <Cell flexGrow>
                <p>
                  {_row.description}
                </p>
              </Cell>
            </BodyRow>)
            :
            <BodyRow>
              <Cell flexGrow>
                <p>
                  No bundles to show.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  )
}

type BundlesTableSmallProps = {
  data: ViewBundle[],
  selectedBundleID: number | null,
  setSelectedBundleID: Dispatch<SetStateAction<number | null>>,
  setSelectedDefaultBillingID?: Dispatch<SetStateAction<number | null>>,
  setAppliedBillings?: Dispatch<SetStateAction<number[]>>
}

export function BundlesTableSmall({ data, selectedBundleID, setSelectedBundleID, setSelectedDefaultBillingID, setAppliedBillings }: BundlesTableSmallProps) {
  const columnWidth = [.5, 3, 2]

  return (
    <Table>
      <HeaderRow>
        <Cell width={columnWidth[0]}>
          <p>
            ID
          </p>
        </Cell>
        <Cell width={columnWidth[1]}>
          <p>
            Bundle name
          </p>
        </Cell>
        <Cell width={columnWidth[2]}>
          <p>
            Vouchers
          </p>
        </Cell>
      </HeaderRow>
      <BodyContainer>
        {
          data.length ?
            data.map((_row, counter) => {
              return <BodyRow key={counter} selected={_row.id === selectedBundleID}
                onClick={() => {
                  setSelectedBundleID(_row.id)
                  if (setAppliedBillings !== undefined) setAppliedBillings([])
                  if (setSelectedDefaultBillingID !== undefined) setSelectedDefaultBillingID(null)
                }}
              >
                <Cell width={columnWidth[0]}>
                  <p>
                    {_row.id}
                  </p>
                </Cell>
                <Cell width={columnWidth[1]}>
                  <p>
                    {_row.name}
                  </p>
                </Cell>
                <Cell width={columnWidth[2]}>
                  <p>
                    {_row.voucher_amount} - {_row.voucher_type_name}
                  </p>
                </Cell>
              </BodyRow>
            })
            :
            <BodyRow>
              <Cell flexGrow>
                <p>
                  No bundles to show.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  )
}
