import { BodyContainer, BodyRow, Cell, ExpansibleRow, HeaderRow, Table } from "wed-components"
import { ViewInvoice } from "./Invoice"
import { Fragment, useState } from "react"
import { formatNumberToMoney, getOrdinalFrom } from "../../Utils"

type Props = {
  currencySign: string | null
  data: ViewInvoice[]
}

export default function InvoiceTable({ currencySign, data }: Props) {
  const columnWidth = [5, 1.25, 3, 1, 1.25, 1.25]
  // General
  const [retractRows, setRetractRows] = useState<number[]>([])

  return <Table>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          Bundle / Service / Student
        </p>
      </Cell>
      <Cell width={columnWidth[1]}>
        <p>
          Grade
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          Installment
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Quantity
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>
          Gross Value
        </p>
      </Cell>
      <Cell width={columnWidth[5]}>
        <p>
          Due Value
        </p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        data.length ?
          data.map((group, index) => <Fragment key={index}>
            <BodyRow onClick={() => setRetractRows(prev => prev.includes(index) ? prev.filter((_) => _ !== index) : prev.concat(index))}>
              <Cell width={columnWidth[0] + columnWidth[1] + columnWidth[2]}>
                <p>
                  {group.bundle_name || group.default_billing_name || group.service_name}
                </p>
              </Cell>
              <Cell width={columnWidth[3]}>
                <p>
                  {
                    group.billings
                      .map((_grade) => _grade.number_of_installments)
                      .reduce((prev, curr) => prev += curr, 0)
                  }
                </p>
              </Cell>
              <Cell width={columnWidth[4]}>
                <p>
                  {currencySign} {
                    formatNumberToMoney(group.billings
                      .map((_grade) => _grade.total_gross_value)
                      .reduce((prev, curr) => prev += curr, 0))
                  }
                </p>
              </Cell>
              <Cell width={columnWidth[5]}>
                <p>
                  {currencySign} {
                    formatNumberToMoney(group.billings
                      .map((_grade) => _grade.total_due_value)
                      .reduce((prev, curr) => prev += curr, 0))
                  }
                </p>
              </Cell>
            </BodyRow>
            {/* Default Billings per Grade */}
            {
              group.billings.map((_defaultBillingPerGrade, gradeIndex) => <Fragment key={gradeIndex}>
                <ExpansibleRow state={!retractRows.includes(index)}>
                  <Cell width={.25}>
                    <></>
                  </Cell>
                  <Cell width={columnWidth[0] - .25 + columnWidth[1] + columnWidth[2]}>
                    <p>
                      {_defaultBillingPerGrade.default_billing_name ? `${_defaultBillingPerGrade.default_billing_name} (${_defaultBillingPerGrade.grade_name})` : _defaultBillingPerGrade.grade_name}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[3]}>
                    <p>
                      {_defaultBillingPerGrade.number_of_installments}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[4]}>
                    <p>
                      {currencySign} {formatNumberToMoney(_defaultBillingPerGrade.total_gross_value)}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[5]}>
                    <p>
                      {currencySign} {formatNumberToMoney(_defaultBillingPerGrade.total_due_value)}
                    </p>
                  </Cell>
                </ExpansibleRow>
                {/* Default Billings */}
                {
                  _defaultBillingPerGrade.customers.map((_customer, customersIndex) => <ExpansibleRow key={customersIndex} state={!retractRows.includes(index)}>
                    <Cell width={.5}>
                      <></>
                    </Cell>
                    <Cell width={columnWidth[0] - .5}>
                      <p>
                        {_customer.beneficiary_customer_name}
                      </p>
                    </Cell>
                    <Cell width={columnWidth[1]}>
                      <p>
                        {_customer.beneficiary_grade_name}
                      </p>
                    </Cell>
                    <Cell width={columnWidth[2]}>
                      <p>
                        {_customer.cashflow.map((_) => getOrdinalFrom(_.installment_number)).join(', ')} of {_customer.total_number_of_installments}
                      </p>
                    </Cell>
                    <Cell width={columnWidth[3]}>
                      <p>
                        {_customer.cashflow.length > 1 ? _customer.cashflow.length : null}
                      </p>
                    </Cell>
                    <Cell width={columnWidth[4]}>
                      <p>
                        {currencySign} {formatNumberToMoney(_customer.cashflow.reduce((prev, curr) => prev += curr.gross_value, 0))}
                      </p>
                    </Cell>
                    <Cell width={columnWidth[5]}>
                      <p>
                        {currencySign} {formatNumberToMoney(_customer.cashflow.reduce((prev, curr) => prev += curr.due_value, 0))}
                      </p>
                    </Cell>
                  </ExpansibleRow>)
                }
              </Fragment>
              )
            }
          </Fragment>
          )
          :
          <BodyRow>
            <Cell flexGrow>
              <p>
                No billings to show.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
    <HeaderRow>
      <Cell width={4}>
        <p style={{ fontSize: 20 }}>
          <b>
            Total gross: {currencySign} {
              formatNumberToMoney(data
                .map((_group) => _group.billings
                  .map((_grade) => _grade.total_gross_value)
                  .reduce((prev, curr) => prev += curr, 0))
                .reduce((prev, curr) => prev += curr, 0))
            }
          </b>
        </p>
      </Cell>
      <Cell width={4}>
        <p style={{ fontSize: 20 }}>
          <b>
            Total due: {currencySign} {
              formatNumberToMoney(data
                .map((_group) => _group.billings
                  .map((_grade) => _grade.total_due_value)
                  .reduce((prev, curr) => prev += curr, 0))
                .reduce((prev, curr) => prev += curr, 0))
            }
          </b>
        </p>
      </Cell>
    </HeaderRow>
  </Table >
}
