import { useCallback, useContext, useEffect, useState } from 'react'
import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Table } from 'wed-components'
import axios from 'axios';
import { utils, writeFile } from 'xlsx'
import DownloadIcon from '@mui/icons-material/Download'
import AlarmIcon from '@mui/icons-material/Alarm'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import styles from './MissingAssignmentsOverview.module.scss'
import PercentageBar from './PercentageBar'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import { DateTime } from 'luxon';
import { ViewEnrollment } from '../tools/credit/MissingAssignments'

export type MissinsAssignmentsOverviewType = {
  fk_id_affiliation: number
  affiliation_name: string,
  significantly_behind: number
  slightly_behind: number
  on_schedule: number
}

type ExcelData = {
  'Affiliation': string,
  'On schedule': number,
  'Slightly behind': number,
  'Significantly behind': number
}

export default function MissingAssignmentsOverview() {
  // Hooks
  const navigate = useNavigate()
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const columnWidth = [2, .6, .6, .6, 1.5]
  const term = 13
  // Data
  const [data, setData] = useState<MissinsAssignmentsOverviewType[]>([])

  const refreshTable = useCallback(async () => {
    try {
      // Get info from DataBase
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DIPLOMAT_URL}/missing-submissions?fk_id_term=${term}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
        }
      })

      console.log(data)

      const reducedData: MissinsAssignmentsOverviewType[] = data.enrollments.reduce((prev: MissinsAssignmentsOverviewType[], curr: ViewEnrollment) => {
        const index = prev.findIndex((_) => _.fk_id_affiliation === curr.fk_id_affiliation)

        if (index === -1) {
          prev.push({
            fk_id_affiliation: curr.fk_id_affiliation,
            affiliation_name: curr.affiliation_name,
            significantly_behind: curr.pace_indicator_name === 'Significantly behind' ? 1 : 0,
            slightly_behind: curr.pace_indicator_name === 'Slightly behind' ? 1 : 0,
            on_schedule: curr.pace_indicator_name === 'On schedule' ? 1 : 0
          })

          return prev
        }

        if (curr.pace_indicator_name === 'Significantly behind') prev[index].significantly_behind++
        if (curr.pace_indicator_name === 'Slightly behind') prev[index].slightly_behind++
        if (curr.pace_indicator_name === 'On schedule') prev[index].on_schedule++

        return prev
      }, [])

      setData(reducedData)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch missing assignments.', { autoClose: 5000 })
    }
  }, [setData])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const exportToExcel = () => {
    const wb = utils.book_new()

    let excelData: ExcelData[] = data.map((item) => {
      return {
        'Affiliation': item.affiliation_name,
        'Significantly behind': item.significantly_behind,
        'Slightly behind': item.slightly_behind,
        'On schedule': item.on_schedule
      }
    })

    const ws = utils.json_to_sheet(excelData)

    utils.book_append_sheet(wb, ws)
    writeFile(wb, `missing-assignments-overview-${DateTime.utc().toFormat('yyyy-MM-dd-HH-mm')}.xlsx`)
  }

  return <Table>
    <Overhead>
      <Cell width={3} flexGrow>
        <p style={{ fontSize: '22px', fontWeight: 'normal' }}>
          <strong>Missing Assignments </strong>
        </p>
        <p style={{ fontSize: '16px', fontWeight: 'normal' }}>
          {lookupTables.currentTerm.term_name}
        </p>
      </Cell>
      <Cell width={1.5}>
        <Button
          id='button-export-excel'
          startIcon={<DownloadIcon />}
          fullWidth
          sx={{ height: '80%', marginRight: '15px', color: 'white', backgroundColor: 'hsl(148.2, 56.5%, 35%)' }}
          disabled={!data.length}
          onClick={exportToExcel}
        >
          XLSX
        </Button>
      </Cell>
    </Overhead>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          Affiliation
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p style={{ textAlign: 'center' }} >
          {<AlarmIcon sx={{ color: 'red', transform: 'translateX(-6px)' }} />}
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p style={{ textAlign: 'center' }} >
          {<AlarmIcon sx={{ color: '#cf8311', transform: 'translateX(-6px)' }} />}
        </p>
      </Cell>
      <Cell width={columnWidth[1]}>
        <p style={{ textAlign: 'center' }} >
          {<AlarmOnIcon color="success" sx={{ transform: 'translateX(-6px)' }} />}
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p></p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        !data.length ?
          <BodyRow>
            <Cell width={4}>
              <p>
                Loading...
              </p>
            </Cell>
          </BodyRow>
          :
          data.map((row, index) => {
            return <BodyRow key={index} >
              <Cell width={columnWidth[0]}>
                <p className={styles.phover} onClick={() => navigate(`/credit-tools?fk_id_affiliation=${row.fk_id_affiliation}&fk_id_term=${term}#missingAssignments`)}>
                  {row.affiliation_name}
                </p>
              </Cell>
              <Cell width={columnWidth[1]}>
                <p
                  style={{
                    textAlign: 'end',
                    paddingRight: '16px',
                    color: row.significantly_behind === 0 ? '#888' : 'white'
                  }}
                >
                  {row.significantly_behind}
                </p>
              </Cell>
              <Cell width={columnWidth[2]}>
                <p
                  style={{
                    textAlign: 'end',
                    paddingRight: '16px',
                    color: row.slightly_behind === 0 ? '#888' : 'white'
                  }}
                >
                  {row.slightly_behind}
                </p>
              </Cell>
              <Cell width={columnWidth[3]}>
                <p
                  style={{
                    textAlign: 'end',
                    paddingRight: '16px',
                    color: row.on_schedule === 0 ? '#888' : 'white'
                  }}
                >
                  {row.on_schedule}
                </p>
              </Cell>
              <Cell width={columnWidth[4]} flexGrow >
                <PercentageBar rowData={row} />
              </Cell>
            </BodyRow>
          })
      }
    </BodyContainer>
  </Table>

}
