import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { useState, useContext, useEffect, useCallback } from 'react'
import { Affiliation, Form } from "./AffilliationSearch"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import SaveIcon from '@mui/icons-material/Save'
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"

export type AffiliationFormProps = {
	setForm?: React.Dispatch<React.SetStateAction<Form>>
	refreshTable?: () => void
}

export default function AffiliationForm({ setForm, refreshTable }: AffiliationFormProps) {
	// General
	const [disableSave, setDisableSave] = useState<boolean>(true)
	// Form
	const [info, setInfo] = useState<Affiliation>({
		name: '',
		tag: '',
		address: '',
		fk_id_state: null,
		fk_id_country: null,
		phone: '',
		email: '',
		fk_id_affiliation_type: null,
		is_active: true,
		billing_email: '',
		tax_payer_number: ''
	})
	// Hooks
	const { id } = useParams()
	const { lookupTables, refreshLookupTables } = useContext(LookupTablesContext)
	const navigate = useNavigate()

	const getAffiliation = useCallback(async () => {
		try {
			const { data } = await axios({
				method: 'GET',
				url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations/${id}`,
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
				}
			})

			console.log(data)

			setInfo(data)
		} catch (err) {
			console.log(err)
			toast.error('Could not fetch affiliation.')
		}
	}, [setInfo])

	// Requirements to submit
	useEffect(() => {
		if (!Boolean(info.name) || !Boolean(info.tag) || !Boolean(info.fk_id_country) || !Boolean(info.fk_id_affiliation_type)) {
			setDisableSave(true)
			return
		}

		setDisableSave(false)
	}, [info])

	useEffect(() => {
		if (id) {
			getAffiliation()
		}
	}, [id, getAffiliation])

	const onUpdate = useCallback(async () => {
		const _toast = toast('Updating affiliation', { toastId: 'toast-affiliations-update', isLoading: true })

		try {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations/${id}`,
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
				},
				data: info

			})

			toastSuccess(_toast, 'Affiliation updated.')

			refreshLookupTables(true)

			navigate('/affiliations')
		} catch (error) {
			console.log(error)
			toastError(_toast, 'Could not update affiliation.')
		}
	}, [info, refreshLookupTables, navigate])

	const onAdd = useCallback(async () => {
		const _toast = toast('Creating affiliation', { toastId: 'toast-affiliation-on-add', isLoading: true })

		try {
			const { data } = await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations`,
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
				},
				data: {
					name: info.name,
					tag: info.tag,
					address: info.address,
					fk_id_state: info.fk_id_state,
					fk_id_country: info.fk_id_country,
					phone: info.phone,
					email: info.email,
					fk_id_affiliation_type: info.fk_id_affiliation_type,
					is_active: info.is_active,
					billing_email: Boolean(info.billing_email) ? info.billing_email : null,
					tax_payer_number: Boolean(info.tax_payer_number) ? info.tax_payer_number : null,
				}
			})

			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_DIPLOMAT_URL}/sub-accounts`,
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
				},
				data: {
					id: data.insert.id,
					name: data.insert.name
				}
			})

			toastSuccess(_toast, 'New affiliation created.')

			refreshLookupTables(true)

			if (setForm) setForm({ show: false })

			if (refreshTable) refreshTable()
		} catch (err) {
			console.log(err)
			toastError(_toast, 'Could not create new affiliation.')
		}
	}, [info, setForm, refreshTable, refreshLookupTables])

	return <Grid container maxWidth={'md'} marginY={1} marginX={'auto'}>
		<Grid item xs={12}>
			<TextField id='form-field-name' label='Name' value={info.name} onChange={(e) => setInfo({ ...info, name: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-tag' label='TAG' value={info.tag} onChange={(e) => setInfo({ ...info, tag: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-phone' label='Phone' value={info.phone} onChange={(e) => setInfo({ ...info, phone: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-academic-email' label='Academic Email' value={info.email} onChange={(e) => setInfo({ ...info, email: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-address' label='Address' value={info.address} onChange={(e) => setInfo({ ...info, address: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-country' label='Country' value={info.fk_id_country || ''} onChange={(e) => setInfo({ ...info, fk_id_country: Number(e.target.value) })} select>
				{
					lookupTables.country.map((_country, counter) => (
						<MenuItem key={counter} id={`country-option-${_country.id}`} value={_country.id}>{_country.option}</MenuItem>
					))
				}
			</TextField>
		</Grid>

		<Grid item xs={12}>
			<TextField disabled={info.fk_id_country ? false : true} id='form-field-state' label='State' value={info.fk_id_state || ''} onChange={(e) => setInfo({ ...info, fk_id_state: Number(e.target.value) })} select>
				{
					lookupTables.state
						.filter((_state) => _state.fk_id_country === info.fk_id_country)
						.map((_state, index) => <MenuItem key={index} id={`state-option-${_state.id}`} value={_state.id}>{_state.option}</MenuItem>)
				}
			</TextField>
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-affiliation-type' label='Affiliation Type' value={info.fk_id_affiliation_type || ''} onChange={(e) => setInfo({ ...info, fk_id_affiliation_type: Number(e.target.value) })} select>
				{
					lookupTables.affiliation_type.map((_affiliationType, index) => {
						return <MenuItem key={index} id={`affiliation-type-option-${_affiliationType.id}`} value={_affiliationType.id}>{_affiliationType.option}</MenuItem>
					})
				}
			</TextField>
		</Grid>

		<Grid item xs={12}>
			<FormControlLabel
				control={
					<Checkbox
						id='form-field-is-active'
						checked={info.is_active}
						onChange={(e) => setInfo({ ...info, is_active: e.target.checked })}
					/>}
				label={'Active'}
			/>
		</Grid>

		<Grid item xs={12} >
			<Typography variant="h5" >Optional:</Typography>
		</Grid>
		<Grid item xs={12}>
			<TextField id='form-field-billing-email' label='Billing Email' value={info.billing_email} onChange={(e) => setInfo({ ...info, billing_email: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<TextField id='form-field-tax-payer-number' label='Tax Payer Number' value={info.tax_payer_number} onChange={(e) => setInfo({ ...info, tax_payer_number: e.target.value })} />
		</Grid>

		<Grid item xs={12}>
			<Grid item xs={3}>
				{
					<Button
						id='form-button-save'
						startIcon={id ? <ChangeCircleIcon /> : <SaveIcon />}
						onClick={id ? onUpdate : onAdd}
						sx={{ height: 56, width: "100%" }}
						disabled={disableSave}
					>
						{id ? 'Update' : 'Save'}
					</Button>
				}
			</Grid>
		</Grid>
	</Grid>
}