import { useState, useCallback, useEffect, useContext } from 'react'
import { Grid, MenuItem, TextField, Typography, Button } from '@mui/material'
import { Paid } from '@mui/icons-material'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { getAvailableVouchers, handleNumberInput } from '../../Utils'
import axios from 'axios'
import { FeedbackContext } from '../../../context/FeedbackContext'
import AvailableVouchersTables from '../billings/AvailableVouchersTables'
import { useMsal } from '@azure/msal-react'

type VouchersConsumeFormProps = {
  id: {
    type: 'fk_id_customer' | 'fk_id_student',
    fk_id: number | null
  },
  allowVouchersConsumption?: boolean,
  refreshTable?: () => void,
  closeForm?: () => void,
}

type balanceType = {
  fk_id_voucher_type: number,
  voucher_type_name: string,
  voucher_type_tag: string,
  balance: number
}

export type availableVouchersType = {
  fk_id_customer: number,
  customer_name: string,
  fk_id_affiliation: number,
  affiliation_name: string,
  beneficiary_balance: balanceType[],
  affiliation_balance: balanceType[]
}

type consumeDataType = {
  fk_id_voucher_type: '' | number,
  quantity: number
}

export default function VouchersConsumeForm({ id, allowVouchersConsumption = false, refreshTable, closeForm }: VouchersConsumeFormProps) {
  const { lookupTables } = useContext(LookupTablesContext)
  const { feedbackDispatch } = useContext(FeedbackContext)
  const { accounts } = useMsal()

  const [consumeData, setConsumeData] = useState<consumeDataType>({
    fk_id_voucher_type: '',
    quantity: 1
  })
  const [availableVouchers, setAvailableVouchers] = useState<availableVouchersType>({
    fk_id_customer: 0,
    customer_name: '',
    fk_id_affiliation: 0,
    affiliation_name: '',
    beneficiary_balance: [],
    affiliation_balance: []
  })
  const [numberOfVouchers, setNumberOfVouchers] = useState<'' | number>(1)
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  useEffect(() => {
    if (!consumeData.fk_id_voucher_type || !numberOfVouchers || !id.fk_id) {
      return setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [consumeData.fk_id_voucher_type, numberOfVouchers, id.fk_id, setDisableSubmit])

  useEffect(() => {
    getAvailableVouchers({ id: id, setAvailableVouchers: setAvailableVouchers, setLoading: setLoading })
  }, [id])

  const consumeVouchers = useCallback(async () => {
    setDisableSubmit(true)
    feedbackDispatch({ mode: 'waiting', message: 'Attempting to consume vouchers...' })

    await axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/vouchers/1`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      },
      data: {
        fk_id_voucher_type: consumeData.fk_id_voucher_type,
        quantity: numberOfVouchers,
        beneficiaries: [availableVouchers.fk_id_customer],
        updated_by: accounts[0].username || 'Cypress Testing',
      }
    })
      .then(() => {
        feedbackDispatch({ mode: 'done', message: 'Consumed requested vouchers' })
        if (refreshTable) refreshTable()
        if (closeForm) closeForm()
      })
      .catch(err => {
        console.log(err)
        feedbackDispatch({ mode: 'failed', message: err.response.data.message })
      })

    setDisableSubmit(false)
  }, [setDisableSubmit, availableVouchers.fk_id_customer, consumeData.fk_id_voucher_type, numberOfVouchers, feedbackDispatch, refreshTable, closeForm, accounts])

  return loading ?
    <Grid item xs={12}>Loading...</Grid>
    :
    <>
      <AvailableVouchersTables
        availableVouchers={availableVouchers}
        noInfo={Boolean(id.fk_id)}
      />

      {
        allowVouchersConsumption
        &&
        <>
          <Grid item xs={12} marginTop='1.5rem' >
            <Typography variant='h6' >Consume</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              id='form-voucher-type'
              label='Voucher Type'
              select
              disabled={lookupTables.loading || !id.fk_id}
              value={lookupTables.loading ? 0 : consumeData.fk_id_voucher_type}
              onChange={(e) => setConsumeData(prev => ({ ...prev, fk_id_voucher_type: Number(e.target.value) }))}
            >
              {
                lookupTables.voucherType.map((voucherType, counter) => {
                  return <MenuItem key={counter} value={voucherType.id} >{voucherType.option}</MenuItem>
                })
              }
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id='form-quantity'
              label='Number of vouchers'
              value={numberOfVouchers}
              onKeyDown={(e) => ['Minus', 'NumpadSubtract'].includes(e.code) ? e.preventDefault() : handleNumberInput(e)}
              onChange={(e) => e.target.value === '' ? setNumberOfVouchers('') : setNumberOfVouchers(Number(e.target.value))}
              disabled={!id.fk_id}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4} marginBottom='1.5rem' >
            <Button
              id='btn-consume'
              fullWidth
              sx={{ height: '56px' }}
              color='error'
              startIcon={<Paid />}
              disabled={disableSubmit}
              onClick={consumeVouchers}
            >
              Consume
            </Button>
          </Grid>
        </>
      }
    </>
}
