import { Container, Grid, Tab, Tabs } from "@mui/material"
import { useState } from 'react'
import DefaultBillings from "../components/finances/_billings/DefaultBillings"
import Bundles from "../components/finances/_billings/Bundles"
import Invoice from "../components/finances/_billings/Invoice"
import Header from "../components/header/Header"
import { useLocation } from "react-router"
import AccountBalance from "../components/finances/billings/AccountBalance"
import AssignBundles from "../components/finances/_billings/AssignBundles"
import Billings from "../components/finances/_billings/Billings"

export default function BillingsView() {
  // Hooks
  const { hash } = useLocation()
  // General
  const [tab, setTab] = useState(hash.replace('#', '') || 'billings')

  function renderComponent() {
    switch (tab) {
      case 'billings':
        return <Billings />
      case 'default-billings':
        return <DefaultBillings />
      case 'bundles':
        return <Bundles />
      case 'assign-bundles':
        return <AssignBundles />
      case 'invoice':
        return <Invoice />
      case 'account-balance':
        return <AccountBalance />
      default:
        return <Billings />
    }
  }

  return <>
    <Container maxWidth={false}>
      <br />
      <Grid container spacing={1}>
        <Header title={'Billings'} name={''} />

        <Grid item xs={12} >
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} >
            <Tab id='billings-tab-billings' label='Billings' value='billings' />
            <Tab id="billings-tab-default-billings" label='Default Billings' value='default-billings' />
            <Tab id="billings-tab-bundles" label='Bundles' value='bundles' />
            <Tab id="billings-tab-assign-bundles" label='Assign Bundles' value='assign-bundles' />
            <Tab id="billings-tab-invoice" label='Invoice' value='invoice' />
            <Tab id="billings-tab-balance" label='Account Balance' value='account-balance' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </>
}