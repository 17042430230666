import { BodyContainer, BodyRow, Cell, HeaderRow, Status, Table } from 'wed-components'
import { DisplayCreditsOverview, SortCreditsOverview, StudentCreditsOverview } from './CreditsOverview'
import SortingHeader02 from '../../assets/SortingHeader02'
import { Dispatch, SetStateAction } from 'react'
import { Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'

type CreditsOverviewTableProps = {
  data: StudentCreditsOverview[],
  sort: { state: SortCreditsOverview, setter: Dispatch<SetStateAction<SortCreditsOverview>> },
  display: DisplayCreditsOverview
  currentTerm: number
}

type CreditsOverviewColumns = {
  column: keyof StudentCreditsOverview,
  header: string,
  width: number
}

export default function CreditsOverviewTable({ data, sort, display, currentTerm }: CreditsOverviewTableProps) {
  const columns: CreditsOverviewColumns[] = [
    { column: 'id', header: 'ID', width: 0.5 },
    { column: 'student_full_name', header: 'Student', width: 2 },
    { column: 'program_name', header: 'Program', width: 1 },
    { column: 'grade_name', header: 'Grade', width: 1 },
    { column: 'student_status_type_name', header: 'Status', width: 1.25 },
    { column: 'egt_name', header: 'EGT', width: 1 },
  ]

  const subjectColumnsWidth = 0.75

  const renderCreditStatus = (value: number, min: number, total?: boolean) => {
    let bgColor: any = ''
    if (total) {
      if (value >= 23) bgColor = 'darkGreen'
      else if (value >= 6) bgColor = 'darkBlue'
      else if (value > 0) bgColor = 'strongYellow'
      else bgColor = 'darkRed'
    } else {
      if (value < min && value !== 0) bgColor = 'yellow'
      else if (value === min) bgColor = 'greenBlue'
      else if (value > min) bgColor = 'green'
      else bgColor = 'red'
    }

    return <Status color={bgColor} >
      {value.toString()}
    </Status>
  }

  const renderStudentStatus = (studentStatusTypeId: number, text: string ) => {
    switch (studentStatusTypeId) {
      case 1: // Admitted
        return <Status color='green' >{text}</Status>
      case 2: // Canceled
        return <Status color='red' >{text}</Status>
      case 8: // Applicant
        return <Status color='magenta' >{text}</Status>
      case 5: // Graduate
        return <Status color='darkGreen' >{text}</Status>
      default: // In Grad
        return <Status color='blue' >{text}</Status>
    }
  }

  return <Table>
    <HeaderRow>
      {/* ID */}
      <Cell width={columns[0].width}>
        <SortingHeader02 text={columns[0].header} column={columns[0].column} sort={sort.state} setSort={sort.setter} />
      </Cell>
      {/* Student Full Name */}
      <Cell width={columns[1].width} flexGrow >
        <SortingHeader02 text={columns[1].header} column={columns[1].column} sort={sort.state} setSort={sort.setter} />
      </Cell>
      {/* Program */}
      <Cell width={columns[2].width}>
        <SortingHeader02 text={columns[2].header} column={'fk_id_program'} sort={sort.state} setSort={sort.setter} />
      </Cell>
      {/* Grade */}
      <Cell width={columns[3].width}>
        <SortingHeader02 text={columns[3].header} column={'fk_id_grade'} sort={sort.state} setSort={sort.setter} />
      </Cell>
      {/* Status */}
      <Cell width={columns[4].width}>
        <SortingHeader02 text={columns[4].header} column={columns[4].column} sort={sort.state} setSort={sort.setter} />
      </Cell>
      {/* SUBJECTS */}
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Language Arts' arrow placement='top' ><p>LA {'(4)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Social Studies' arrow placement='top' ><p>SS {'(3)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Foreign Languages' arrow placement='top' ><p>FL {'(2)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Science' arrow placement='top' ><p>SC {'(3)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Mathematics' arrow placement='top' ><p>MA {'(4)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Physical Education' arrow placement='top' ><p>PE {'(1)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Arts' arrow placement='top' ><p>AR {'(1)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth}>
        <Tooltip title='Electives' arrow placement='top' ><p>EL {'(5)'}</p></Tooltip>
      </Cell>
      <Cell width={subjectColumnsWidth + 0.25}>
        <Tooltip title='Total' arrow placement='top' ><p>Total</p></Tooltip>
      </Cell>
      {/* Expected Graduation Term */}
      <Cell width={columns[5].width}>
        <SortingHeader02 text={columns[5].header} column={'fk_id_egt'} sort={sort.state} setSort={sort.setter} />
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        !data.length ?
          <BodyRow>
            <Cell width={columns.reduce((prev, curr) => prev + curr.width, 0)} >
              <p>No students available</p>
            </Cell>
          </BodyRow>
          :
          data.map((row, index) => {
            return <BodyRow key={index} >
              <Cell width={columns[0].width} >
                <p>{row.id}</p>
              </Cell>
              <Cell width={columns[1].width} flexGrow >
                <p>
                  {row.student_full_name}
                </p>
              </Cell>
              <Cell width={columns[2].width} >
                <p>{row.program_tag}</p>
              </Cell>
              <Cell width={columns[3].width} >
                <p>{row.grade_name}</p>
              </Cell>
              <Cell width={columns[4].width} >
                {renderStudentStatus(row.fk_id_student_status_type, row.student_status_type_name)}
              </Cell>
              {/* SUBJECTS */}
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].languageArts, 4)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].socialStudies, 3)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].foreignLanguages, 2)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].science, 3)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].mathematics, 4)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].physicalEducation, 1)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].arts, 1)}
              </Cell>
              <Cell width={subjectColumnsWidth} >
                {renderCreditStatus(row[display].electives, 5)}
              </Cell>
              <Cell width={subjectColumnsWidth + 0.25} >
                {renderCreditStatus(row[display].total, 23, true)}
              </Cell>
              {/* EGT */}
              <Cell width={columns[5].width} >
                <p style={{ color: row.fk_id_egt < currentTerm ? '#FFF6' : 'white' }} >
                  {row.egt_name}
                </p>
              </Cell>
            </BodyRow>
          })
      }
    </BodyContainer>
  </Table>
}
