import { useContext } from "react"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { IconButton } from "@mui/material"

import { LookupTablesContext } from '../../context/LookupTablesContext'

export type Permissions = {
  id?: number
  fk_id_affiliation: number
  fk_id_faculty_type: number
}

type FacultyPermissionsFormTableProps = {
  permissions: Permissions[]
  deletePermission: (aff: number, faculty: number) => void
}

export default function FacultyPermissionsFormTable({ permissions, deletePermission }: FacultyPermissionsFormTableProps) {
  // Constants
  const columnWidth = [2.75, 2.75]
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)

  return <>
    <Table>
      <HeaderRow>
        <Cell width={columnWidth[0]}>
          Affiliation
        </Cell>
        <Cell width={columnWidth[1]}>
          Role
        </Cell>
      </HeaderRow>
      <BodyContainer>
        {
          permissions.length ?
            permissions.map((row, index) => <BodyRow key={index}>
              <Cell width={columnWidth[0]}>
                {
                  lookupTables.affiliation.find((_) => _.id === row.fk_id_affiliation)?.option
                }
              </Cell>
              <Cell width={columnWidth[1]}>
                {
                  lookupTables.facultyType.find((_) => _.id === row.fk_id_faculty_type)?.option
                }
              </Cell>
              <Cell width={1}>
                <IconButton
                  id='delete-permission-button'
                  onClick={() => deletePermission(row.fk_id_affiliation, row.fk_id_faculty_type)}
                  sx={{ color: 'white' }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Cell>
            </BodyRow>
            )
            :
            <BodyRow>
              <Cell width={3}>
                <p>
                  No permissions to show.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  </>
}