import { BounceLoader, GridLoader, HashLoader, PuffLoader, ScaleLoader } from "react-spinners"

type Props = {
  height?: string,
}

export default function PreLoader({ height }: Props) {
  const containerStyle: React.CSSProperties = {
    height: height ? height : "90vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    color: "#9C9C9C"
  }

  const color = '#FFF'

  const loaders = [
    <BounceLoader size={100} color={color} aria-label="Loading Spinner" />,
    <GridLoader size={30} color={color} aria-label="Loading Spinner" />,
    <HashLoader size={100} color={color} aria-label="Loading Spinner" />,
    <PuffLoader size={100} color={color} aria-label="Loading Spinner" />,
    <ScaleLoader height={100} width={12} color={color} aria-label="Loading Spinner" />
  ]

  return <div style={containerStyle}>
    {
      loaders[Math.floor(Math.random() * loaders.length)]
    }
    <p>Loading...</p>
  </div>
}