import React, { ReactElement, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { paymentRequest } from './Payments'
import { DateTime } from 'luxon'
import { renderCurrency } from '../../Utils'

type ServiceInfoGridProps = {
  selectedRequest: null | paymentRequest
}

export default function ServiceInfoGrid({ selectedRequest }: ServiceInfoGridProps) {
  const renderText = useMemo(() => {
    let text: { [key: string]: ReactElement | string } = {
      quantity: '',
      amount: <Typography variant='h6' >Total amount: <strong>$ 0.00</strong></Typography>,
      description: <small>Description</small>,
      comments: '-'
    }

    if (!selectedRequest) return text
    // Updating description
    text.description = <small>{selectedRequest.default_payment_description}</small>

    const currencySymbol = renderCurrency(selectedRequest.fk_id_currency)
    // Updating quantity and amount
    if (selectedRequest.fk_id_payment_type === 1) {
      // Per hour
      const hours = (selectedRequest.dependence_multiplier / 60)
      const totalAmount = (hours * selectedRequest.currency_per_multiplier).toFixed(2)
      text.quantity = <p > {selectedRequest.dependence_multiplier} minutes (<strong>{hours.toFixed(2)} hours</strong>)</p>
      text.amount = <p style={{ fontSize: '1.25rem' }} ><strong>{currencySymbol} {totalAmount}</strong></p>
    } else {
      // Per unit
      const totalAmount = (selectedRequest.dependence_multiplier * selectedRequest.currency_per_multiplier).toFixed(2)
      text.amount = <p style={{ fontSize: '1.25rem' }} ><strong>{currencySymbol} {totalAmount}</strong></p>

      if (selectedRequest.fk_id_service_provided_type === 1) {
        text.quantity = <p > {selectedRequest.dependence_multiplier} graded student activities.</p>
      }
      if (selectedRequest.fk_id_service_provided_type === 3) {
        text.quantity = <p > {selectedRequest.dependence_multiplier} masterclass</p>
      }
      if (selectedRequest.fk_id_service_provided_type === 4) {
        text.quantity = <p > {selectedRequest.dependence_multiplier} onboarding 4hs training</p>
      }
    }

    if (Boolean(selectedRequest.lesson_number)) {
      text.comments = (`Payment request submitted automatically for section ${selectedRequest.section_name} - ${selectedRequest.class_tag}, Lesson ${selectedRequest.lesson_number}: ${selectedRequest.lesson_content}`)
    } else {
      text.comments = (selectedRequest.service_provided_comment || '-')
    }

    return text
  }, [selectedRequest])

  return <>
    <Grid item xs={3}>
      <Typography variant='h6' >Beneficiary:</Typography>
    </Grid>
    <Grid item xs={9} display='flex' alignItems='center' >
      {selectedRequest?.customer_beneficiary_name || ''}
    </Grid>

    <Grid item xs={3}>
      <Typography variant='h6' >Service:</Typography>
    </Grid>
    <Grid item xs={9} display='flex' alignItems='center' >
      {selectedRequest?.service_provided_type_name || ''}
    </Grid>

    <Grid item xs={3}>
      <Typography variant='h6' >Date:</Typography>
    </Grid>
    <Grid item xs={9} display='flex' alignItems='center' >
      {selectedRequest ? DateTime.fromISO(selectedRequest.service_provided_date).toFormat('dd-LLL-yyyy') : ''}
    </Grid>

    <Grid item xs={3}>
      <Typography variant='h6' >Quantity:</Typography>
    </Grid>
    <Grid item xs={9} display='flex' alignItems='center' >
      {renderText.quantity}
    </Grid>

    <Grid item xs={3}>
      <Typography variant='h6' >Total amount:</Typography>
    </Grid>
    <Grid item xs={9} display='flex' alignItems='center' >
      {renderText.amount}
    </Grid>
    <Grid item xs={3} />
    <Grid item xs={9} display='flex' alignItems='center' >
      {renderText.description}
    </Grid>

    <Grid item xs={3}>
      <Typography variant='h6' >Comments:</Typography>
    </Grid>
    <Grid item xs={9} minHeight='120px' paddingTop='16px !important' >
      {renderText.comments}
    </Grid>
  </>
}
