import { useContext, useMemo, useCallback, useState, useEffect } from 'react'
import { Button, Grid, MenuItem, RadioGroup, TextField, FormControlLabel, Radio, Autocomplete } from '@mui/material'
import { RequestQuote } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { handleNumberInput } from '../../Utils'
import { FeedbackContext } from '../../../context/FeedbackContext'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { CustomerView, beneficiaryType } from './Vouchers'

type VouchersIssueFormProps = {
  selectedBeneficiary: beneficiaryType | null,
  customers: CustomerView[],
  refreshTable: () => void,
  closeForm: () => void,
}

type exportDataType = {
  voucher_duration: number,
  vouchers: {
    fk_id_voucher_type: number,
    fk_id_customer_payer: number,
    fk_id_customer_beneficiary: number | null,
    description: string | null,
    updated_by: string
  }[]
}

type optionsType = {
  payerCustomerType: number,
  beneficiaryCustomerType: number,
  numberOfVouchers: '' | number
}

type AutocompleteType = {
  id: number,
  label: string,
  fk_id_affiliation: number
} | null

type dataType = {
  fk_id_voucher_type: '' | number,
  voucher_duration: '' | number,
  description: string,
  customer_payer: AutocompleteType,
  customer_beneficiary: AutocompleteType,
}

export default function VouchersIssueForm({ selectedBeneficiary, refreshTable, closeForm, customers }: VouchersIssueFormProps) {
  const { lookupTables } = useContext(LookupTablesContext)
  const { feedbackDispatch } = useContext(FeedbackContext)
  const { accounts } = useMsal()

  const [options, setOptions] = useState<optionsType>({
    payerCustomerType: 1,
    beneficiaryCustomerType: 2,
    numberOfVouchers: 1
  })
  const [data, setData] = useState<dataType>({
    fk_id_voucher_type: '',
    voucher_duration: 365,
    description: '',
    customer_payer: null,
    customer_beneficiary: null
  })
  // Pre-populate data with payer and beneficiary if a beneficiary was selected
  useEffect(() => {
    setOptions(prev => ({ ...prev, payerCustomerType: 1, beneficiaryCustomerType: 2 }))
    if (!selectedBeneficiary) return setData({
      fk_id_voucher_type: '',
      voucher_duration: 365,
      description: '',
      customer_payer: null,
      customer_beneficiary: null
    })

    const affiliationCustomer = customers.find(customer => customer.fk_id_customer_type === 1 && customer.fk_id_customer_dependence === selectedBeneficiary.beneficiary_fk_id_affiliation)
    setData(prev => ({
      ...prev,
      customer_payer: affiliationCustomer ? {
        id: affiliationCustomer.id,
        label: affiliationCustomer.customer_affiliation_name,
        fk_id_affiliation: selectedBeneficiary.beneficiary_fk_id_affiliation
      } : null,
      customer_beneficiary: {
        id: selectedBeneficiary.fk_id_customer_beneficiary,
        label: selectedBeneficiary.beneficiary_name,
        fk_id_affiliation: selectedBeneficiary.beneficiary_fk_id_affiliation
      }
    }))
  }, [selectedBeneficiary, setData, setOptions, customers])

  const startOfToday = DateTime.utc().setZone('America/New_York').startOf('day')
  const expirationDateString = useMemo(() => {
    if (data.voucher_duration === '') return 'Set a duration'
    const expirationDate = startOfToday.plus({ days: data.voucher_duration }).toFormat('dd-LLL-yyyy');
    return expirationDate
  }, [startOfToday, data.voucher_duration])

  const [disableSubmit, setDisableSubmit] = useState(true)
  useEffect(() => {
    if (!data.customer_payer || !data.voucher_duration || !data.fk_id_voucher_type || !options.numberOfVouchers) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [data.customer_payer, data.voucher_duration, data.fk_id_voucher_type, options.numberOfVouchers])

  const submitForm = useCallback(async () => {
    setDisableSubmit(true)
    if (data.voucher_duration === '') return feedbackDispatch({ mode: 'failed', message: 'Set voucher\'s duration' })
    const exportData: exportDataType = {
      voucher_duration: data.voucher_duration,
      vouchers: []
    }

    if (options.numberOfVouchers === '' || data.fk_id_voucher_type === '' || !data.customer_payer) return feedbackDispatch({ mode: 'failed', message: 'Fill out required data' })
    for (let i = 0; i < options.numberOfVouchers; i++) {
      exportData.vouchers.push({
        fk_id_voucher_type: data.fk_id_voucher_type,
        fk_id_customer_payer: data.customer_payer.id,
        fk_id_customer_beneficiary: data.customer_beneficiary?.id || null,
        description: data.description,
        updated_by: accounts[0]?.username || 'Cypress testing'
      })
    }

    console.log({exportData})
    feedbackDispatch({ mode: 'waiting', message: 'Issuing vouchers...' })
    await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/vouchers`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      },
      data: exportData
    })
    .then(() => {
      console.log()
      feedbackDispatch({ mode: 'done', message: 'Vouchers issued successfully.' })
      refreshTable()
      closeForm()
    })
    .catch(err => {
      console.log(err)
      feedbackDispatch({ mode: 'failed', message: 'Unable to issue voucher(s).' })
    })
    setDisableSubmit(false)
  }, [data, options.numberOfVouchers, feedbackDispatch, refreshTable, closeForm, setDisableSubmit, accounts])

  const payerAutocompleteOptions: AutocompleteType[] = useMemo(() => {
    return customers
      .filter(customer => customer.fk_id_customer_type === options.payerCustomerType)
      .map(customer => ({ id: customer.id, label: customer.customer_name, fk_id_affiliation: customer.customer_affiliation_id }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [customers, options.payerCustomerType])

  const beneficiaryAutocompleteOptions: AutocompleteType[] = useMemo(() => {
    return customers
      .filter(customer => customer.fk_id_customer_type === options.beneficiaryCustomerType)
      .filter(customer => {
        if (options.payerCustomerType === 1 && data.customer_payer) {
          return customer.customer_affiliation_id === data.customer_payer.fk_id_affiliation
        } else if (options.payerCustomerType === 2 && data.customer_payer) {
          return customer.id === data.customer_payer.id
        }
        return true
      })
      .map(customer => ({ id: customer.id, label: customer.customer_name, fk_id_affiliation: customer.customer_affiliation_id }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [customers, options.beneficiaryCustomerType, options.payerCustomerType, data.customer_payer])

  return <>
  <Grid item xs={12}>
      <p>Payer type:</p>
      <RadioGroup id='form-payer-type' row name="form-payer-type" value={options.payerCustomerType} onChange={(e) => setOptions(prev => ({ ...prev, payerCustomerType: Number(e.target.value) }))} >
        <FormControlLabel value={1} control={<Radio />} label="Affiliation" />
        <FormControlLabel value={2} control={<Radio />} label="Student" />
      </RadioGroup>
    </Grid>
    <Grid item xs={12} marginBottom='1.5rem' >
      <Autocomplete
        id='form-payer'
        options={payerAutocompleteOptions}
        renderInput={params => <TextField {...params} label='Payer' />}
        value={data.customer_payer}
        onChange={(e, newValue) => {
          setData(prev => ({
            ...prev, customer_payer: newValue, customer_beneficiary: null
          }))
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
      />
    </Grid>
    <Grid item xs={12}>
      <p>Beneficiary type:</p>
      <RadioGroup id='form-beneficiary-type' row name="form-beneficiary-type" value={options.beneficiaryCustomerType} onChange={(e) => setOptions(prev => ({ ...prev, beneficiaryCustomerType: Number(e.target.value) }))} >
        <FormControlLabel value={1} control={<Radio />} label="Affiliation" />
        <FormControlLabel value={2} control={<Radio />} label="Student" />
      </RadioGroup>
    </Grid>
    <Grid item xs={12} marginBottom='1.5rem'>
      <Autocomplete
        id='form-beneficiary'
        options={beneficiaryAutocompleteOptions}
        renderInput={params => <TextField {...params} label='Beneficiary' />}
        value={data.customer_beneficiary}
        onChange={(e, newValue) => {
          setData(prev => ({
            ...prev, customer_beneficiary: newValue
          }))
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        id='form-voucher-type'
        label='Voucher Type'
        select
        disabled={lookupTables.loading}
        value={lookupTables.loading ? 0 : data.fk_id_voucher_type}
        onChange={(e) => setData(prev => ({ ...prev, fk_id_voucher_type: Number(e.target.value) }))}
      >
        {
          lookupTables.voucherType.map((voucherType, counter) => {
            return <MenuItem key={counter} value={voucherType.id} >{voucherType.option}</MenuItem>
          })
        }
      </TextField>
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='form-duration'
        label='Voucher duration'
        value={data.voucher_duration}
        onKeyDown={(e) => ['Minus', 'NumpadSubtract'].includes(e.code) ? e.preventDefault() : handleNumberInput(e)}
        onChange={(e) => setData(prev => ({ ...prev, voucher_duration: e.target.value === '' ? '' : Number(e.target.value) }))}
      />
    </Grid>
    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <p>Expiration date:</p>
      <strong>{expirationDateString}</strong>
    </Grid>
    <Grid item xs={12}>
      <TextField
        id='form-description'
        label='Description'
        value={data.description}
        onChange={(e) => setData(prev => ({ ...prev, description: e.target.value }))}
        multiline
        rows={4}
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        id='form-quantity'
        label='Number of vouchers'
        value={options.numberOfVouchers}
        onKeyDown={(e) => ['Minus', 'NumpadSubtract'].includes(e.code) ? e.preventDefault() : handleNumberInput(e)}
        onChange={(e) => setOptions(prev => ({ ...prev, numberOfVouchers: e.target.value === '' ? '' : Number(e.target.value) }))}
      />
    </Grid>
    <Grid item xs={4} />
    <Grid item xs={4} >
      <Button
        id='btn-issue-vouchers'
        startIcon={<RequestQuote />}
        fullWidth
        sx={{ height: '100%', color: 'white' }}
        color='info'
        disabled={disableSubmit}
        onClick={submitForm}
      >
        Issue
      </Button>
    </Grid>
  </>
}
