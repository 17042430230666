import { useContext, useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import styles from './Feedback.module.css'
import { FeedbackContext } from '../context/FeedbackContext'

export default function Feedback() {
  const [showFeedback, setShowFeedback] = useState<boolean>(false)
  const feedback: { [key: string]: JSX.Element } = {
    done: <CheckCircleIcon sx={{ width: '100%', height: '100%', color: 'green' }} />,
    waiting: <CircularProgress sx={{ width: '100%', height: '100%' }} />,
    failed: <CancelIcon sx={{ width: '100%', height: '100%', color: 'red' }} />
  }

  // Hooks
  const { feedbackState } = useContext(FeedbackContext)

  // Timer
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)

  useEffect(() => {
    // If we are waiting a feedback, only show the component
    if (feedbackState.mode === 'waiting') {
      setShowFeedback(true)
    }
    else if (feedbackState.mode === 'hidden') {
      setShowFeedback(false)
    }
    else {
      setShowFeedback(true)
      // Cleaning the timeout to prevent more than one instance
      clearTimeout(timerRef.current as NodeJS.Timeout)
      // Start a timeout for the new search
      timerRef.current = setTimeout(() => {
        setShowFeedback(false)
      }, 2000)
    }
  }, [feedbackState])

  return (
    <div id='feedback' className={`${styles.container} ${showFeedback ? styles.shown : styles.hidden}`}>
      <div className={styles.icon}>{feedback[feedbackState.mode]}</div>
      <div className={styles.message}>{feedbackState.message}</div>
    </div>
  )
}
