import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Filters, PdfInvoiceRow, ViewInvoice } from './Invoice'
import { DocsContainer, InvoiceDocument } from 'wed-components'
import { DateTime } from 'luxon'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import axios from 'axios'
import { AutocompleteOption } from '../../../utilities/generalPurposeTypes'
import { toast } from 'react-toastify'
import { formatNumberToMoney, getOrdinalFrom } from '../../Utils'
import { toastSuccess } from '../../assets/customToasts'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

type InvoicePdfModalProps = {
  affiliation: AutocompleteOption
  currency: { option: string, sign: string }
  data: ViewInvoice[]
  filters: Filters
}

type ViewAffiliation = {
  address: string
  billing_email: string
  email: string | null
  fk_id_affiliation_type: number
  fk_id_country: number
  fk_id_state: number
  id: number
  is_active: boolean
  name: string
  phone: string
  tag: string
  tax_payer_number: string
}

export default function InvoicePDFModal({ affiliation, currency, data, filters }: InvoicePdfModalProps) {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [affiliationInfo, setAffiliationInfo] = useState<ViewAffiliation | null>(null)
  const [disableDownload, setDisableDownload] = useState(false)
  const [zoom, setZoom] = useState(60)
  const studentsPerPage = 36
  // useMemos
  const pdfInvoiceRows = useMemo<PdfInvoiceRow[]>(() => {
    const _ = data.reduce((prev: PdfInvoiceRow[], curr) => {
      prev.push(
        {
          name: curr.bundle_name || curr.default_billing_name || curr.service_name || '',
          grade: null,
          installment: null,
          quantity: curr.billings.map((_defaultBilling) => _defaultBilling.number_of_installments).reduce((prev, curr) => prev += curr, 0),
          grossValue: formatNumberToMoney(curr.billings.map((_defaultBilling) => _defaultBilling.total_gross_value).reduce((prev, curr) => prev += curr, 0)),
          dueValue: formatNumberToMoney(curr.billings.map((_defaultBilling) => _defaultBilling.total_due_value).reduce((prev, curr) => prev += curr, 0))
        },
        ...curr.billings.reduce((_prev: PdfInvoiceRow[], _curr) => {
          const _defaultBillingRow = {
            name: curr.fk_id_bundle ? `${_curr.default_billing_name} (${_curr.grade_name})` : _curr.grade_name,
            grade: null,
            installment: null,
            quantity: _curr.number_of_installments,
            grossValue: formatNumberToMoney(_curr.total_gross_value),
            dueValue: formatNumberToMoney(_curr.total_due_value),
            rowMargin: 1
          }

          const _customerRows = _curr.customers.map((_customer) => ({
            name: _customer.beneficiary_customer_name,
            grade: _customer.beneficiary_grade_name,
            installment: _customer.cashflow.map((_installment) => getOrdinalFrom(_installment.installment_number)).join(', ') + ' of ' + _customer.total_number_of_installments,
            quantity: _customer.cashflow.length > 1 ? _customer.cashflow.length : null,
            grossValue: formatNumberToMoney(_customer.cashflow.map((_installment) => _installment.gross_value).reduce((__prev, __curr) => __prev += __curr, 0)),
            dueValue: formatNumberToMoney(_customer.cashflow.map((_installment) => _installment.due_value).reduce((__prev, __curr) => __prev += __curr, 0)),
            rowMargin: 2
          }))

          _prev.push(
            _defaultBillingRow,
            ..._customerRows
          )

          return _prev
        }, [])
      )

      return prev
    }, [])

    return _
  }, [data])

  const fetchAffiliation = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations/${affiliation.id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setAffiliationInfo(data)
    } catch (err) {
      console.log(err)

      toast.error('Could not load affiliation information.')
    }
  }, [])

  useEffect(() => {
    fetchAffiliation()
  }, [fetchAffiliation])

  const handleZoom = (direction: "decrease" | "augment") => {
    setZoom(prev => {
      if (direction === 'augment' && prev < 100) return prev + 10
      else if (direction === 'decrease' && prev > 10) return prev - 10
      else return prev
    })
  }

  const downloadPdf = async () => {
    setDisableDownload(true)
    const _toast = toast('Exporting to PDF file', { toastId: 'toast-invoice-download-pdf', isLoading: true })

    const pdf = new jsPDF('p', 'px', 'a4', true)

    var width = pdf.internal.pageSize.getWidth()
    var height = pdf.internal.pageSize.getHeight()
    const numberOfPages = Math.ceil(pdfInvoiceRows.length / 36)

    for (var page = 0; page < numberOfPages; page++) {
      const nextPage = document.getElementById(`invoice-page-${page}`)

      if (nextPage) {
        const canvas = await html2canvas(nextPage)
        const image = canvas.toDataURL('image/png')

        pdf.addImage(image, 'PNG', 0, 0, width, height)

        // If there's one more page, keep appending
        if (page + 1 < numberOfPages) pdf.addPage()
      }
    }

    const fileName = `Invoice - ${affiliationInfo?.name} (${filters.startDate?.toFormat('MMM-yy')}).pdf`
    pdf.save(fileName)

    setDisableDownload(false)
    toastSuccess(_toast, 'File donwloaded.')
  }

  const documents = useMemo(() => {
    const _documents: JSX.Element[] = []
    const numberOfPages = Math.ceil(pdfInvoiceRows.length / studentsPerPage)

    for (var page = 0; page < numberOfPages; page++) {
      _documents.push(
        <InvoiceDocument
          key={page}
          affiliationInfo={{
            name: affiliationInfo?.name || '',
            address: affiliationInfo?.address || '',
            cnpj: affiliationInfo?.tax_payer_number || '',
            email: affiliationInfo?.billing_email || affiliationInfo?.email || '',
          }}
          currency={currency}
          data={pdfInvoiceRows.slice(page * studentsPerPage, (page + 1) * studentsPerPage)}
          issueDate={DateTime.now().toFormat('dd-MMM-yyyy')}
          page={page}
          pageNumberText={`${page + 1} of ${numberOfPages}`}
          totalInfo={
            page + 1 === numberOfPages ?
              {
                grossValue: formatNumberToMoney(data
                  .map((_group) => _group.billings
                    .map((_defaultBilling) => _defaultBilling.total_gross_value)
                    .reduce((prev, curr) => prev += curr, 0))
                  .reduce((prev, curr) => prev += curr, 0)),
                dueValue: formatNumberToMoney(data
                  .map((_group) => _group.billings
                    .map((_defaultBilling) => _defaultBilling.total_due_value)
                    .reduce((prev, curr) => prev += curr, 0))
                  .reduce((prev, curr) => prev += curr, 0))
              }
              :
              undefined
          }
        />
      )
    }

    return _documents
  }, [data, affiliationInfo, lookupTables, pdfInvoiceRows])

  return <DocsContainer
    disabled={disableDownload || !affiliationInfo}
    helperText='No invoice info available'
    onDownload={downloadPdf}
    zoom={zoom}
    handleZoom={handleZoom}
    numberOfDocuments={documents.length}
  >
    {documents}
    {

    }
  </DocsContainer>

}
