import styles from './CreditsOverview.module.css'

type CreditsOverviewLegendProps = {
  numberOfRows: number
}

export default function CreditsOverviewLegend({ numberOfRows }: CreditsOverviewLegendProps) {
  return <div className={styles.legendContainer} >
    <span className={styles.count}>Students: {numberOfRows}</span>
    <ul className={styles.legend}>
      <li>Credits per subject:</li>
      <li className={styles.red} >No credits</li>
      <li className={styles.yellow} >Less than required</li>
      <li className={styles.greenBlue} >Minimum requirements</li>
      <li className={styles.green} >More than required</li>
    </ul>
  </div>
}
