import { Dispatch, Fragment, SetStateAction } from 'react'
import { Table, HeaderRow, Cell, BodyContainer, BodyRow, ExpansibleRow } from 'wed-components'
import { beneficiaryType, filtersType } from './Vouchers'

type VouchersBeneficiaryTableProps = {
  beneficiaryList: beneficiaryType[],
  handleSelectBeneficiary: (beneficiary: beneficiaryType) => void
  selectedBeneficiary: beneficiaryType | null,
  setFilters: Dispatch<SetStateAction<filtersType>>
}

export default function VouchersBeneficiaryTable({ beneficiaryList, handleSelectBeneficiary, selectedBeneficiary, setFilters }: VouchersBeneficiaryTableProps) {
  const columns = [
    { field: 'id', header: 'ID', width: 0.5 },
    { field: 'beneficiary_name', header: 'Beneficiary', width: 2.5 },
    { field: 'beneficiary_grade', header: 'Grade / Balance', width: 1 },
  ]

  const childRowText = { fontSize: '0.9rem', color: '#FFF9' }

  return <Table>
    <HeaderRow>
      {
        columns.map((column, counter) => {
          return <Cell key={counter} width={column.width} flexGrow={column.field === 'beneficiary_name'} >
            <p>{column.header}</p>
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        !beneficiaryList.length ?
          <BodyRow>
            <Cell width={columns.reduce((acc, curr) => acc += curr.width, 0)} >
              <p>No beneficiaries available</p>
            </Cell>
          </BodyRow>
          :
          beneficiaryList.map((beneficiary, counter) => {
            return <Fragment key={counter} >
              <BodyRow onClick={() => handleSelectBeneficiary(beneficiary)}>
                <Cell width={columns[0].width} >
                  <p>{beneficiary.fk_id_customer_beneficiary}</p>
                </Cell>
                <Cell width={columns[1].width} flexGrow >
                  <p>{beneficiary.beneficiary_name}</p>
                </Cell>
                <Cell width={columns[2].width} >
                  <p>{beneficiary.beneficiary_grade_name}</p>
                </Cell>
              </BodyRow>
              {
                beneficiary.voucher_types.map((voucherType, counter) => {
                  return <ExpansibleRow
                    key={counter}
                    state={selectedBeneficiary?.fk_id_customer_beneficiary === beneficiary.fk_id_customer_beneficiary}
                  >
                    <Cell width={columns[0].width}><p /></Cell>
                    <Cell width={columns[1].width} flexGrow >
                      <p
                        style={{ ...childRowText, cursor: 'pointer' }}
                        onClick={() => setFilters(prev => ({...prev, voucherType: voucherType.fk_id_voucher_type}))}
                      >
                        {voucherType.voucher_type_name}
                      </p>
                    </Cell>
                    <Cell width={columns[2].width} >
                      <p style={{ ...childRowText }}>
                        {voucherType.balance}
                      </p>
                    </Cell>
                  </ExpansibleRow>
                })
              }
            </Fragment>
          })
      }
    </BodyContainer>
  </Table>
}
