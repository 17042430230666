import { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import { VoucherRequestsType } from './RequestVouchers'
import RequestVouchersFormTable from './RequestVouchersFormTable'
import axios from 'axios'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import styles from './Voucher.module.css'
import { useMsal } from '@azure/msal-react'
import { FeedbackContext } from '../../../context/FeedbackContext'

type RequestVouchersFormProps = {
  selectedRequest: VoucherRequestsType | null,
  afterSubmit: () => void
}

export type VoucherGeneratorsType = {
  fk_id_request: number,
  fk_id_billing: number,
  payer_fk_id_customer_type: number,
  payer_customer_type_name: string,
  payer_name: string,
  fk_id_default_billing: number,
  default_billing_name: string,
  first_installment_due_value: number,
  first_installment_paid_value: null | number
}

export type ExpectedDefaultBillings = {
  fk_id_default_billing: number,
  default_billing_name: string,
}

export default function RequestVouchersForm({ selectedRequest, afterSubmit }: RequestVouchersFormProps) {
  const { lookupTables } = useContext(LookupTablesContext)
  const { feedbackDispatch } = useContext(FeedbackContext)
  const { accounts } = useMsal()

  const [loadingTable, setLoadingTable] = useState(false)
  const [voucherGenerators, setVoucherGenerators] = useState<VoucherGeneratorsType[]>([])
  const [expectedDefBills, setExpectedDefBills] = useState<ExpectedDefaultBillings[]>([])
  const [requestStatus, setRequestStatus] = useState(() => {
    if(!selectedRequest) return 1
    return selectedRequest.fk_id_request_status_type
  })
  useEffect(() => {
    if(!selectedRequest) return
    setRequestStatus(selectedRequest.fk_id_request_status_type)
  }, [selectedRequest])

  const [comments, setComments] = useState(() => {
    if(!selectedRequest) return ''
    if(selectedRequest.request_comments) return selectedRequest.request_comments
    return ''
  })
  const closedRequest = useMemo(() => {
    if(selectedRequest?.fk_id_request_status_type !== 1) return true
    return false
  }, [selectedRequest])

  const [ongoingFetch, setOngoingFetch] = useState(false)
  const validForm = useMemo(() => {
    if(requestStatus === 1 || requestStatus === 2) return false // Under revision or Canceled
    if(requestStatus === 4 && !comments) return false
    return true
  }, [requestStatus, comments])

  const refreshTable = useCallback( async () => {
    if (!selectedRequest?.fk_id_request) return

    setOngoingFetch(true)
    setLoadingTable(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/request-voucher/${selectedRequest?.fk_id_request}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })
      console.log(data)
      setVoucherGenerators(data.voucher_generators)
      setExpectedDefBills(data.expected_default_billings)
    } catch(err) {
      setVoucherGenerators([])
      setExpectedDefBills([])
    }

    setOngoingFetch(false)
    setLoadingTable(false)
  }, [selectedRequest?.fk_id_request, setOngoingFetch, setVoucherGenerators, setExpectedDefBills])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const approveRequest = async () => {
    setOngoingFetch(true)
    feedbackDispatch({ mode: 'waiting', message: 'Approving request...' })

    try {
      const { data } = await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/request-voucher/1`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          fk_id_billing: voucherGenerators[0].fk_id_billing, // Any associated billing is enough for manual Approves
          updated_by: accounts[0]?.username || 'Cypress testing',
          manual_approve: true,
          added_comment: !comments ? null : comments
        }
      })

      console.log(data)
      afterSubmit()
      feedbackDispatch({ mode: 'done', message: 'Request approved' })
    } catch(err) {
      console.log(err)
      feedbackDispatch({ mode: 'failed', message: 'Unable to approve request' })
    }

    setOngoingFetch(false)
  }

  const rejectRequest = async () => {
    if(!selectedRequest || !comments) return
    setOngoingFetch(true)
    feedbackDispatch({ mode: 'waiting', message: 'Approving request...' })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/request-voucher/${selectedRequest.fk_id_request}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      afterSubmit()
      feedbackDispatch({ mode: 'done', message: 'Request rejected' })
    } catch(err) {
      console.log(err)
      feedbackDispatch({ mode: 'failed', message: 'Unable to reject request' })
    }
  }

  const renderStatusColor = (requestStatus: number) => {
    if (!selectedRequest || !requestStatus) return styles.underRevision
    switch (requestStatus) {
      case 1:
        return styles.underRevision
      case 2:
        return styles.canceled
      case 3:
        return styles.approved
      case 4:
        return styles.rejected
      default:
        return styles.underRevision
    }
  }

  return <Grid container spacing={1} marginX='auto' >
    <Grid item xs={3} >
      <Typography variant='h6'>Beneficiary:</Typography>
    </Grid>
    <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }} >
      { selectedRequest?.beneficiary_name || '' }
    </Grid>

    <Grid item xs={3} >
      <Typography variant='h6'>Bundle:</Typography>
    </Grid>
    <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }} >
      { selectedRequest?.bundle_name || '' }
    </Grid>

    <Grid item xs={3} >
      <Typography variant='h6'>Vouchers:</Typography>
    </Grid>
    <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }} >
      { `${selectedRequest?.voucher_amount} ${selectedRequest?.voucher_type_name} voucher(s)` || '' }
    </Grid>

    <Grid item xs={12} >
      <RequestVouchersFormTable loadingTable={loadingTable} expectedDefBills={expectedDefBills} voucherGenerators={voucherGenerators} />
    </Grid>

    {/* -------- Spacer -------- */}
    <Grid item xs={12} height='40px' />

    <Grid item xs={12} >
      <Typography variant='h5' >
        Request: <span style={{ color: '#FFF6' }} >{selectedRequest?.fk_id_request || ''}</span>
      </Typography>
    </Grid>
    <Grid item xs={4} height={66}>
    {
        lookupTables.requestStatus[0].option === 'Loading...' ?
          'Loading...'
          :
          <select
            id="select-request-status"
            value={requestStatus}
            onChange={(e) => setRequestStatus(Number(e.target.value)) }
            className={`${styles.selectStatus} ${renderStatusColor(requestStatus)}`}
            disabled={closedRequest} // Disable if customer cancelled their request
          >
            {
              lookupTables.requestStatus.map((status, counter) => {
                return <option
                  key={counter}
                  value={status.id}
                  className={renderStatusColor(status.id)}
                  disabled={status.id === 2} // Only the customer who made the request can cancel a request
                >
                  {status.option}
                </option>
              })
            }
          </select>
      }
    </Grid>
    <Grid item xs={12} paddingRight='10px' >
      <TextField
        id='form-comment'
        label='Comments'
        multiline
        rows={4}
        value={comments}
        onChange={(e) => setComments(e.target.value) }
        disabled={![3, 4].includes(requestStatus) || closedRequest } // Enable only for 'Approved' and 'Rejected' status
      />
    </Grid>
    <Grid item xs={8} />
    <Grid item xs={4} height={66} paddingRight='10px' >
      <Button
        fullWidth
        sx={{ height: '100%' }}
        startIcon={<Save />}
        disabled={!validForm || ongoingFetch || closedRequest}
        onClick={requestStatus === 3 ? approveRequest : rejectRequest}
      >
        Save
      </Button>
    </Grid>
  </Grid>
}
