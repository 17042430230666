import { Container, Grid, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import Header from '../components/header/Header'
import CanvasAccounts from '../components/canvas/CanvasAccounts'

export default function CanvasView() {
  const [tab, setTab] = useState('accounts')

  function renderComponent() {
    switch (tab) {
      case 'accounts':
        return <CanvasAccounts />
      default:
        return <CanvasAccounts />
    }
  }

  return <>
    <Container>
      <br />
      <Header title='Canvas' name='' />
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} >
            <Tab id='accounts' label='Accounts' value='accounts' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </>
}
