import { Suspense, lazy, useState } from "react"
import { Container, Grid, Tabs, Tab } from "@mui/material"
import { useLocation } from "react-router-dom"
import PreLoader from "../components/PreLoader"

// import RequestManager from "../components/nest/RequestManager"
// import Header from "../components/header/Header"
// import Accounts from '../components/nest/accounts/Accounts'

const RequestManager = lazy(() => import("../components/nest/RequestManager"))
const RolloutSearch = lazy(() => import("../components/nest/rollout/RolloutSearch"))
const Header = lazy(() => import("../components/header/Header"))
const Accounts = lazy(() => import('../components/nest/accounts/Accounts'))

export default function NestView() {
  // Hook for preselect the tab
  const { hash } = useLocation()

  const [tab, setTab] = useState<string>(hash.replace('#', '') || 'requestManager')

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const renderComponent = () => {
    switch (tab) {
      case 'requestManager':
        return <RequestManager />
      case 'rollouts':
        return <RolloutSearch />
      case 'accounts':
        return <Accounts />
      default:
        return <RequestManager />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1}>
        <Header title={'Nest'} name={''} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab id='nest-tab-request-manager' label='Request Manager' value='requestManager' />
            <Tab id='nest-tab-rollouts' label='Rollouts' value='rollouts' />
            <Tab id='nest-tab-accounts' label='Accounts' value='accounts' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </Suspense>
}