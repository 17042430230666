import { useEffect, useState, Dispatch, SetStateAction } from "react"

export function useSessionStorageFilters<Type>(fields: Type, sessionStorageID: string): [Type, Dispatch<SetStateAction<Type>>] {

    const [filters, setFilters] = useState<Type>(() => {

        const storedValues = window.sessionStorage.getItem(sessionStorageID)

        return storedValues ? JSON.parse(storedValues) : fields

    })

    useEffect(() => {
        window.sessionStorage.setItem(sessionStorageID, JSON.stringify(filters))
    }, [filters, sessionStorageID])

    return [filters, setFilters]

}