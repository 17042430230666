import styles from './BillingsTable.module.scss'

type Props = {
  children: React.ReactNode
  isBundle?: boolean
  isEven: boolean
  isNumber?: boolean
  isSelected: boolean
  width?: number
}

export default function Cell({ children, isBundle, isEven, isNumber = false, isSelected, width = 1 }: Props) {
  return <div className={`${styles.cell} ${isBundle ? styles.bundle : ''} ${isEven ? styles.even : styles.odd} ${isNumber ? styles.numberCell : ''} ${isSelected ? styles.selectedRow : ''}`} style={{ width: width * 100 }}>
    {children}
  </div>
}
