import { Settings } from '@mui/icons-material'
import { Button, Checkbox, IconButton } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { ViewNotification } from '../../hooks/useNotification'
import { useNavigate } from 'react-router-dom'

type Props = {
  notifications: ViewNotification[],
  updateNotification: (id: number, visualized: {
    visualized: boolean;
  }) => Promise<void>
}

export default function NotificationsTable({ notifications, updateNotification }: Props) {
  // Hooks
  const navigate = useNavigate()

  return <Table>
    <HeaderRow style={{ padding: '0 4px' }}>
      <Cell flexGrow>
        <span style={{ fontWeight: 'bold', fontSize: '17px', width: '100%' }}>
          Notifications
        </span>
      </Cell>
      <Cell width={.5}>
        <span>
          <IconButton size='small' sx={{ color: '#fff', background: 'none' }}>
            <Settings />
          </IconButton>
        </span>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        notifications.length > 0 ?
          notifications.map((_, index) => <BodyRow key={index} rowHeight={65}>
            <Cell width={5}>
              <p style={{ fontWeight: 'bold' }}>
                {_.automated_service_name || 'Notifier'}:
              </p>
              <p style={{ color: _.visualized ? 'var(--color-text-disabled)' : '#fff' }}>
                {_.message}
              </p>
            </Cell>
            <Cell width={1}>
              <Checkbox
                color={_.visualized ? 'success' : 'primary'}
                checked={_.visualized}
                onChange={(e) => updateNotification(_.id, { visualized: e.target.checked })}
              />
              <p style={{ fontSize: '11px', color: 'var(--color-text-subtitle)' }}>
                {_.time_since_created}
              </p>
            </Cell>
          </BodyRow>
          )
          :
          <BodyRow>
            <Cell flexGrow>
              <span>No notifications</span>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
    <HeaderRow>
      <Cell width={3}>
        <p>
        </p>
      </Cell>
      <Cell width={3}>
        <Button
          size='small'
          sx={{
            padding: 0,
            height: '30px',
            width: '140px'
          }}
          onClick={() => navigate('/notifications')}
        >
          <p style={{ fontSize: '13px', fontWeight: 'bold' }}>
            See all
          </p>
        </Button>
      </Cell>
    </HeaderRow>
  </Table >
}
