import { Dispatch, SetStateAction } from 'react'
import { convertNumberToString, formatNumberToMoney, renderCurrency } from '../../Utils'
import { ViewDefaultBilling } from './DefaultBillings'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import SortingHeader02 from '../../assets/SortingHeader02'
import { Checkbox } from '@mui/material'
import { ViewBundleDefaultBillingMap } from './Bundles'

type DefaultBillingsTableProps = {
  data: ViewDefaultBilling[],
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any>>,
  sort: any,
  setSort: Dispatch<SetStateAction<any>>
}

type ColumnDefaultBillingTable = {
  field: keyof ViewDefaultBilling,
  headerName: string,
  width: number
}

export function DefaultBillingsTable({ data, form, setForm, sort, setSort }: DefaultBillingsTableProps) {
  // Constants
  const columns: ColumnDefaultBillingTable[] = [
    { field: 'id', headerName: 'ID', width: .55 },
    { field: 'default_billing_name', headerName: 'Name', width: 2.5 },
    { field: 'default_value', headerName: 'Gross Value', width: 1.25 },
    // { field: 'max_installments', headerName: 'Max Install.', width: .75 },
    { field: 'affiliation_name', headerName: 'Affiliation', width: 1.75 },
    { field: 'program_name', headerName: 'Program', width: 1.25 },
    { field: 'service_name', headerName: 'Service', width: 1.45 },
    // { field: 'fk_id_payment_method', headerName: 'Payment Method', width: 1 },
    // { field: 'fk_id_payee', headerName: 'Payee', width: .75 },
    // { field: 'fk_id_customer_type', headerName: 'Payer Type', width: 1 },
    // { field: 'commercial_conditions', headerName: 'Commercial Conditions', width: 1.25 }
  ]

  return <Table id='table-default-billings'>
    <HeaderRow>
      {
        columns.map((column, index) => {
          return <Cell key={index} width={column.width} >
            <SortingHeader02
              text={column.headerName}
              column={column.field}
              sort={sort}
              setSort={setSort}
            />
          </Cell>
        })
      }
    </HeaderRow>
    <BodyContainer>
      {
        data.length ?
          data.map((row, index) => {
            return <BodyRow key={index} selected={form.id === row.id} onClick={() => setForm({ show: false, id: row.id })}>
              <Cell width={columns[0].width}>
                <p>
                  {row.id}
                </p>
              </Cell>
              <Cell width={columns[1].width}>
                <p>
                  {row.default_billing_name}
                </p>
              </Cell>
              <Cell width={columns[2].width}>
                <p>
                  {renderCurrency(row.fk_id_currency)} {convertNumberToString(row[columns[2].field] || 0)}
                </p>
              </Cell>
              {/* <Cell width={columns[3].width}>
                  <p>
                    {row.max_installments}
                  </p>
                </Cell> */}
              <Cell width={columns[3].width}>
                <p>
                  {row.affiliation_name}
                </p>
              </Cell>
              <Cell width={columns[4].width}>
                <p>
                  {row.program_tag}
                </p>
              </Cell>
              <Cell width={columns[5].width}>
                <p>
                  {row.service_name}
                </p>
              </Cell>
              {/* <Cell width={columns[7].width}>
                  <p>
                    {row.payment_method_name}
                  </p>
                </Cell>
                <Cell width={columns[8].width}>
                  <p>
                    {row.payee_name}
                  </p>
                </Cell>
                <Cell width={columns[9].width}>
                  <p>
                    {row.customer_type_name}
                  </p>
                </Cell>
                <Cell width={columns[10].width} flexGrow>
                  <p>
                    {row.commercial_conditions}
                  </p>
                </Cell> */}
            </BodyRow>
          })
          :
          <BodyRow>
            <Cell width={3}>
              <p>
                No Default Billings to show.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
  </Table>
}

type DefaultBillingsTableMediumProps = {
  data: ViewDefaultBilling[]
  filteredData: ViewDefaultBilling[]
  selectedRowsIds: number[]
  setSelectedRowsIds: React.Dispatch<React.SetStateAction<number[]>>
}

export function DefaultBillingsTableMedium({ data, filteredData, selectedRowsIds, setSelectedRowsIds }: DefaultBillingsTableMediumProps) {
  // Constants
  const columnWidth = [.6, 3, 1.5, 1.5, 1.25, .75]

  const renderBodyRow = (row: ViewDefaultBilling, key: number) => {
    return <BodyRow key={key}>
      <Cell width={columnWidth[0]}>
        <Checkbox
          id={`checkbox-${row.id}`}
          checked={selectedRowsIds.includes(row.id)}
          onChange={(e, checked) => setSelectedRowsIds((ids) => {
            const _ids = [...ids]

            if (!checked) return _ids.filter((_id) => _id !== row.id)

            else {
              _ids.push(row.id)
              return _ids
            }
          })}
        />
      </Cell>
      <Cell width={columnWidth[1]}>
        <p>
          {row.default_billing_name}
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          {row.program_tag}
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          {row.affiliation_name}
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>
          {row.sign} {formatNumberToMoney(row.default_value)}
        </p>
      </Cell>
      <Cell width={columnWidth[5]}>
        <p>
          {row.max_installments}
        </p>
      </Cell>
    </BodyRow>
  }

  return <Table>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <Checkbox
          checked={selectedRowsIds.length === data.length && data.length !== 0}
        />
      </Cell>
      <Cell width={columnWidth[1]}>
        <p>
          Default Billing
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          Program
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Affiliation
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>
          Value
        </p>
      </Cell>
      <Cell width={columnWidth[5]}>
        <p>
          Max. Inst.
        </p>
      </Cell>
    </HeaderRow>
    {/* Fixing the selected default billings at the top of the list */}
    {
      selectedRowsIds.length ?
        <>
          {
            data.filter((_) => selectedRowsIds.includes(_.id)).map(renderBodyRow)
          }
          <hr style={{ width: '100%', margin: 0 }} />
        </>
        :
        null
    }
    {/* Not selected default billings */}
    <BodyContainer>
      {
        filteredData.length ?
          filteredData.filter((_) => !selectedRowsIds.includes(_.id)).map(renderBodyRow)
          :
          <BodyRow>
            <Cell flexGrow>
              <p>
                No default billings to show.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
    <HeaderRow>
      <Cell width={3}>
        <p>
          {selectedRowsIds.length} default billings selected
        </p>
      </Cell>
      <Cell flexGrow>
        <p>
          Total: {data.find((_) => _.id === selectedRowsIds[0])?.sign} {formatNumberToMoney(data.filter((_) => selectedRowsIds.includes(_.id)).reduce((prev, curr) => prev += curr.default_value, 0))}
        </p>
      </Cell>
    </HeaderRow>
  </Table>
}

type DefaultBillingsTableShortProps = {
  data: ViewBundleDefaultBillingMap[] | null
}

export function DefaultBillingsTableShort({ data }: DefaultBillingsTableShortProps) {
  const columnWidth = [.5, 2.5]
  return <Table id='table-default-billings-short'>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          ID
        </p>
      </Cell>
      <Cell flexGrow>
        <p>
          Default Billing
        </p>
      </Cell>
      <Cell>
        <p>
          Service
        </p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        data?.length ?
          data.map((row, index) => <BodyRow key={index}>
            <Cell width={columnWidth[0]}>
              <p>
                {row.fk_id_default_billing}
              </p>
            </Cell>
            <Cell flexGrow>
              <p>
                {row.default_billing_name}
              </p>
            </Cell>
            <Cell>
              <p>
                {row.service_name}
              </p>
            </Cell>
          </BodyRow>)
          :
          <BodyRow>
            <Cell flexGrow>
              <p>
                No default billings to show.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
  </Table>
}