import { Autocomplete, Button, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField } from '@mui/material'
import { useCallback, useContext, useMemo, useState } from 'react'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { AutocompleteOption } from '../../../utilities/generalPurposeTypes'
import axios from 'axios'
import CreditsOverviewTable from './CreditsOverviewTable'
import { quickSort } from '../../Utils'
import CreditsOverviewLegend from './CreditsOverviewLegend'
import { Download } from '@mui/icons-material'
import { utils, writeFile } from 'xlsx'
import { toast } from 'react-toastify'

type CreditsOverviewFilters = {
  affiliation: AutocompleteOption | null,
  studentStatusType: 'active' | number,
  program: 'all' | number,
  totalCredits: string,
  egt: 'all' | number,
}

export type CreditsPerSubject = {
  languageArts: number,
  mathematics: number,
  science: number,
  socialStudies: number,
  foreignLanguages: number,
  physicalEducation: number,
  arts: number,
  electives: number,
  total: number
}

export type StudentCreditsOverview = {
  id: number,
  student_full_name: string,
  fk_id_student_status_type: number,
  student_status_type_name: string,
  fk_id_program: number,
  program_tag: string,
  program_name: string,
  fk_id_grade: number,
  grade_name: string,
  fk_id_egt: number,
  egt_name: string,
  planned: CreditsPerSubject,
  granted: CreditsPerSubject,
}

export type SortCreditsOverview = {
  column: keyof StudentCreditsOverview,
  direction: 'asc' | 'desc'
}

export type DisplayCreditsOverview = 'granted' | 'planned'

type ExcelRow = {
  'ID': number,
  'Student': string,
  'Program': string,
  'Grade': string,
  'Status': string,
  'LA (4)': number,
  'SS (3)': number,
  'FL (2)': number,
  'SC (3)': number,
  'MA (4)': number,
  'PE (1)': number,
  'AR (1)': number,
  'EL (5)': number,
  'Total': number,
  'EGT': string
}

export default function CreditsOverview() {
  // States
  const [data, setData] = useState<StudentCreditsOverview[]>([])
  const [filter, setFilter] = useState<CreditsOverviewFilters>({
    affiliation: null,
    studentStatusType: 'active',
    program: 'all',
    totalCredits: 'all',
    egt: 'all'
  })
  const [sort, setSort] = useState<SortCreditsOverview>({ column: 'id', direction: 'asc' })
  const [display, setDisplay] = useState<DisplayCreditsOverview>('granted')
  const [loading, setLoading] = useState(false)
  // Contexts
  const { lookupTables } = useContext(LookupTablesContext)
  // Memos
  const affiliationOptions: AutocompleteOption[] = useMemo(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id }))
  }, [lookupTables.affiliation])

  const refreshTable = async () => {
    if (!filter.affiliation) return
    setLoading(true)
    const _toast = toast('Fetching students...', { toastId: 'toast-credits-overview', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/credits-overview?affiliation=${filter.affiliation.id}&student_status_type=${filter.studentStatusType}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })
      console.log(data)
      setData(data)
      toast.update(_toast, { render: 'Students fetched', type: 'success', isLoading: false, autoClose: 1500 })
    } catch (err) {
      console.log(err)
      toast.update(_toast, { render: 'Unable to fetch students', type: 'error', isLoading: false })
    }

    setLoading(false)
  }

  const filterByProgram = useCallback((row: StudentCreditsOverview) => {
    if (filter.program === 'all') return true
    else return row.fk_id_program === filter.program
  }, [filter.program])

  const filterByTotalCredits = useCallback((row: StudentCreditsOverview) => {
    switch (filter.totalCredits) {
      case 'all':
        return true
      case 'less than 6':
        return row[display].total < 6
      case 'between 6 and 22':
        return row[display].total >= 6 && row[display].total < 23
      case '23 or more':
        return row[display].total >= 23
      default:
        return true
    }
  }, [filter.totalCredits, display])


  const filterByEgt = useCallback((row: StudentCreditsOverview) => {
    if (filter.egt === 'all') return true
    else return row.fk_id_egt === filter.egt
  }, [filter.egt])

  const filteredAndSortedData = useMemo(() => {
    if (!data.length) return data

    const filteredData = data.filter(filterByEgt).filter(filterByTotalCredits).filter(filterByProgram)
    return quickSort(filteredData, sort.column as keyof StudentCreditsOverview, sort.direction)
  }, [data, filterByProgram, filterByEgt, filterByTotalCredits, sort])

  const downloadExcel = () => {
    const wb = utils.book_new();


    const excelRows: ExcelRow[] = []
    for (const row of filteredAndSortedData) {
      const newExcelRow: ExcelRow = {
        'ID': row.id,
        'Student': row.student_full_name,
        'Program': row.program_tag,
        'Grade': row.grade_name,
        'Status': row.student_status_type_name,
        'LA (4)': row[display].languageArts,
        'SS (3)': row[display].socialStudies,
        'FL (2)': row[display].foreignLanguages,
        'SC (3)': row[display].science,
        'MA (4)': row[display].mathematics,
        'PE (1)': row[display].physicalEducation,
        'AR (1)': row[display].arts,
        'EL (5)': row[display].electives,
        'Total': row[display].total,
        'EGT': row.egt_name
      }

      excelRows.push(newExcelRow)
    }
    const ws = utils.json_to_sheet(excelRows);
    utils.book_append_sheet(wb, ws);

    const affiliationName = filter.affiliation?.label.split(' - ')[1]
    writeFile(wb, `Credits Overview - ${affiliationName} (${display} credits).xlsx`);
  }

  return <>
    <Grid item container rowSpacing={1}>
      <Grid item xs={2}>
        <Autocomplete
          id='filter-affiliation'
          renderInput={(params) => <TextField label='Affiliation' {...params} />}
          options={affiliationOptions}
          value={filter.affiliation}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => setFilter(prev => ({ ...prev, affiliation: newValue }))}
        />
      </Grid>
      <Grid item xs={1.5} >
        <TextField
          id='filter-student-status-type'
          label='Student Status'
          select
          value={filter.studentStatusType}
          onChange={(e) => setFilter(prev => ({ ...prev, studentStatusType: e.target.value === 'active' ? 'active' : Number(e.target.value) }))}
        >
          {[
            <MenuItem key={-1} value='active' >Active</MenuItem>,
            ...lookupTables.studentStatusType.map((status, index) => {
              return <MenuItem key={index} value={status.id} >{status.option}</MenuItem>
            })
          ]}
        </TextField>
      </Grid>
      <Grid item xs={1.5}>
        <Button
          id='fetch'
          disabled={!filter.affiliation || loading}
          onClick={refreshTable}
        >
          Fetch
        </Button>
      </Grid>
      <Grid item xs={0.75} >
        <Button
          id='download'
          startIcon={<Download />}
          sx={{ height: '100%', color: 'white', backgroundColor: 'hsl(148.2, 56.5%, 35%)' }}
          disabled={!filteredAndSortedData.length || loading}
          onClick={downloadExcel}
        >
          XLSX
        </Button>
      </Grid>

      <Grid item xs={2.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <RadioGroup
          row
          value={display}
          onChange={(e) => setDisplay(e.target.value as DisplayCreditsOverview)}
        >
          <FormControlLabel value='granted' control={<Radio />} label="Granted" />
          <FormControlLabel value='planned' control={<Radio />} label="Planned" />
        </RadioGroup>
      </Grid>
      <Grid item xs={1.25}>
        <TextField
          id='filter-program'
          label='Program'
          select
          value={filter.program}
          onChange={(e) => setFilter(prev => ({ ...prev, program: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        >
          {[
            <MenuItem key={-1} value='all'>All</MenuItem>,
            lookupTables.program.filter(pr => pr.option !== 'tbd').map((program, index) => {
              return <MenuItem key={index} value={program.id}>{program.tag}</MenuItem>
            })
          ]}
        </TextField>
      </Grid>
      <Grid item xs={1.25}>
        <TextField
          id='filter-total-credits'
          label='Total credits'
          select
          value={filter.totalCredits}
          onChange={(e) => setFilter(prev => ({ ...prev, totalCredits: e.target.value }))}
        >
          {[
            <MenuItem key={0} value='all'>All</MenuItem>,
            <MenuItem key={1} value='less than 6'>Less than 6</MenuItem>,
            <MenuItem key={2} value='between 6 and 22'>Between 6 and 22</MenuItem>,
            <MenuItem key={3} value='23 or more'>23 or more</MenuItem>,
          ]}
        </TextField>
      </Grid>
      <Grid item xs={1.25}>
        <TextField
          id='filter-egt'
          label='EGT'
          select
          value={filter.egt}
          onChange={(e) => setFilter(prev => ({ ...prev, egt: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        >
          {[
            <MenuItem key={-1} value='all'>All</MenuItem>,
            lookupTables.term
              .filter(term => data.some(row => row.fk_id_egt === term.id))
              .map((term, index) => {
                return <MenuItem key={index} value={term.id} >{term.option}</MenuItem>
              })
          ]}
        </TextField>
      </Grid>

      <Grid item xs={12} height='calc(100vh - 250px)'>
        <CreditsOverviewTable
          data={filteredAndSortedData}
          sort={{ state: sort, setter: setSort }}
          display={display}
          currentTerm={lookupTables.currentTerm.id}
        />
        <CreditsOverviewLegend numberOfRows={filteredAndSortedData.length} />
      </Grid>
    </Grid>
  </>
}
