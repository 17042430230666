import { Container, Grid, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import Header from '../components/header/Header'
import Vouchers from '../components/finances/vouchers/Vouchers'
import RequestVouchers from '../components/finances/vouchers/RequestVouchers'

export default function VouchersView() {
  const [tab, setTab] = useState('vouchers')

  function handleTabChange(event: React.SyntheticEvent, newValue: string) {
    setTab(newValue)
  }

  function renderComponent() {
    switch (tab) {
      case 'vouchers':
        return <Vouchers />
      case 'request-vouchers':
        return <RequestVouchers />
      default:
        return <Vouchers />
    }
  }

  return <>
    <Container>
      <br />
      <Grid container spacing={1} >
        <Header title='Vouchers' name='' />

        <Grid item xs={12} >
          <Tabs value={tab} onChange={handleTabChange} >
            <Tab id='vouchers' label='Vouchers' value='vouchers' />
            <Tab id='request-vouchers' label='Requests' value='request-vouchers' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </>
}
