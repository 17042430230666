import { BodyContainer, BodyRow, Cell, HeaderRow, StatusCell, Table } from "wed-components"
import { Form, ViewAffiliation } from "./AffilliationSearch"
import { Dispatch, SetStateAction } from "react"

type AffiliationTableProps = {
	data: ViewAffiliation[],
	form: Form //Form
	setForm: Dispatch<SetStateAction<Form>> //(form: Form) => void
}
export default function AffiliationTable({ data, setForm, form }: AffiliationTableProps) {
	const columns = [0.5, 0.75, 1.5, 2, 2.5, 3, 4]

	const renderEmails = (row: ViewAffiliation) => {
		if (!row.affiliation_email && !row.affiliation_billing_email) return <p>-</p>
		return <>
			{Boolean(row.affiliation_email) ? <p>{row.affiliation_email}</p> : <></>}
			{Boolean(row.affiliation_billing_email) ? <p>{row.affiliation_billing_email}</p> : <></>}
		</>
	}

	return <>
		<Table>
			<HeaderRow>
				<Cell width={columns[0]}>
					<p>
						ID
					</p>
				</Cell>
				<Cell width={columns[4]}>
					<p>
						Affiliation name
					</p>
				</Cell>
				<Cell width={columns[1]}>
					<p>
						TAG
					</p>
				</Cell>
				<Cell width={columns[3]}>
					<p>
						Email
					</p>
				</Cell>
				<Cell width={columns[2]}>
					<p>
						Phone
					</p>
				</Cell>
				<Cell width={columns[2]}>
					<p>
						Country
					</p>
				</Cell>
				<Cell width={columns[2]}>
					<p>
						State
					</p>
				</Cell>
				<Cell width={columns[2]}>
					<p>
						Tax Payer
					</p>
				</Cell>
				<Cell width={columns[2]}>
					<p>
						Type
					</p>
				</Cell>
				<Cell width={columns[2]}>
					<p>
						Status
					</p>
				</Cell>
			</HeaderRow>

			<BodyContainer>
				{data?.length > 0 ?
					data.map((_row) => {
						return <BodyRow id={`row-${_row.id}`} key={_row.id} selected={form.id === _row.id} onClick={() => setForm(prev => ({ ...prev, id: Number(_row.id) }))}>
							<Cell width={columns[0]}><p>{_row.id}</p></Cell>
							<Cell width={columns[4]}><p>{_row.affiliation_name}</p></Cell>
							<Cell width={columns[1]}><p>{_row.affiliation_tag}</p></Cell>
							<Cell width={columns[3]}>
								{
									renderEmails(_row)
								}
							</Cell>
							<Cell width={columns[2]}><p>{_row.affiliation_phone ? _row.affiliation_phone : '-'}</p></Cell>
							<Cell width={columns[2]}>
								<p>{_row.affiliation_country_name}</p>
							</Cell>
							<Cell width={columns[2]}>
								<p>{_row.affiliation_state_name ? _row.affiliation_state_name : '-'}</p>
							</Cell>
							<Cell width={columns[2]}>
								<p>{_row.tax_payer_number || '-'}</p>
							</Cell>


							<Cell width={columns[2]}>
								<p>{_row.affiliation_type_tag}</p>
							</Cell>

							<Cell width={columns[2]}>
								{_row.is_active ? <StatusCell color='green'>Active</StatusCell> : <StatusCell color='red'>Inactive</StatusCell>}
							</Cell>

						</BodyRow>
					})
					:
					<BodyRow>
						<Cell width={columns.reduce((prev, curr) => prev += curr, 0)}>
							<p>No affiliations to show</p>
						</Cell>
					</BodyRow>
				}
			</BodyContainer>
		</Table>
	</>
}