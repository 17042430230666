import { Container, Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import Header from '../components/header/Header'
import Payments from '../components/finances/payments/Payments'

export default function PaymentsView() {
  const [tab, setTab] = useState('tabulation')

  function handleTabChange(event: React.SyntheticEvent, newValue: string) {
    setTab(newValue)
  }

  function renderComponent() {
    switch (tab) {
      case 'tabulation':
        return <Payments />
      default:
        return <Payments />
    }
  }

  return <>
    <Container>
      <br />
      <Grid container spacing={1} >
        <Header title='Payments' name='' />

        <Grid item xs={12} >
          <Tabs value={tab} onChange={handleTabChange} >
            <Tab id='tabulation' label='Tabulation' value='tabulation' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
    {/* <div>
      {renderComponent()}
    </div> */}
  </>
}
