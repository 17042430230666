import { StatusCell } from 'wed-components'
import { ViewFaculty } from './FacultySearch'
import { Grid, Paper, Typography } from '@mui/material'

type FacultyDetailsProps = {
  selectedFaculty: ViewFaculty | null
}

export default function FacultyDetails({ selectedFaculty }: FacultyDetailsProps) {
  return <Grid container spacing={.5}>
    <Grid item xs={12}>
      <Paper sx={{ padding: 2, backgroundColor: 'white' }}>
        <Typography variant='h6' color='secondary'>
          Basic Information
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.full_name}
        </Typography>
        {/* <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.faculty_type_name}
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.affiliation_name}
        </Typography> */}
        <StatusCell color={selectedFaculty?.is_active ? 'green' : 'red'}>
          {selectedFaculty?.is_active ? 'Active' : 'Inactive'}
        </StatusCell>
        <Typography variant='h6' color='secondary'>
          Contact
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.email}
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.phone}
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.address}
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.address_city}
        </Typography>
        <Typography variant='h6' color='secondary'>
          Additional Information
        </Typography>
        <Typography color='secondary' fontWeight={'bold'}>
          {selectedFaculty?.comments || 'No comments for this faculty.'}
        </Typography>
      </Paper>
    </Grid>
  </Grid>
}
