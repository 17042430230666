import { Grid, MenuItem, TextField } from '@mui/material'
import { DashboardTables } from '../Home'

type DashboardFormProps = {
  data: DashboardTables,
  setData: React.Dispatch<React.SetStateAction<DashboardTables>>
}

export default function DashboardForm({ data, setData }: DashboardFormProps) {
  const dashboardsOptions = [
    {
      id: 1,
      name: 'Missing Assigments'
    },
    {
      id: 2,
      name: 'Requests'
    },
    {
      id: 3,
      name: 'Ending sections'
    },
    {
      id: 4,
      name: 'Students without enrollments'
    }
  ] as const

  return <Grid container spacing={1}>
    <Grid item xs={4} sx={{ marginTop: '10px' }}>
      <TextField
        id='select-dashboard-1'
        label="Dashboard 1"
        value={data.dash1}
        onChange={(e) => setData({ ...data, dash1: Number(e.target.value) })}
        select
      >
        <MenuItem key={-1} value={0}>None</MenuItem>
        {
          dashboardsOptions
            .filter((_option) => ![data.dash2, data.dash3].includes(_option.id))
            .map((_option, index) => <MenuItem key={index} id={`table-option-${_option.id}`} value={_option.id}>{_option.name}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={4} sx={{ marginTop: '10px' }}>
      <TextField
        id='select-dashboard-2'
        label="Dashboard 2"
        value={data.dash2}
        onChange={(e) => setData({ ...data, dash2: Number(e.target.value) })}
        select
      >
        <MenuItem key={-1} value={0}>None</MenuItem>
        {
          dashboardsOptions
            .filter((_option) => ![data.dash1, data.dash3].includes(_option.id))
            .map((_option, index) => <MenuItem key={index} id={`table-option-${_option.id}`} value={_option.id}>{_option.name}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={4} sx={{ marginTop: '10px' }}>
      <TextField
        id='select-dashboard-3'
        label="Dashboard 3"
        value={data.dash3}
        onChange={(e) => setData({ ...data, dash3: Number(e.target.value) })}
        select
      >
        <MenuItem key={-1} value={0}>None</MenuItem>
        {
          dashboardsOptions
            .filter((_option) => ![data.dash1, data.dash2].includes(_option.id))
            .map((_option, index) => <MenuItem key={index} id={`table-option-${_option.id}`} value={_option.id}>{_option.name}</MenuItem>)
        }
      </TextField>
    </Grid>
  </Grid>
}
